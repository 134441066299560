import { toastsActions } from '@zola-helpers/client';
import type {
  UpdateCmsPageHiddenRequest,
  UpdateCmsPageRequest,
  WCmsPageSummaryView,
  WCmsPageView,
  WRegistryView,
} from '@zola/svc-web-api-ts-client';
import { AppDispatch, RootState } from 'reducers/index';

import { getPageVisibility } from 'selectors/website/pageSelectors';
import * as PageActionsTypes from '../types/website/PageActionTypes';

type ReqestPagesAction = {
  type: typeof PageActionsTypes.REQUEST_PAGES;
};
function requestPages(): ReqestPagesAction {
  return {
    type: PageActionsTypes.REQUEST_PAGES,
  };
}

type ReceivePagesAction = {
  type: typeof PageActionsTypes.RECEIVE_PAGES;
  payload: {
    pages: WCmsPageView[];
  };
};
function receivePages(json: WCmsPageView[]): ReceivePagesAction {
  return {
    type: PageActionsTypes.RECEIVE_PAGES,
    payload: {
      pages: json,
    },
  };
}

type ReceiveHiddenPageSuggestionsAction = {
  type: typeof PageActionsTypes.RECEIVE_HIDDEN_PAGE_SUGGESTIONS;
  payload: {
    hiddenSuggestions: WCmsPageSummaryView[];
  };
};
function receiveHiddenPageSuggestions(
  json: WCmsPageSummaryView[]
): ReceiveHiddenPageSuggestionsAction {
  return {
    type: PageActionsTypes.RECEIVE_HIDDEN_PAGE_SUGGESTIONS,
    payload: {
      hiddenSuggestions: json,
    },
  };
}

type ReceivePageAction = {
  type: typeof PageActionsTypes.RECEIVE_PAGE;
  payload: {
    page: WCmsPageView;
  };
};
function receivePage(json: WCmsPageView): ReceivePageAction {
  return {
    type: PageActionsTypes.RECEIVE_PAGE,
    payload: {
      page: json,
    },
  };
}

type ActivatePageAction = { type: typeof PageActionsTypes.ACTIVATE_PAGE; activePage: string };
export const activatePage = (activePage: string) => (dispatch: AppDispatch): void => {
  dispatch({ type: PageActionsTypes.ACTIVATE_PAGE, activePage });
};

export function fetchPages() {
  return (dispatch: AppDispatch): Promise<WCmsPageView[]> => {
    dispatch(requestPages());
    return fetch('/web-api/v1/cms/page/list', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then((json: WCmsPageView[]) => {
        dispatch(receivePages(json));
        return json;
      });
  };
}

export function fetchHiddenPageSuggestions() {
  return (dispatch: AppDispatch): Promise<void> => {
    return fetch('/web-api/v1/cms/page/hiddensuggestions', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveHiddenPageSuggestions(json));
      });
  };
}

export function reorderPages() {
  return (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    return fetch('/web-api/v1/cms/page/list/reorder', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(getState().website.pages.orderedIds),
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receivePages(json));
      });
  };
}

type MovePageAction = {
  type: typeof PageActionsTypes.MOVE_PAGE;
  oldIndex: number;
  newIndex: number;
};
export const movePage = (oldIndex: number, newIndex: number) => (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch({ type: PageActionsTypes.MOVE_PAGE, oldIndex, newIndex });
  return dispatch(reorderPages());
};

export function updatePageVisibility(pageId: number) {
  return (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    return fetch(`/web-api/v1/cms/page/id/${pageId}/hidden`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        hidden: getPageVisibility(getState(), pageId),
      }),
    })
      .then(response => response.json())
      .then(json => dispatch(receivePage(json)))
      .then(() => {
        dispatch(
          toastsActions.positive({
            headline: `Page ${getPageVisibility(getState(), pageId) ? 'hidden' : 'visible'}`,
          })
        );
      });
  };
}

type TogglePageAction = { type: typeof PageActionsTypes.TOGGLE_PAGE; payload: { id: number } };
export const togglePage = (id: number) => (dispatch: AppDispatch): void => {
  dispatch({ type: PageActionsTypes.TOGGLE_PAGE, payload: { id } });
  dispatch(updatePageVisibility(id)).catch(() => undefined);
};

type RequestProfileImageAction = {
  type: typeof PageActionsTypes.REQUEST_PROFILE_IMAGE;
};
export const requestProfileImage = (): RequestProfileImageAction => {
  return {
    type: PageActionsTypes.REQUEST_PROFILE_IMAGE,
  };
};

type ReceiveProfileImageAction = {
  type: typeof PageActionsTypes.RECEIVE_PROFILE_IMAGE;
  payload?: string;
};

export const receiveProfileImage = (response: WRegistryView): ReceiveProfileImageAction => {
  const profileImage = response.profile_image;
  return {
    type: PageActionsTypes.RECEIVE_PROFILE_IMAGE,
    payload: profileImage,
  };
};

export function toggleHiddenPageSuggestion(pageId: number, hidden: boolean) {
  return (dispatch: AppDispatch): Promise<void> =>
    fetch(`/web-api/v1/cms/page/id/${pageId}/hidden`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        hidden,
      }),
    })
      .then(response => response.json())
      .then(() => {
        dispatch(toastsActions.positive({ headline: `Page ${hidden ? 'Hidden' : 'Visible'}` }));
      });
}

export function getProfileImage() {
  return (dispatch: AppDispatch): Promise<void> => {
    dispatch(requestProfileImage());
    return fetch('/website-nav/web-api/v1/registry/get', {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveProfileImage(json));
      });
  };
}

export function updatePage(
  pageView: Omit<UpdateCmsPageRequest, 'wedding_account_id'>,
  showNotification = true
) {
  return (dispatch: AppDispatch): Promise<void> =>
    fetch('/web-api/v1/cms/page', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(pageView),
    })
      .then(response => response.json())
      .then(json => dispatch(receivePage(json)))
      .then(data => {
        if (showNotification) {
          const pageName = data.payload.page && data.payload.page.menu_title;
          const notifyText = pageName && `${pageName} changes saved`;

          dispatch(toastsActions.positive({ headline: notifyText || null }));
        }
      });
}

export const updatePageSilently = (
  pageView: Omit<UpdateCmsPageRequest, 'wedding_account_id'>
): ((dispatch: AppDispatch) => Promise<void>) => updatePage(pageView, false);

export function bulkUpdateCmsPageVisibility(
  pages: Omit<UpdateCmsPageHiddenRequest, 'wedding_account_id'>[]
) {
  return (dispatch: AppDispatch): Promise<void> =>
    fetch('/web-api/v1/cms/page/list/bulkupdate', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(pages),
    }).then(() => {
      dispatch(fetchPages()).catch(() => undefined);
    });
}

export type PageActions =
  | ReqestPagesAction
  | ReceivePagesAction
  | ReceivePageAction
  | ReceiveHiddenPageSuggestionsAction
  | TogglePageAction
  | MovePageAction
  | ActivatePageAction
  | ReceiveProfileImageAction;
