import _pick from 'lodash/pick';
import * as ActionTypes from '../actions/types/PublicWebsiteActionTypes';

export const initialState = {
  wedding: {},
  busy: false,
  page: {},
  entities: [],
  pages: [],
  theme: {},
  guestSearch: [],
  guestGroupRsvp: {},
  rsvp: {},
  rsvpShowSummary: false,
  guestRequestObject: [],
  guestRequestByUuid: {},
  guestRequestSubmitted: false,
  rsvpAccessForm: false,
  guestRequestCount: 0,
  zolaRegistry: {},
  token: '',
  cmsBanners: [],
  currentVirtualEvent: {},
  isCustomZIndex: false,
};

const publicReducer = (state = initialState, action) => {
  switch (action.type) {
    // busy
    case ActionTypes.REQUEST_WEDDING: {
      return { ...state, busy: true };
    }
    // busy, wedding, pages, theme
    case ActionTypes.RECEIVE_WEDDING: {
      return {
        ...state,
        busy: false,
        wedding: action.payload,
        pages: action.payload.nav_items,
        theme: action.payload.theme,
      };
    }
    case ActionTypes.TOGGLE_POI_MAPS: {
      return {
        ...state,
        wedding: {
          ...state.wedding,
          enable_poi_maps: !state.wedding.enable_poi_maps,
        },
      };
    }
    case ActionTypes.RECEIVE_WEDDING_THEME: {
      return {
        ...state,
        theme: action.payload,
      };
    }
    // busy
    case ActionTypes.REQUEST_PAGE: {
      return { ...state, busy: true };
    }
    // busy, entities, page, zolaRegistry
    case ActionTypes.RECEIVE_PAGE: {
      const page = _pick(
        action.payload,
        'title',
        'description',
        'header_image_url',
        'footer_image_url',
        'type',
        'zola_registry.default_gift_card_enabled'
      );

      if (page.zola_registry && page.zola_registry.default_gift_card_enabled) {
        page.zola_registry.registry_object_id = action.payload.zola_registry.registry_object_id;
      }

      if (action.entityName === 'rsvp_questions') {
        const mealOptions = action.payload.meal_options;
        const rsvpQuestions = action.payload.rsvp_questions;
        const { events } = action.payload;
        const rsvp = Object.assign({}, { events, mealOptions, rsvpQuestions });
        return Object.assign({}, state, { busy: false, rsvp, page });
      }
      const entities = action.entities || action.payload[action.entityName];
      return {
        ...state,
        entities,
        page,
        busy: false,
        zolaRegistry: action.payload.zola_registry,
      };
    }
    // busy, error
    case ActionTypes.NOT_FOUND: {
      return { ...state, busy: false, error: action.error };
    }
    // busy
    case ActionTypes.REQUEST_GUEST_RSVP: {
      return { ...state, busy: true };
    }
    // busy, guestGroupRsvp
    case ActionTypes.RECEIVE_GUEST_RSVP: {
      return { ...state, busy: false, guestGroupRsvp: action.payload };
    }
    case ActionTypes.RESET_GUEST_RSVP: {
      return { ...state, busy: false, guestGroupRsvp: {} };
    }
    // busy, guestSearch
    case ActionTypes.RECEIVE_GUEST_SEARCH: {
      const guestSearch = action.payload.length === 0 ? ['NOT_FOUND'] : action.payload;
      return { ...state, busy: false, guestSearch };
    }
    // busy, guestSearch
    case ActionTypes.RESET_GUEST_SEARCH: {
      return { ...state, busy: false, guestSearch: [] };
    }
    // wedding
    case ActionTypes.RECEIVE_WEBSITE_VISIBILITY: {
      const wedding = { ...state.wedding, wedding_published: !state.wedding.wedding_published };
      return { ...state, wedding };
    }
    // busy, guestGroupRsvp
    case ActionTypes.RECEIVE_GUEST_RSVP_SUCCESS: {
      const guestGroupRsvp = { ...state.guestGroupRsvp, ...action.payload };
      return { ...state, busy: false, guestGroupRsvp };
    }
    // busy, guestRequestObject
    case ActionTypes.GET_GUEST_REQUESTS: {
      return { ...state, busy: false, guestRequestObject: action.payload };
    }
    // busy, guestRequestByUuid
    case ActionTypes.RECEIVE_GUEST_REQUEST_BY_UUID: {
      return { ...state, busy: false, guestRequestByUuid: action.payload };
    }
    // busy, guestRequestSubmitted
    case ActionTypes.RSVP_REQUEST_SUBMITTED: {
      return { ...state, busy: false, guestRequestSubmitted: action.payload };
    }
    // rsvpAccessForm
    case ActionTypes.REVEAL_ACCESS_FORM: {
      return { ...state, rsvpAccessForm: action.payload };
    }
    // guestRequestByUuid, guestRequestObject
    case ActionTypes.GUEST_SUCCESSFULLY_SUBMITTED: {
      return { ...state, guestRequestByUuid: {}, guestRequestObject: [] };
    }
    // rsvpAccessForm, guestSearch
    case ActionTypes.HIDE_ACCESS_FORM: {
      return { ...state, rsvpAccessForm: action.payload, guestSearch: [] };
    }
    // guestRequestCount
    case ActionTypes.RECEIVE_GUEST_COUNT: {
      return { ...state, guestRequestCount: action.payload };
    }
    // token
    case ActionTypes.STORE_MOBILE_TOKEN: {
      return { ...state, token: action.payload };
    }
    // rsvpShowSummary
    case ActionTypes.SHOW_RSVP_SUMMARY: {
      return { ...state, rsvpShowSummary: action.payload };
    }
    // rsvpModalAction
    case ActionTypes.HANDLE_RSVP_MODAL_ACTION: {
      return { ...state, rsvpModalAction: action.payload };
    }
    // cmsBanners
    case ActionTypes.RECEIVE_BANNER_LIST: {
      return { ...state, cmsBanners: action.payload.data };
    }
    case ActionTypes.RECEIVE_VIRTUAL_EVENT_PAGE:
      return { ...state, currentVirtualEvent: action.payload };
    default:
      return state;
    // isCustomZIndex for filter container
    case ActionTypes.HIDE_TOP_NAVIGATION: {
      return { ...state, isCustomZIndex: !state.isCustomZIndex };
    }
  }
};

export default publicReducer;
