import {
  getLastDraftEditDate,
  getMappedDraftsByCardType,
  getMappedOrders,
} from 'cards/util/customization';
import { ORDERED_CUSTOMIZATION_TYPES } from 'cards/constants/CardTypes';
import type {
  WProjectListingView,
  WProjectListingViewByType,
  WProjectOrderView,
  WProjectSampleProofOrderView,
} from '@zola/svc-web-api-ts-client';
import {
  Medium,
  CardType,
  ProjectListingView,
  ProjectListingCustomizationView,
} from 'cards/pages/ProductDetail/ProducDetailTypes';
import { ActionTypes, DraftsAndOrdersAction } from '../actions/draftsAndOrdersActions';

interface ProjectsData extends WProjectListingViewByType {
  waitingOnPreviewSuites: boolean | undefined;
  entities: {
    customizations: ProjectListingCustomizationView[];
    projects: WProjectOrderView[];
  };
}

type DraftsMap = Record<CardType, ProjectListingView[]>;

interface draftsAndOrdersState {
  activeDraftsTab: string;
  activeSegment: string | null;
  activeAnchor: string | null;
  busy: boolean;
  waitingOnPreviewSuites: boolean;
  drafts: Record<Exclude<Medium, 'MAGNET'>, DraftsMap>;
  orders: WProjectOrderView[];
  published: any[];
  entities: {
    customizations: ProjectListingCustomizationView[];
    projects: WProjectOrderView[];
  };
  proofDrafts: DraftsMap;
  proofOrders: WProjectSampleProofOrderView[];
  samples: WProjectSampleProofOrderView[];
}

const init: draftsAndOrdersState = {
  activeDraftsTab: 'all',
  activeSegment: null,
  activeAnchor: null,
  busy: false,
  waitingOnPreviewSuites: false,
  drafts: {
    PAPER: {
      INVITATION: [],
      RSVP: [],
      SAVE_THE_DATE: [],
      CHANGE_THE_DATE: [],
      ENCLOSURE: [],
      MENU: [],
      PROGRAM: [],
      PLACE: [],
      TABLE_NUMBER: [],
      THANK_YOU: [],
      ENVELOPE: [],
      INVITATION_ENVELOPE: [],
      RSVP_ENVELOPE: [],
      HOLIDAY: [],
    },
    DIGITAL: {
      INVITATION: [],
      RSVP: [],
      SAVE_THE_DATE: [],
      CHANGE_THE_DATE: [],
      ENCLOSURE: [],
      MENU: [],
      PROGRAM: [],
      PLACE: [],
      TABLE_NUMBER: [],
      THANK_YOU: [],
      ENVELOPE: [],
      INVITATION_ENVELOPE: [],
      RSVP_ENVELOPE: [],
      HOLIDAY: [],
    },
  },
  orders: [],
  published: [],
  entities: {
    customizations: [],
    projects: [],
  },
  proofDrafts: {
    INVITATION: [],
    RSVP: [],
    SAVE_THE_DATE: [],
    CHANGE_THE_DATE: [],
    ENCLOSURE: [],
    MENU: [],
    PROGRAM: [],
    PLACE: [],
    TABLE_NUMBER: [],
    THANK_YOU: [],
    ENVELOPE: [],
    INVITATION_ENVELOPE: [],
    RSVP_ENVELOPE: [],
    HOLIDAY: [],
  },
  proofOrders: [],
  samples: [],
};

const reducer = (
  state: draftsAndOrdersState = init,
  action: DraftsAndOrdersAction
): draftsAndOrdersState => {
  switch (action.type) {
    case ActionTypes.DELETE_DRAFT: {
      const { projectUUID, medium, cardType } = action.payload as {
        projectUUID: string;
        medium: string;
        cardType: CardType;
      };
      const { drafts } = state;
      const localMedium = medium === 'PAPER' || medium === 'MAGNET' ? 'PAPER' : 'DIGITAL';

      let newDrafts;

      const draftToDelete = drafts[localMedium][cardType].find(
        ({ project_uuid }) => project_uuid === projectUUID
      );

      if (draftToDelete?.version_history && draftToDelete.version_history.length > 0) {
        const { version_history: versionHistory } = draftToDelete;
        const newCardTypeDrafts = drafts[localMedium][cardType]
          .concat(versionHistory)
          .filter(({ project_uuid }) => project_uuid !== projectUUID)
          // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must...
          .sort((a, b) => getLastDraftEditDate(b) - getLastDraftEditDate(a));

        // @ts-expect-error ts-migrate(7034) FIXME: Variable 'mappedNewCardTypeDrafts' implicitly has ...
        const mappedNewCardTypeDrafts = [];

        newCardTypeDrafts.forEach(draft => {
          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'mappedNewCardTypeDrafts' implicitly has ...
          const existingDraft = mappedNewCardTypeDrafts.find(
            ({ project_name }: { project_name: string }) => project_name === draft.project_name
          );

          if (!existingDraft) {
            mappedNewCardTypeDrafts.push(draft);
          } else {
            existingDraft.version_history.push(draft);
          }
        });

        // @ts-expect-error ts-migrate(7005) FIXME: Variable 'mappedNewCardTypeDrafts' implicitly has ...
        newDrafts = mappedNewCardTypeDrafts;
      } else {
        newDrafts = drafts[localMedium][cardType].filter(
          ({ project_uuid }) => project_uuid !== projectUUID
        );
      }

      return {
        ...state,
        drafts: {
          ...state.drafts,
          [localMedium]: {
            ...state.drafts[localMedium],
            [cardType]: newDrafts,
          },
        },
      };
    }
    case ActionTypes.DELETE_PUBLISHED: {
      const { projectUUID } = action.payload;
      const { published } = state;

      const updatedPublished = published.filter(({ project_uuid }) => project_uuid !== projectUUID);

      return {
        ...state,
        published: updatedPublished,
      };
    }
    case ActionTypes.MOVE_PUBLISHED_TO_DRAFTS: {
      const { projectUUID } = action.payload;
      const { drafts, published } = state;

      const project = published.find(({ project_uuid }) => project_uuid === projectUUID);
      const updatedPublished = published.filter(({ project_uuid }) => project_uuid !== projectUUID);
      const updatedDrafts = [...drafts.DIGITAL.SAVE_THE_DATE, project];

      return {
        ...state,
        drafts: {
          ...state.drafts,
          ...updatedDrafts,
        },
        published: updatedPublished,
      };
    }
    case ActionTypes.REQUEST_PROJECTS:
      return {
        ...state,
        busy: true,
        waitingOnPreviewSuites: true,
      };
    case ActionTypes.RECEIVE_PROJECTS: {
      const { waitingOnPreviewSuites, all_drafts, orders, published, entities } = action.payload
        .projects as ProjectsData;

      // * Drafts mapping
      // * Get all the physical (paper/magnet) drafts, digital, and proofs
      const sortDraftsPerMedium = all_drafts?.reduce(
        (physicalDrafts, currentDraft) => {
          const leadCustomizationMedium =
            currentDraft.customizations?.find(customization => !!customization.lead)?.medium ||
            'PAPER';
          const leadCustomizationType =
            currentDraft.customizations?.find(customization => !!customization.lead)?.type ||
            'SAVE_THE_DATE';
          const leadCustomizationQty =
            currentDraft.customizations?.find(customization => !!customization.lead)?.quantity || 1;
          // * Paper drafts are a combinaisons of the two mediums, PAPER and MAGNET
          const currentMedium =
            leadCustomizationMedium === 'MAGNET' ? 'PAPER' : leadCustomizationMedium;

          // * Sort drafts per medium
          if (!physicalDrafts[currentMedium][leadCustomizationType]) {
            // eslint-disable-next-line no-param-reassign
            physicalDrafts[currentMedium][leadCustomizationType] = [currentDraft];
          } else {
            physicalDrafts[currentMedium][leadCustomizationType].push(currentDraft);
          }

          // * store proof physical drafts
          if (
            !physicalDrafts.PROOF[leadCustomizationType] &&
            leadCustomizationMedium !== 'DIGITAL' &&
            leadCustomizationQty === 1
          ) {
            // eslint-disable-next-line no-param-reassign
            physicalDrafts.PROOF[leadCustomizationType] = [currentDraft];
          } else if (
            physicalDrafts.PROOF[leadCustomizationType] &&
            leadCustomizationMedium !== 'DIGITAL' &&
            leadCustomizationQty === 1
          ) {
            physicalDrafts.PROOF[leadCustomizationType].push(currentDraft);
          }

          return physicalDrafts;
        },
        { PAPER: {}, PROOF: {}, DIGITAL: {} } as Record<
          string,
          Record<string, WProjectListingView[]>
        >
      );

      // * Orders paper/magnet
      const mappedOrders =
        orders?.sort(
          (a, b) =>
            new Date(b.created_at as Date).getTime() - new Date(a.created_at as Date).getTime()
        ) || [];

      // * Proof/Sample Orders
      const proofAndSampleOrders = mappedOrders.reduce(
        (sortedOrders, order) => {
          const leadProjectCustomizationQuantity =
            order.projects?.[0].customizations?.find(customization => !!customization.lead)
              ?.quantity || 0;
          const isSampleOrder = order.projects?.[0].single_sample;
          const orderEntry = {
            split_order_id: order.split_order_id,
            created_at: order.created_at,
            order_status: order.order_status,
            shipping_link: order.shipping_link,
            project: order.projects?.[0],
          };
          const orderType = isSampleOrder ? 'SAMPLES' : 'PROOFS';

          if (leadProjectCustomizationQuantity === 1) {
            sortedOrders[orderType].push(orderEntry);
          }

          return sortedOrders;
        },
        { PROOFS: [], SAMPLES: [] } as Record<string, Partial<WProjectOrderView>[]>
      );

      // * Published digital
      const mappedPublished = getMappedOrders(published);

      return {
        ...state,
        busy: false,
        waitingOnPreviewSuites: Boolean(waitingOnPreviewSuites),
        drafts: {
          ...state.drafts,
          PAPER: {
            ...state.drafts.PAPER,
            ...getMappedDraftsByCardType(sortDraftsPerMedium?.PAPER),
          },
          DIGITAL: {
            ...state.drafts.DIGITAL,
            ...getMappedDraftsByCardType(sortDraftsPerMedium?.DIGITAL),
          },
        },
        orders: mappedOrders,
        published: mappedPublished,
        entities,
        proofDrafts: sortDraftsPerMedium?.PROOF as DraftsMap,
        proofOrders: proofAndSampleOrders.PROOFS,
        samples: proofAndSampleOrders.SAMPLES,
      };
    }
    case ActionTypes.RECEIVE_CANCELLED_ORDER: {
      const { cancelledOrder } = action.payload;
      const { orders } = state;

      const updatedOrders = orders.map(order =>
        cancelledOrder.split_order_id === order.split_order_id ? cancelledOrder : order
      );

      return {
        ...state,
        orders: updatedOrders,
      };
    }
    case ActionTypes.SET_ACTIVE_DRAFTS_TAB: {
      const { key } = action.payload;

      return {
        ...state,
        activeDraftsTab: ORDERED_CUSTOMIZATION_TYPES.includes(key) ? key : 'all',
      };
    }
    case ActionTypes.SET_ACTIVE_SEGMENT: {
      const { key } = action.payload;

      return {
        ...state,
        activeSegment: key,
      };
    }
    case ActionTypes.SET_ACTIVE_ANCHOR: {
      const { anchor } = action.payload;

      return {
        ...state,
        activeAnchor: anchor,
      };
    }
    case ActionTypes.TOGGLE_WAITING_ON_PREVIEW_SUITES: {
      const { waitingOnPreviewSuites } = action.payload;

      return {
        ...state,
        waitingOnPreviewSuites,
      };
    }
    default:
      return state;
  }
};

export default reducer;
