import React, { Fragment } from 'react';
import { getTextColor } from 'cards/components/Customization/customizationUtils';
import { CARD_BLEED_PT } from 'cards/util';
import { calculateLineHeight } from 'cards/util/lineHeight';
import { textLayerTopSpacing } from 'paper/utils/fontCalibration/fonts';
import featureFlags from 'util/featureFlags';
import { getPlaceCardAlignment, getElementAlignment } from './utils';
import styles from './styles.module.less';

const TextElement = ({ element, foilColor = '', isPlaceCard = false, isBoundingBoxAdjusted }) => {
  if (!element || !element.body) {
    return null;
  }
  const hasVerticalAlignmentFix = featureFlags.get('paperVerticalAlignmentFix');
  const lines = element.body.split('\n');

  const body = lines.map((s, i) => (
    <Fragment key={i}>
      {s}
      {i !== lines.length - 1 && <br />}
    </Fragment>
  ));

  const characterSpacing = element.font.character_spacing || element.character_spacing;
  const letterSpacing = characterSpacing ? characterSpacing * 0.001 : 0;
  const originalTextColor = element.font.font_color || element.font_color || 'black';
  const textColor = getTextColor(originalTextColor, element.printing_technique, foilColor);

  const [verticalAlignment, horizontalAlignment] = getElementAlignment(element);
  const placeCardAlignment = isPlaceCard
    ? getPlaceCardAlignment(verticalAlignment, horizontalAlignment)
    : {};

  /*
   * Because our templates dimensions includes the bleed value
   * it's deducted on the preview container context for Sample preview / Digital canvas preview.
   * Thus, elements positions needs to be adjusted.
   */
  const elementLeftPos = element.x - CARD_BLEED_PT;
  const elementTopPos = element.y - CARD_BLEED_PT;
  const lineHeight = element?.font?.line_height || element.line_height;
  const fontSize = element?.font?.font_size || element.font_size;
  const fontFamily = element.font.font_family || element.font_family;
  const adjustedElementTopPos = hasVerticalAlignmentFix
    ? textLayerTopSpacing({ lineHeight, fontSize, body, fontFamily }) + elementTopPos
    : elementTopPos;
  const style = {
    zIndex: element.layer,
    left: `${elementLeftPos}pt`,
    top: `${adjustedElementTopPos}pt`,
    width: `${element.width}pt`,
    height: `${element.height}pt`,
    color: `#${textColor}`,
    fontFamily: `'${fontFamily}'`,
    fontSize: `${element.font.font_size || element.font_size}pt`,
    fontStyle: element.font.font_style || element.font_style,
    fontWeight: `${element.font.font_weight || element.font_weight}`,
    fontKerning: element.font?.enable_kerning ? 'normal' : 'none',
    fontVariantLigatures: element.font?.enable_ligatures ? 'common-ligatures' : 'none',
    textAlign: horizontalAlignment,
    verticalAlign: verticalAlignment,
    letterSpacing: `${letterSpacing}em`,
    lineHeight: `${calculateLineHeight(
      isBoundingBoxAdjusted,
      element.body,
      fontSize,
      lineHeight
    )}pt`,
    paddingTop: 0,
    // @TODO COMMERCE-1398 Enable this when we want to support all_caps in FE
    // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1398
    // textTransform: element.all_caps ? 'uppercase' : 'none',
    ...placeCardAlignment,
  };

  return (
    <div className={styles.textLayer} style={style}>
      {body}
    </div>
  );
};

export default TextElement;
