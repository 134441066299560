import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import _hasIn from 'lodash/hasIn';

import type {
  SetWeddingAccountFlagRequest,
  UpdateSearchSettings,
  UpdateWeddingAccountRequest,
  UpdateWeddingRequest,
  WThemeGroupView,
  WThemeView,
  WWeddingAccountView,
  WWeddingView,
  UpdateWebsiteDateRequest,
  WWeddingThemeView,
  WThemeGroupDetailView,
  UpdateCmsPageHiddenRequest,
  WCmsPageView,
} from '@zola/svc-web-api-ts-client';
import { showModal } from 'actions/ModalActions';
import { SetStateAction } from 'react';
import * as ActionType from './types/WeddingActionTypes';
import type { WeddingActionTypes } from './types/WeddingActionTypes';
import {
  getWeddingTheme,
  getWeddingThemeKey,
  getWeddingThemeGroupTitle,
  getWeddingThemeName,
} from '../selectors/website/websiteSelectors';
import type { AppDispatch, AppThunk } from '../reducers';
import { togglePoiMaps } from './PublicWebsiteActions';
import { bulkUpdateCmsPageVisibility } from './website/PageActions';

function requestWedding(): WeddingActionTypes {
  return {
    type: ActionType.REQUEST_WEDDING,
  };
}

export function receiveWeddingAccount(json: WWeddingAccountView): WeddingActionTypes {
  return {
    type: ActionType.RECEIVE_WEDDING_ACCOUNT,
    payload: {
      account: json,
    },
  };
}

function receiveWedding(json: WWeddingView): WeddingActionTypes {
  return {
    type: ActionType.RECEIVE_WEDDING,
    payload: {
      wedding: json,
    },
  };
}

function requestTheme(): WeddingActionTypes {
  return {
    type: ActionType.THEME_REQUESTED,
  };
}

// V1 - WThemeView | V2 - WWeddingThemeView
export function receiveTheme(
  json: WThemeView | WWeddingThemeView
): ActionType.ReceiveThemeActionType {
  return {
    type: ActionType.THEME_RECEIVED,
    payload: {
      theme: json,
    },
  };
}

function requestThemeGroup(): WeddingActionTypes {
  return {
    type: ActionType.THEMEGROUP_REQUESTED,
  };
}

// V1 - WThemeGroupView | V2 - WThemeGroupDetailView
export function receiveThemeGroup(
  json: WThemeGroupView | WThemeGroupDetailView
): WeddingActionTypes {
  return {
    type: ActionType.THEMEGROUP_RECEIVED,
    payload: {
      theme: json,
    },
  };
}

// This may not be needed but i need to get something to prod
export function disableWebsiteOnboarding(): WeddingActionTypes {
  return {
    type: ActionType.DISABLE_WEBSITE_ONBOARDING,
  };
}

export function enableGuestListOnboarding(): WeddingActionTypes {
  return {
    type: ActionType.ENABLE_GUEST_LIST_ONBOARDING,
  };
}

export function enableGuestListTour(): WeddingActionTypes {
  return {
    type: ActionType.ENABLE_GUEST_LIST_TOUR,
  };
}

export function fetchWedding(): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestWedding());
    return ApiService.get<WWeddingView>('/web-api/v1/wedding').then(json =>
      dispatch(receiveWedding(json))
    );
  };
}

// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export function fetchWeddingAccount(): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestWedding());
    return ApiService.get<WWeddingAccountView>('/web-api/v1/weddingAccount').then(json =>
      dispatch(receiveWeddingAccount(json))
    );
  };
}

// FIXME This does not belong here. It should live in a file called ThemeActions
export function fetchWeddingThemeGroup(key?: string): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestThemeGroup());
    return ApiService.get<WThemeGroupView>(`/web-api/v2/theme/${key}`).then(json =>
      dispatch(receiveThemeGroup(json))
    );
  };
}

// FIXME This does not belong here. It should live in WeddingThemeActions
export function fetchWeddingTheme(): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestTheme());
    return ApiService.get<WThemeView>('/web-api/v1/wedding/theme').then(json => {
      dispatch(receiveTheme(json));
      return dispatch(fetchWeddingThemeGroup(json.key));
    });
  };
}

// FIXME This does not belong here. It should live in WeddingThemeActions
export function updateWeddingTheme(
  themeId: number,
  toastHeadlineType: 'group' | 'individual' = 'group'
): AppThunk<Promise<void>> {
  return (dispatch, getState): Promise<void> => {
    return ApiService.post<WThemeView>('/web-api/v1/wedding/theme', {
      theme_id: themeId,
    })
      .then(json => dispatch(receiveTheme(json)))
      .then(() => dispatch(fetchWeddingThemeGroup(getWeddingThemeKey(getState()))))
      .then(() => {
        const customizePath = '/wedding/manage/website/customize';
        const isCustomizable = _hasIn(getWeddingTheme(getState()), 'customization_default');
        const customizableLink =
          isCustomizable && window.location.pathname !== customizePath
            ? {
                linkText: 'Customize Fonts & Colors',
                linkDestination: customizePath,
              }
            : {};
        dispatch(
          toastsActions.positive({
            headline: `${
              toastHeadlineType === 'group'
                ? getWeddingThemeGroupTitle(getState())
                : getWeddingThemeName(getState())
            } Design Selected`,
            ...customizableLink,
          })
        );
      });
  };
}

// FIXME This does not belong here. Should live in a file called WeddingAccountActions
export function updateWebsitePublish(enable: boolean): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    ApiService.post('/web-api/v1/weddingAccount/publish', { enable }).then(() =>
      dispatch(fetchWeddingAccount())
    );
}

export type UpdateSearchSettingsClientType = Omit<UpdateSearchSettings, 'account_id'>;

export function updateSearchSettings(
  request: UpdateSearchSettingsClientType
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    ApiService.put<WWeddingView>('/web-api/v1/wedding/searchSettings', request).then(json =>
      dispatch(receiveWedding(json))
    );
}

// TODO: remove and replace all instances with updateWebsitePublish
// FIXME This does not belong here. Should live in a file called WeddingAccountActions
export const toggleWebsite = (value: boolean): AppThunk<Promise<WeddingActionTypes>> => (
  dispatch
): Promise<WeddingActionTypes> => {
  return dispatch(updateWebsitePublish(value));
};

export type UpdateWeddingAccountRequestClientType = Omit<
  UpdateWeddingAccountRequest,
  'wedding_account_id' | 'account_id'
>;

// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export function updateWeddingAccount(
  request: UpdateWeddingAccountRequestClientType
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    ApiService.post<WWeddingAccountView>('/web-api/v1/weddingAccount/update', request).then(json =>
      dispatch(receiveWeddingAccount(json))
    );
}

export type UpdateWeddingRequestClientType = Omit<UpdateWeddingRequest, 'account_id'>;

export const postUpdateWedding = (body: UpdateWeddingRequestClientType): Promise<WWeddingView> => {
  return ApiService.post<WWeddingView>('/web-api/v1/wedding/update', body);
};

export function updateWedding(
  request: UpdateWeddingRequestClientType
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    postUpdateWedding(request).then(json => dispatch(receiveWedding(json)));
}

// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export function updateShowPoiMap(hidden: boolean): AppThunk<Promise<void>> {
  return (dispatch, getState): Promise<void> => {
    const weddingAccount = getState().wedding.account;
    weddingAccount.enable_poi_maps = hidden;
    return dispatch(updateWeddingAccount(weddingAccount as UpdateWeddingAccountRequest)).then(
      () => {
        dispatch(togglePoiMaps());
      }
    );
  };
}

// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export function updateGuestListOnboarding(enable: boolean): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    ApiService.post<WWeddingAccountView>('/web-api/v1/weddingAccount/guest_list_onboarding', {
      enable,
    }).then(() => dispatch(fetchWeddingAccount()));
}

// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export function updateFeatureFlag(
  uuid: string,
  flags: SetWeddingAccountFlagRequest
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    ApiService.post('/web-api/v1/weddingAccount/features', { uuid, flags }).then(() =>
      dispatch(fetchWeddingAccount())
    );
}

export function updateCustomDomain(customDomain: string): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return ApiService.put<WWeddingView>('/web-api/v2/wedding/custom_domain', {
      custom_domain: customDomain,
    }).then((response: WWeddingView) => {
      dispatch(receiveWedding(response));
    });
  };
}

// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export const postUpdateWeddingDate = (
  body: UpdateWebsiteDateRequest
): Promise<WWeddingAccountView> => {
  return ApiService.post<WWeddingAccountView>('/web-api/v1/weddingAccount/update-date', body);
};

export const submitWedding = (
  weddingRequest: UpdateWeddingRequestClientType,
  disableToast?: boolean,
  cb?: () => void
): AppThunk<Promise<WWeddingView>> => {
  return (dispatch): Promise<WWeddingView> => {
    return postUpdateWedding(weddingRequest).then((weddingRes: WWeddingView) => {
      dispatch(receiveWedding(weddingRes));
      if (!disableToast) {
        dispatch(toastsActions.positive({ headline: 'Wedding Details Updated' }));
      }
      if (cb) {
        cb();
      }
      return weddingRes;
    });
  };
};

export const submitWeddingAndWebsiteDate = (
  weddingRequest: UpdateWeddingRequestClientType,
  websiteDateRequest: UpdateWebsiteDateRequest,
  disableToast?: boolean,
  cb?: () => void
): AppThunk<Promise<[WWeddingView, WWeddingAccountView]>> => {
  return (dispatch): Promise<[WWeddingView, WWeddingAccountView]> => {
    return postUpdateWedding(weddingRequest).then((weddingRes: WWeddingView) => {
      return postUpdateWeddingDate(websiteDateRequest).then(
        (weddingAccountRes: WWeddingAccountView) => {
          if (weddingAccountRes) {
            dispatch(receiveWeddingAccount(weddingAccountRes));
          }
          if (weddingRes) {
            dispatch(receiveWedding(weddingRes));
          }
          if (!disableToast) {
            dispatch(toastsActions.positive({ headline: 'Wedding Details Updated' }));
          }
          if (cb) {
            cb();
          }
          return [weddingRes, weddingAccountRes];
        }
      );
    });
  };
};

// FIXME This does not belong here. It should live in a file called ThemeActions
export const fetchWeddingThemeGroupV2 = (key?: string): AppThunk<Promise<WeddingActionTypes>> => {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestThemeGroup());
    return ApiService.get<WThemeGroupDetailView>(`/web-api/v3/theme/key/${key}/group`)
      .then(data => dispatch(receiveTheme(data)))
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Unable to fetch wedding theme group');
      });
  };
};

// FIXME This does not belong here. It should live in WeddingThemeActions
export const updateWeddingWebsiteThemeV2 = (
  themeKey: string,
  themeLayout?: string,
  fromOnboarding?: boolean
): AppThunk<Promise<void>> => {
  const payload: {
    themeKey: string;
    themeLayout?: string;
  } = { themeKey };
  if (themeLayout) {
    payload.themeLayout = themeLayout;
  }
  return (dispatch, getState): Promise<void> => {
    return ApiService.put<WWeddingThemeView>('/web-api/v2/weddingTheme/update', payload)
      .then(data => dispatch(receiveTheme(data)))
      .then(() => dispatch(fetchWeddingThemeGroupV2(themeKey)))
      .then(() => {
        if (fromOnboarding !== false) {
          dispatch(
            toastsActions.positive({
              headline: `${getWeddingThemeGroupTitle(getState())} Design Selected`,
            })
          );
        }
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Unable to update wedding theme');
      });
  };
};

// Takes in array of pages with IDs and makes action call to hide them
// FIXME This does not belong here. It should live in a file called WeddingAccountActions
const hidePages = (
  pagesToHide: Omit<UpdateCmsPageHiddenRequest, 'wedding_account_id'>[],
  receivePageList?: () => void
) => {
  return (dispatch: AppDispatch) => {
    if (pagesToHide && pagesToHide.length > 0) {
      dispatch(bulkUpdateCmsPageVisibility(pagesToHide))
        .then(() => {
          if (receivePageList) {
            receivePageList();
          }
        })
        .catch(() => undefined);
    }
  };
};

// Checks over array of pages from redux store and determines which pages should be hidden
// post: string array of page titles
// Hidden criteia: set to visible with no information on the page, Home is excluded.
// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export const hideEmptyPages = (
  pages: Record<number, WCmsPageView>,
  receivePageList?: () => void
) => {
  const hiddenPagesListArray: string[] = [];
  const pagesToHideArray: SetStateAction<{ id: number; hidden: boolean }[] | undefined> = [];
  return (dispatch: AppDispatch) => {
    for (const key in pages) {
      if (
        !pages[key].hidden &&
        !pages[key].entities_summary &&
        pages[key].type !== 'HOME' &&
        pages[key].type !== 'REGISTRY'
      ) {
        hiddenPagesListArray.push(pages[key].nav_title as string);
        pagesToHideArray.push({ id: pages[key].id as number, hidden: true });
      }
    }
    dispatch(hidePages(pagesToHideArray, receivePageList));
    return hiddenPagesListArray;
  };
};

// New modal flow for popping publish website modals
// FIXME This does not belong here. It should live in a file called WeddingAccountActions
export const handleEasyPublishing = (
  hiddenPagesList: string[],
  wedding: WWeddingView,
  isPublished: boolean,
  hidePrivacyLink?: boolean
) => {
  return (dispatch: AppDispatch) => {
    if (!wedding.wedding_date && !isPublished) {
      dispatch(
        showModal(
          'WEBSITE_PUBLISH_WEDDING_DATE',
          { hiddenPagesList, hidePrivacyLink },
          { size: 'v2' }
        )
      );
    } else {
      dispatch(toggleWebsite(!isPublished))
        .then(() => {
          if (!isPublished) {
            dispatch(
              showModal(
                'WEBSITE_PUBLISH_CONFIRMATION',
                { hiddenPagesList, hidePrivacyLink },
                { size: 'v2' }
              )
            );
          }
        })
        .catch(() => {
          dispatch(toastsActions.positive({ headline: 'Error publishing website' }));
        });
    }
  };
};
