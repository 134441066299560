/*
 * Based off the font family names declared
 * in https://github.com/NewAmsterdamLabs/web-wedding/blob/development/src/cards/stylesheets/_fonts.less
 */
export const FONTS_VERTICAL_MULTILINE_SPACING_VALUES = {
  'Adora Bouton': {
    hasMultiSingleParity: false,
    default: 0,
    NoHeightParity: 0,
  },
  Bellanaisa: {
    hasMultiSingleParity: true,
    default: 3,
    NoHeightParity: 4,
  },
  'Salma Alianda Script': {
    hasMultiSingleParity: true,
    default: 5,
    NoHeightParity: 5,
  },
  'Macadamia Smooth': {
    hasMultiSingleParity: false,
    default: 0,
    NoHeightParity: 0,
  },
  'Amira Madison Script': {
    hasMultiSingleParity: false,
    default: 0,
    NoHeightParity: 0,
  },
  'Aston Script': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Floral Hearts': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Mindfully Alternate': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Monsieur La Doulaise': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Petit Formal Script': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  Quarzo: {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Real one specific': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Real one specific script': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Rouge Script': {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  Rushfor: {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  Weisston: {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Alexander Lettering': {
    hasMultiSingleParity: true,
    default: -4,
    NoHeightParity: -4,
  },
  Kinfolk: {
    hasMultiSingleParity: true,
    default: -2,
    NoHeightParity: -2,
  },
  Hatton: {
    hasMultiSingleParity: true,
    default: 1,
    NoHeightParity: 1,
  },
  'Crimson Text': {
    hasMultiSingleParity: true,
    default: 0.4,
    NoHeightParity: 0.4,
  },
  Amaryllis: {
    hasMultiSingleParity: true,
    default: 0.75,
    NoHeightParity: 0.75,
  },
  Karla: {
    hasMultiSingleParity: true,
    default: 1.125,
    NoHeightParity: 1.125,
  },
  'Mrs Eaves Roman All Small Caps': {
    hasMultiSingleParity: true,
    default: 0.75,
    NoHeightParity: 0.75,
  },
  Raleway: {
    hasMultiSingleParity: true,
    default: 0.75,
    NoHeightParity: 0.75,
  },
  'Astoria Sans': {
    hasMultiSingleParity: true,
    default: 1.125,
    NoHeightParity: 1.125,
  },
  'Ms Jollie': {
    hasMultiSingleParity: true,
    default: -6,
    NoHeightParity: -6,
  },
};
