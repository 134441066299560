import _isEqual from 'lodash/isEqual';
import { ActionTypes } from '../actions/cardCatalogActions';

// helper functions
const enrichThemeMatchedCards = cards =>
  Object.keys(cards).reduce(
    (acc, card) => ({
      ...acc,
      [card]: {
        ...cards[card],
        isThemeMatchedCard: true,
      },
    }),
    {}
  );

const createVariationsOptionsValues = cardVariations =>
  cardVariations.map(variation => ({
    uuid: variation.uuid,
    ...variation.option_values,
  }));

const mapSearchIndexToSuite = (suites, offset) => {
  return suites.map((suite, i) => ({
    ...suite,
    lead_card: {
      ...suite.lead_card,
      searchIndex: offset + i,
    },
  }));
};

const createSortedSuites = (state, suites) =>
  suites.reduce(
    (result, suite) => {
      if (!result.byUUID[suite.uuid]) {
        result.orderedUUIDs.push(suite.uuid);
      }
      result.byUUID[suite.uuid] = suite; // eslint-disable-line no-param-reassign
      return result;
    },
    {
      byUUID: { ...state.suites.byUUID },
      orderedUUIDs: [...state.suites.orderedUUIDs],
    }
  );

const prependSortedSuites = (state, suites) => {
  const reversed = suites.reverse();
  return reversed.reduce(
    (result, suite) => {
      if (!result.byUUID[suite.uuid]) {
        result.orderedUUIDs.unshift(suite.uuid);
      }
      result.byUUID[suite.uuid] = suite; // eslint-disable-line no-param-reassign
      return result;
    },
    {
      byUUID: { ...state.suites.byUUID },
      orderedUUIDs: [...state.suites.orderedUUIDs],
    }
  );
};

const splitCardSuitesIntoByUUIDAndOrderedUUIDs = suites => {
  const byUUID = {};
  suites.forEach(suite => {
    byUUID[suite.uuid] = suite;
  });
  const orderedUUIDs = suites.map(suite => suite.uuid);
  return {
    byUUID,
    orderedUUIDs,
  };
};

const sortSizes = opts => {
  /**
   * * When called, the assumption is that there is a size attribute, with options.
   * * For some reasons, Digital Cards are sometimes returned with missing data.
   * * Until data is fixed, this is a quick workaround
   */
  if (!opts.size) {
    return {
      ...opts,
    };
  }

  return {
    ...opts,
    size: {
      ...opts.size,
      options: opts.size && opts.size.options,
    },
  };
};

const createOptionGroups = optGroups =>
  optGroups.reduce(
    (result, optionGroup) => {
      result.orderedKeys.push(optionGroup.key);
      result[optionGroup.key] = { ...optionGroup }; // eslint-disable-line no-param-reassign
      return result;
    },
    { orderedKeys: [] }
  );

const createOptionGroupsByKey = optGroups => sortSizes(createOptionGroups(optGroups));

const initialState = {
  busy: false,
  initial: true,
  filters: {
    limit: 12, // integer filter, defaults to 12
    offset: 0, // integer filter, starts at zero, increments by limit
    color: null, // string filter, defaults to null,
    seo_colors: [], // string array filter, defaults to empty array
    motifs: [],
    silhouettes: [],
    lead_card_type: null, // string filter, defaults to null
    lead_card_types: null, // string array filter, defaults to null
    active_only: true, // boolean filter, defaults to true
    has_foil: false, // boolean filter, defaults to false
    has_custom_photo: false, // boolean filter, defaults to false
    has_no_custom_photo: false, // boolean filter, defaults to false
    has_digital: false, // boolean filter, defaults to false
    has_magnet: false, // boolean filter, defaults to false,
    is_letterpress: false, // boolean filter, defaults to false
    is_portrait: false,
    is_landscape: false,
    is_vow_renewal: false,
    featured_artist: false,
    multiColor: [],
    single_sample_available: false,
    sorts: null, // string filter, defaults to null
  },
  suites: {
    themeMatchedCards: {
      byUUID: {},
      orderedUUIDs: [],
    },
    byUUID: {},
    orderedUUIDs: [],
    count: 0,
    total: 0,
    displayableTotal: 0,
    loadedStartIndex: 0,
    loadedEndIndex: 0,
  },
  promotionData: null,
  cards: {},
  cardUUIDsByVariationUUID: {},
  collaborators: {},
  additionalSuiteVersions: [],
  relatedCardsFromSuite: [],
  relatedCardsByDesigner: [],
  paperAddOns: [], // Paper add-ons card data used for the paper add-ons PDP carousel and upsell module
  initialVariationColor: null,
  isAdminView: false,
  relatedCardsFamilyName: null,
  motifs: [],
  silhouettes: [],
  multiSampleState: {
    enabled: false,
    selected: {},
    count: {},
  },
  recentlyViewed: [],
};

// TODO: refactor to use combineReducers to split up reducer into multiple slice reducers
// this is getting unwieldy and could probably be divided into search, suites, and cards slice reducers
const cardCatalogReducer = (state = initialState, action) => {
  switch (action.type) {
    // busy
    case ActionTypes.REQUEST_SEARCH_CARD_SUITES: {
      return { ...state, busy: true };
    }
    // suites, filters, busy, initial,
    case ActionTypes.RECEIVE_SEARCH_CARD_SUITES: {
      const { cardSuites } = action.payload;

      const { total, limit, offset, color, lead_card_type } = cardSuites;
      const { byUUID, orderedUUIDs } = createSortedSuites(state, cardSuites.suites);
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          count: orderedUUIDs.length,
          total,
        },
        filters: {
          ...state.filters,
          limit,
          offset,
          color,
          lead_card_type,
        },
      };
    }
    case ActionTypes.RECEIVE_SEARCH_CARD_SUITES_BY_UUID: {
      const { cardSuites } = action.payload;
      return {
        ...state,
        busy: false,
        recentlyViewed: cardSuites,
      };
    }
    case ActionTypes.RECEIVE_THEME_MATCHED_CARD_SUITES: {
      const { cardSuites } = action.payload;
      const { byUUID, orderedUUIDs } = splitCardSuitesIntoByUUIDAndOrderedUUIDs(cardSuites.suites);
      return {
        ...state,
        busy: false,
        suites: {
          ...state.suites,
          themeMatchedCards: { byUUID: enrichThemeMatchedCards(byUUID), orderedUUIDs },
          count: orderedUUIDs.length,
        },
      };
    }
    // collaboratorsByKey
    case ActionTypes.RECEIVE_COLLABORATORS: {
      const { collaborators } = action.payload;
      const collaboratorsByKey = {};
      collaborators.forEach(collaborator => {
        collaboratorsByKey[collaborator.key] = collaborator;
      });
      return {
        ...state,
        collaborators: collaboratorsByKey,
      };
    }
    // cards
    case ActionTypes.RECEIVE_CARD: {
      const { card, variationUUID } = action.payload;
      const optionGroupsByKey = createOptionGroupsByKey(card.option_groups);
      const variationsOptionValues = createVariationsOptionsValues(card.variations);
      return {
        ...state,
        cards: {
          ...state.cards,
          [card.uuid]: {
            ...card,
            optionGroupsByKey,
            variationsOptionValues,
          },
        },
        cardUUIDsByVariationUUID: {
          ...state.cardUUIDsByVariationUUID,
          [variationUUID]: card.uuid,
        },
      };
    }
    case ActionTypes.UPDATE_CARD_CUSTOMIZATION_UUID: {
      const { cardUUID, customizationUUID } = action.payload;

      return {
        ...state,
        cards: {
          ...state.cards,
          [cardUUID]: {
            ...state.cards[cardUUID],
            customizationUUID,
          },
        },
      };
    }
    // suites, filters, initial
    case ActionTypes.UPDATE_SEARCH_FILTERS: {
      const { key, value } = action.payload;
      let returnVal;
      switch (key) {
        case 'lead_card_type': {
          returnVal = {
            ...state,
            filters: {
              ...initialState.filters,
              ...state.filters,
              motifs: state.filters.motifs,
              lead_card_type: value,
            },
          };
          break;
        }
        case 'motifs': {
          if (_isEqual(state.filters[key], value)) {
            returnVal = {
              ...state,
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          } else {
            returnVal = {
              ...state,
              busy: true,
              initial: true,
              suites: {
                ...initialState.suites,
                themeMatchedCards: state.suites.themeMatchedCards,
              },
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          }
          break;
        }
        case 'seo_colors': {
          if (_isEqual(state.filters[key], value)) {
            returnVal = {
              ...state,
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          } else {
            returnVal = {
              ...state,
              busy: true,
              initial: true,
              suites: {
                ...initialState.suites,
                themeMatchedCards: state.suites.themeMatchedCards,
              },
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          }
          break;
        }
        case 'silhouettes': {
          if (_isEqual(state.filters[key], value)) {
            returnVal = {
              ...state,
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          } else {
            returnVal = {
              ...state,
              busy: true,
              initial: true,
              suites: {
                ...initialState.suites,
                themeMatchedCards: state.suites.themeMatchedCards,
              },
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          }
          break;
        }
        case 'collaborator': {
          returnVal = {
            ...state,
            busy: true,
            initial: true,
            suites: {
              ...initialState.suites,
              themeMatchedCards: state.suites.themeMatchedCards,
            },
            filters: {
              ...state.filters,
              collaborator: value,
            },
          };
          break;
        }
        default: {
          returnVal = {
            ...state,
            busy: true,
            initial: true,
            suites: {
              ...initialState.suites,
              themeMatchedCards: state.suites.themeMatchedCards,
            },
            filters: {
              ...state.filters,
              limit: initialState.filters.limit,
              offset: initialState.filters.offset,
              [key]: value,
            },
          };
        }
      }
      return returnVal;
    }
    case ActionTypes.UPDATE_MULTIPLE_SEARCH_FILTERS: {
      const newValues = action.payload;
      const filters = {
        ...state.filters,
        limit: initialState.filters.limit,
        offset: initialState.filters.offset,
        ...newValues,
      };
      return {
        ...state,
        busy: true,
        initial: true,
        suites: {
          ...initialState.suites,
          themeMatchedCards: state.suites.themeMatchedCards,
        },
        filters,
      };
    }
    case ActionTypes.OVERWRITE_SEARCH_FILTERS: {
      const newValues = action.payload;
      return {
        ...state,
        busy: true,
        initial: true,
        suites: {
          ...initialState.suites,
          themeMatchedCards: state.suites.themeMatchedCards,
        },
        filters: {
          ...initialState.filters,
          lead_card_type: state.filters.lead_card_type,
          active_only: state.filters.active_only,
          single_sample_available: state.filters.single_sample_available,
          ...newValues,
        },
      };
    }
    // suites, filters, initial
    case ActionTypes.RESET_SEARCH_FILTERS: {
      return {
        ...state,
        busy: true,
        initial: true,
        filters: {
          ...initialState.filters,
          lead_card_type: state.filters.lead_card_type,
          active_only: state.filters.active_only,
          collaborator: state.filters.collaborator,
          digital_suite: state.filters.digital_suite,
          motifs: [],
          silhouettes: [],
        },
        suites: {
          ...initialState.suites,
          themeMatchedCards: state.suites.themeMatchedCards,
        },
      };
    }
    // initialVariationColor
    case ActionTypes.SET_INITIAL_VARIATION_COLOR: {
      const { color } = action.payload;
      return {
        ...state,
        initialVariationColor: color,
      };
    }
    // additionalSuiteVersions
    case ActionTypes.RECEIVE_ADDITIONAL_SUITE_VERSIONS: {
      return {
        ...state,
        additionalSuiteVersions: action.payload,
      };
    }
    // relatedCardsFromSuite
    case ActionTypes.RECEIVE_RELATED_CARDS_FROM_SUITE: {
      const { relatedCards } = action.payload;
      return {
        ...state,
        relatedCardsFromSuite: relatedCards.suites,
      };
    }
    // relatedCardsFromSuite
    case ActionTypes.RESET_RELATED_CARDS_FROM_SUITE: {
      return {
        ...state,
        relatedCardsFromSuite: initialState.relatedCardsFromSuite,
      };
    }
    case ActionTypes.RECEIVE_RELATED_CARDS_BY_DESIGNER: {
      const { relatedCards } = action.payload;

      return {
        ...state,
        relatedCardsByDesigner: relatedCards.suites,
      };
    }
    case ActionTypes.RECEIVE_PAPER_ADD_ONS: {
      const { paperAddOns } = action.payload;

      return {
        ...state,
        paperAddOns: paperAddOns.suites,
      };
    }
    case ActionTypes.RESET_RELATED_CARDS_BY_DESIGNER: {
      return {
        ...state,
        relatedCardsByDesigner: initialState.relatedCardsByDesigner,
      };
    }
    case ActionTypes.RESET_PAPER_ADD_ONS: {
      return {
        ...state,
        paperAddOns: initialState.paperAddOns,
      };
    }
    // isAdminView
    case ActionTypes.UPDATE_CATALOG_ADMIN_VIEW: {
      const { isAdminView } = action.payload;
      return {
        ...state,
        isAdminView,
      };
    }
    case ActionTypes.HYDRATE_MOTIFS: {
      return {
        ...state,
        motifs: action.payload.motif_views,
      };
    }
    case ActionTypes.UPDATE_SELECTED_MOTIFS: {
      const filters = {
        ...state.filters,
        selected_motifs: action.payload,
      };
      return {
        ...state,
        filters,
      };
    }
    case ActionTypes.UPDATE_SEARCH_FILTER_OFFSET:
      return {
        ...state,
        filters: { ...state.filters, offset: action.offset },
      };
    case ActionTypes.RECEIVE_PREVIOUS_SEARCH_RESULTS: {
      const { cardSuites } = action.payload;
      const { offset } = cardSuites;
      const mappedSuites = mapSearchIndexToSuite(cardSuites.suites, offset);
      const { byUUID, orderedUUIDs } = prependSortedSuites(state, mappedSuites);
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          loadedStartIndex: offset,
        },
      };
    }
    case ActionTypes.RECEIVE_ADDITIONAL_CARD_SUITES: {
      const { cardSuites } = action.payload;
      const { offset } = cardSuites;
      const count = cardSuites.suites.length;
      const mappedSuites = mapSearchIndexToSuite(cardSuites.suites, offset);
      const { byUUID, orderedUUIDs } = createSortedSuites(state, mappedSuites);
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          loadedEndIndex: offset + count - 1,
        },
      };
    }
    case ActionTypes.RECEIVE_INITIAL_CARD_SUITES: {
      const { cardSuites } = action.payload;
      const {
        offset,
        displayable_total: displayableTotal,
        promotion_code: promotionData,
      } = cardSuites;
      const mappedSuites = mapSearchIndexToSuite(cardSuites.suites, offset);
      const numNewSuites = cardSuites.suites.length;
      const { byUUID, orderedUUIDs } = createSortedSuites(state, mappedSuites);
      const loadedEndIndex = numNewSuites !== 0 ? offset + numNewSuites - 1 : offset;
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          count: orderedUUIDs.length,
          displayableTotal,
          loadedStartIndex: offset,
          loadedEndIndex,
        },
        promotionData,
      };
    }
    case ActionTypes.RESET_THEME_MATCHED_CARDS:
      return {
        ...state,
        suites: {
          ...state.suites,
          themeMatchedCards: {
            ...initialState.suites.themeMatchedCards,
          },
        },
      };
    case ActionTypes.SET_MULTISAMPLE_ENABLED: {
      const { enabled, count } = action.payload;

      return {
        ...state,
        multiSampleState: {
          ...state.multiSampleState,
          enabled,
          count: {
            ...count,
            limit: count.maximumOrdersAllowed - count.ordersPlaced,
          },
          selected: enabled ? state.multiSampleState.selected : {},
        },
      };
    }
    case ActionTypes.SET_MULTISAMPLE_SELECTED: {
      const { card, selected, initialColor } = action.payload;

      if (Object.keys(selected).length >= state.multiSampleState.count.limit) {
        return state;
      }

      const nextState = {
        ...state,
        multiSampleState: {
          ...state.multiSampleState,
          selected: {
            ...state.multiSampleState.selected,
          },
        },
      };

      if (selected && card.suiteUUID) {
        nextState.multiSampleState.selected[card.suiteUUID] = { ...card, initialColor };
      } else if (nextState.multiSampleState.selected[card.suiteUUID]) {
        delete nextState.multiSampleState.selected[card.suiteUUID];
      }

      return nextState;
    }
    case ActionTypes.CLEAR_MULTISAMPLE_SELECTED: {
      const nextState = {
        ...state,
        multiSampleState: {
          ...state.multiSampleState,
          selected: {},
        },
      };
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default cardCatalogReducer;
