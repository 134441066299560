import _get from 'lodash/get';
import { browserHistory } from 'react-router';
import { identifyUserSailthru } from 'actions/UserActions';
import { getCookie, deleteCookie } from '@zola-helpers/client/dist/es/util/storage';
import onboardTrackingConstants from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';
import {
  trackOnboardingCompleted,
  trackOnboardingStarted,
} from '@zola-helpers/client/dist/es/tracking/onboard/onboardEvents';
import { setGTMLoggedIn } from '@zola-helpers/client/dist/es/tracking/googleTagManager/googleTagManager';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import { trackUserCreated } from '@zola-helpers/client/dist/es/tracking/trackingHelper';

// Tracking Contracts
import { trackUserCreated as trackUserCreatedTrackingContracts } from '@zola/tracking-contracts/src/tracking';
import { BusinessCategoryComponentMap } from 'components/onboard/flowControllers/helpers/constants';

import { setCookie } from 'util/storage';
import { ACCOUNT_ERROR_MESSAGES } from './constants';
import {
  extractWeddingNames,
  parseWeddingDate,
  getUserTimeZone,
  getAccountCreationTimestamp,
} from './helpers';

export const getUserContext = () => {
  return ApiService.get('/website-nav/web-api/v1/user/get');
};

const { BUSINESS_UNIT } = onboardTrackingConstants;

export const buildAccountDetails = userContext => {
  if (!userContext) return {};
  // * Some users can be registered without going through the onboarding flow
  // * i.e. direct registry buying - creating account from checkout
  const {
    partner_first_name: partnerFirstName,
    partner_last_name: partnerLastName,
  } = userContext.user_role_account_weddings[0];
  const existingAccount = {
    account_id: userContext.user_role_account_weddings[0].account_id,
    user_id: userContext.object_id,
  };
  const weddingDetails = {
    fullName: `${userContext.first_name} ${userContext.last_name}`,
    partnerFullName: `${partnerFirstName} ${partnerLastName}`,
    hasNoPartner: !partnerFirstName,
  };
  const accountDetails = {
    email: userContext.email,
  };

  return {
    existingAccount,
    weddingDetails,
    accountDetails,
  };
};

function validateAccountFields({
  accountDetails,
  existingAccount,
  weddingNames,
  date,
  currentErrors = {},
}) {
  const parsedDate = parseWeddingDate(date);
  let result = { errors: { ...currentErrors } };

  // * Validate Account (email/password)
  if (!existingAccount.account_id) {
    const isEmailInvalid =
      !accountDetails.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(accountDetails.email);
    const isPasswordInvalid = !accountDetails.password || accountDetails.password.length < 8;

    // * Validate Wedding details (User and Partner Infos + Date)
    const isUserInvalid = weddingNames.errors && weddingNames.errors.includes('userDetails');
    const isPartnerInvalid = weddingNames.errors && weddingNames.errors.includes('partnerDetails');
    const isDateInvalid = (date && date.length > 0 && !parsedDate) || false;

    result = {
      errors: {
        ...result.errors,
        ...{
          account: {
            ...result.errors.account,
            email: {
              isInvalid: isEmailInvalid,
              errorMessage: ACCOUNT_ERROR_MESSAGES.invalidEmail,
            },
            password: {
              isInvalid: isPasswordInvalid,
              errorMessage: ACCOUNT_ERROR_MESSAGES.invalidPassword,
            },
          },
          weddingDetails: {
            user: {
              isInvalid: isUserInvalid,
              errorMessage: ACCOUNT_ERROR_MESSAGES.emptyWeddingDetails,
            },
            partner: {
              isInvalid: isPartnerInvalid,
              errorMessage: ACCOUNT_ERROR_MESSAGES.emptyPartnerWeddingDetails,
            },
            date: {
              isInvalid: isDateInvalid,
              errorMessage: ACCOUNT_ERROR_MESSAGES.invalidWeddingDate,
            },
          },
        },
      },
    };
  }

  return result;
}

export const createAccount = ({
  existingAccount = {},
  weddingDetails = {},
  accountDetails = {},
  businessUnitComponent = BUSINESS_UNIT.INVITATIONS,
  businessUnitSource = BUSINESS_UNIT.INVITATIONS,
  shouldTrackOnboarding,
  isGuest,
  currentErrors,
  captchaToken,
}) => {
  const weddingNames = extractWeddingNames(weddingDetails);
  const { date } = weddingDetails;
  const weddingDate = !weddingDetails.stillDeciding ? date : null;
  const businessCategory = BusinessCategoryComponentMap[businessUnitComponent];

  // * Validation step
  const fieldsValidation = validateAccountFields({
    accountDetails,
    existingAccount,
    weddingNames,
    date,
    currentErrors,
  });

  const hasError = Object.keys(fieldsValidation.errors).find(entry =>
    Object.keys(fieldsValidation.errors[entry]).some(
      field => field !== 'emailInUse' && fieldsValidation.errors[entry][field].isInvalid
    )
  );

  if (hasError) {
    return Promise.resolve(fieldsValidation);
  }

  // * Build data
  let accountData = {
    business_unit: businessUnitComponent,
    questionnaire_id: null,
    started_at: getAccountCreationTimestamp(),
    wedding_details: {
      ...weddingNames,
      event_date: weddingDate,
    },
    answers: [],
  };

  if (!existingAccount.account_id) {
    accountData = {
      ...accountData,
      captchaToken,
      user: {
        business_unit_source: businessUnitSource,
        email: accountDetails.email,
        password: accountDetails.password,
        timezone: getUserTimeZone(),
      },
    };
  } else {
    accountData = {
      ...accountData,
      existing_account: existingAccount,
    };
  }

  // * Send Started Onboarding Event
  if (shouldTrackOnboarding) {
    trackOnboardingStarted(BUSINESS_UNIT.INVITATIONS, businessUnitComponent, !isGuest);
  }

  const onboardEndpoint = isGuest
    ? '/web-api/v1/onboard/submit'
    : '/web-api/v1/onboard/submit/existing';

  // * Account creation request
  return ApiService.post(onboardEndpoint, accountData)
    .catch(() => {
      return {
        errors: {
          account: {
            emailInUse: {
              isInvalid: true,
              errorMessage: ACCOUNT_ERROR_MESSAGES.invalidWeddingDetails,
            },
          },
        },
      };
    })
    .then(result => {
      if (result.status === 'error' || result.error) {
        const errorMessage = _get(result, ['error', 'message']);
        return {
          errors: {
            account: {
              emailInUse: {
                isInvalid: true,
                errorMessage: errorMessage || ACCOUNT_ERROR_MESSAGES.invalidWeddingDetails,
              },
            },
          },
        };
      }

      if (!existingAccount.account_id) {
        // Send Completed Onboarding Event
        if (shouldTrackOnboarding) {
          trackOnboardingCompleted(BUSINESS_UNIT.INVITATIONS, businessUnitComponent, {
            isExistingUser: false,
            weddingDate,
          });
        }

        // Analytics Logged in event
        setGTMLoggedIn();

        if (isGuest) {
          // track user created
          let cookie = getCookie('pkey');
          if (cookie === '') {
            cookie = null;
          }
          trackUserCreated(accountDetails.email, cookie);

          // Tracking Contracts
          if (businessCategory) {
            const normalizedPkey = cookie || undefined;
            trackUserCreatedTrackingContracts({
              business_unit: 'PAPER',
              business_category: businessCategory,
              promotion_key: normalizedPkey,
            });
          }
        }

        // * Subscription to Marketing Email Campain Provider (Sailthru)
        // * isGuest is determining if previously a guest or brand new user
        identifyUserSailthru(accountDetails.email, isGuest ? accountData.user : undefined);
      }

      return {};
    });
};

// Post Auth Only
export const createInvitationsAccount = (opts = {}) => {
  return getUserContext().then(userContext => {
    const { shouldTrackOnboarding, businessUnitComponent, businessUnitSource } = opts;

    const { existingAccount, weddingDetails, accountDetails } = buildAccountDetails(userContext);

    return createAccount({
      isGuest: userContext.is_guest,
      existingAccount,
      weddingDetails,
      accountDetails,
      businessUnitComponent,
      businessUnitSource,
      shouldTrackOnboarding,
    });
  });
};

export const sendToOnboarding = ({
  activeSuiteUUID,
  activeVariationUUID,
  quantity,
  businessUnitComponent,
  activeSelectionColor = '',
  cardType,
  isDigital,
}) => {
  /*
   * If the user favorited cards before
   * This cookie is used to control copy
   * on the onboarding single page form
   */
  deleteCookie('invitesOnboardFormSource');

  setCookie(
    'newUserInvitesSku',
    `${activeSuiteUUID}|${activeVariationUUID}|${quantity}|${cardType}|${JSON.stringify(isDigital)}`
  );
  setCookie('isNewUserDSTDCustomize', 'true');
  setCookie('newUserPaperFavoriteLocation', window.location.pathname);
  setCookie('invitesBusinessComponent', businessUnitComponent, 1);
  setCookie('invitesOnboardFormData', `${activeSuiteUUID}|${activeSelectionColor}`);
  // go to single page onboarding form
  browserHistory.push(`/wedding/onboard/wedding-paper/${businessUnitComponent}/form`);
};
