import { SETTINGS_BY_CARD_TYPE } from 'cards/constants/CardTypes';

export type CardSettings = {
  type: string;
  urlSlug: string | null;
  label: string | null;
  hasFoil: boolean;
  hasLetterpress: boolean;
  hasMotifs: boolean;
  hasShapes: boolean;
  hasMagnet: boolean;
  hasMultipleOrientations: boolean;
  hasThirdPartyDesigns: boolean;
  hasCustomPhoto: boolean;
  hasNoCustomPhoto: boolean;
  isVowRenewal: boolean;
  isLead: boolean | null;
  showPdpFreeAddressingModule: boolean | null;
  freeAddressingText: string | null;
  freeAddressingImage: string | null;
  showPdpCtdBanner: boolean | null;
  showPdpCovidBanner: boolean | null;
  showReviewStepCtdBanner: boolean | null;
  hiddenPlpFilters?: null | null;
};

export const getCardSettings = (
  cardType?: keyof typeof SETTINGS_BY_CARD_TYPE, // @TODO
  isDigital?: boolean
): CardSettings => {
  if (isDigital) {
    return {
      type: 'DIGITAL_SAVE_THE_DATE',
      urlSlug: 'digital/save-the-date',
      label: 'Digital Save The Dates',
      hasFoil: false,
      hasLetterpress: false,
      hasMotifs: true,
      hasShapes: false,
      hasMultipleOrientations: false,
      hasThirdPartyDesigns: false,
      hasCustomPhoto: false,
      hasNoCustomPhoto: false,
      hasMagnet: false,
      isVowRenewal: false,
      isLead: true,
      showPdpFreeAddressingModule: false,
      freeAddressingText: null,
      freeAddressingImage: null,
      showPdpCtdBanner: true,
      showPdpCovidBanner: false,
      showReviewStepCtdBanner: true,
    };
  }
  if (cardType && SETTINGS_BY_CARD_TYPE[cardType]) {
    return SETTINGS_BY_CARD_TYPE[cardType];
  }
  return {} as CardSettings;
};
