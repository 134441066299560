import React from 'react';
import { useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';

// selectors
import { getSilhouette } from 'cards/selectors/samplesFlow';

// components
import { ptToPx, CARD_BLEED_PT } from 'cards/util';
import { hasBoundingBoxAdjustedTag } from 'cards/util/boundingBoxAdjusted';
import { DesignCustomizations } from 'cards/reducers/samplesFlow/types';
import ImageElement from '../../../SamplePreview/ImageElement';
import TextElement from '../../../SamplePreview/TextElement';

import styles from './styles.module.less';

interface PreviewProps {
  customizations: DesignCustomizations;
  page: any;
  width?: number;
}

const Preview = ({ customizations, page, width = 330 }: PreviewProps): JSX.Element | null => {
  const silhouette = useSelector(getSilhouette);

  if (!page) return null;

  const { elements } = page;
  const orderedElements = _sortBy(elements, element => element.layer);

  const { width: templateWidthPt, height: templateHeightPt, tags } = page;

  /*
   * Our templates dimensions values accounts for:
   * - Card dimensions
   * - Bleed value
   *
   * In the context of Sample where elements cannot be moved around
   * and no safety line is shown, we need to deduct the bleed value
   * and render an accurate card per final dimensions
   */
  const cardWidth = ptToPx(templateWidthPt - CARD_BLEED_PT * 2);
  const cardHeight = ptToPx(templateHeightPt - CARD_BLEED_PT * 2);
  const scale = width / Math.max(cardWidth, cardHeight);
  const isRounded = silhouette === 'rounded';

  const { uploadcareFile } = customizations;

  const customImageIndex = orderedElements.findIndex(
    element => element.content_type === 'IMAGE' && element.customizable
  );
  const backgroundLayerIndex = orderedElements.findIndex(element => element.layer === 1);

  const boundingBoxesAdjusted = hasBoundingBoxAdjustedTag(tags);

  const renderElement = (element: any, i: number): JSX.Element | null => {
    switch (element.content_type) {
      case 'IMAGE': {
        let imageElementProps = {
          key: i,
          element,
          isRounded,
          border: i === backgroundLayerIndex,
        };

        if (customImageIndex === i) {
          imageElementProps = {
            ...imageElementProps,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ photo: null; pixelCrop: null; uploadcareFi...
            photo: null,
            pixelCrop: null,
            uploadcareFile,
          };
        }

        return <ImageElement {...imageElementProps} />;
      }
      case 'TEXT': {
        return (
          <TextElement key={i} element={element} isBoundingBoxAdjusted={boundingBoxesAdjusted} />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div style={{ height: cardHeight * scale, width }}>
      <div
        className={styles.preview}
        style={{
          width: cardWidth,
          height: cardHeight,
          marginLeft: (width - cardWidth * scale) / 2,
          transform: `scale(${scale})`,
        }}
      >
        {orderedElements.map(renderElement)}
      </div>
    </div>
  );
};

export default Preview;
