export const calculateLineHeight = (
  isBoundingBoxAdjusted: boolean,
  body: string,
  fontSize: number,
  lineHeight: number
): number => {
  if (!isBoundingBoxAdjusted) {
    return lineHeight;
  }

  // Adjusting the leading (line height) when singled line text since IText spreads half of this on the top and the other half on the bottom of the line.
  // InDesign doesn't do this and it doesn't work well for single lines of text.
  if (body.indexOf('\n') === -1) {
    return lineHeight / 2.0 + fontSize / 2.0;
  }

  return lineHeight;
};
