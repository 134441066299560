const createEnvironmentFlags = (development = false, staging = false, production = false) => ({
  development,
  staging,
  production,
});

export const FLAGS = {
  confirmExitModalFlag: createEnvironmentFlags(true, true, false), // WEDDINGS-1785
  expeditedShippingModal: createEnvironmentFlags(false, false, false), // INV-1439
  datadog: createEnvironmentFlags(false, true, true), // Toggle datadog monitoring
  cardsSampleFlow: createEnvironmentFlags(true, true, false), // COMMERCE-1014
  envelopeGuestAddressingTextResets: createEnvironmentFlags(false, false, false), // COMMERCE-1153 follow up
  resetCustomizationButton: createEnvironmentFlags(false, false, false), // COMMERCE-1476
  newPaperShapesConfirmation: createEnvironmentFlags(true, true, false), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2081
  holidayShipping: createEnvironmentFlags(true, true, true),
  paperGuestAddressingConfirmationModal: createEnvironmentFlags(false, false, false), // Don't delete. Waiting for guest addressing experiment to be over before reenabling.
  paperVerticalAlignmentFix: createEnvironmentFlags(true, true, true),
  virtualEventDateFix: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-3286
  cardSizeIndicator: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2578
  vendorSearchInWeddingLocationStep: createEnvironmentFlags(false, false, false), // https://newamsterdamlabs.atlassian.net/browse/CE-2390
  multipleEventDates: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-3372
  navSkeleton: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-3370
  increaseMobilePadding: createEnvironmentFlags(true, true, true),
  paperTableNumber: createEnvironmentFlags(true, true, true),
  updateSegmentEventsPLV: createEnvironmentFlags(true, true, true),
  updateSegmentEventsPV: createEnvironmentFlags(true, true, true),
  updateSegmentEventsPC: createEnvironmentFlags(true, true, true),
  plantATree: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-3280
  paperAddOns: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-3296
  paperAddOnsUpsell: createEnvironmentFlags(true, true, true),
  mTFMMiniQuizEntryPoints: createEnvironmentFlags(true, true, true),
  paperStrikethroughPricing: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-3353
  ywPaperStateUpdates: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-3940
  navSsr: createEnvironmentFlags(true, true, true),
  plpNewBadge: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-127
};

type Environments = 'development' | 'staging' | 'production';
export const getEnvironment = (): Environments => {
  const zolaEnv: string | undefined =
    (typeof window !== 'undefined' && window.zola && window.zola.env) ||
    process.env.ZOLA_ENV ||
    process.env.NODE_ENV;
  switch (zolaEnv) {
    case 'test':
    case 'development':
    case 'dev':
      return 'development';
    case 'staging':
    case 'stage':
      return 'staging';
    case 'production':
    case 'prod':
      return 'production';
    default:
      return 'production';
  }
};

export type FeatureFlag = keyof typeof FLAGS;

const get = (flag: FeatureFlag): boolean => {
  if (FLAGS[flag]) {
    return Boolean(FLAGS[flag][getEnvironment()]);
  }
  return false;
};

export default {
  get,
  getEnvironment,
};
