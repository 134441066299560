import type { WCardVariationColorSummaryView } from '@zola/svc-web-api-ts-client';
import { SEO_COLORS } from 'cards/constants/Cards';

export type CardMetaData = {
  product_id?: string;
  sku?: string;
  sku_id?: string;
  category?: string;
  brand?: string;
  name?: string;
  variant?: string;
  price?: string; // toFixed returns a string
  quantity?: number;
  url?: string;
  image_url?: string;
  location?: string;
  section?: string;
  position?: number;
};

export type CardListing = {
  motif_color_recommendations: Record<string, string>;
  color_recommendations: Record<keyof typeof SEO_COLORS, string>;
  collaborator: string;
  colors: WCardVariationColorSummaryView[];
  initialColor?: string;
  foil: boolean;
  key: string;
  letterpress: boolean;
  medium: string;
  min_price_cents: number;
  name: string;
  searchIndex: number;
  singleSampleAvailable: boolean;
  singleSampleSku: string;
  suiteName: string;
  suiteUUID: string;
  thirdparty: boolean;
  type: CARD_TYPES;
  uuid: string;
  thirdParty?: boolean;
};

export type Color = {
  default_variation_uuid: string;
  label: string;
  nrf_color_code_label: string;
  plp_front_image_url: string;
  value: string;
};

export type Ad = {
  type: 'Ad';
  uuid: string;
};

export type Digital = {
  type: 'Digital';
  uuid: string;
};

export type Sample = {
  type: 'sample';
  uuid: string;
};

export type QRModule = {
  type: 'qrModule';
  uuid: string;
};

export type MultiSampleTile = {
  uuid: 'multiSampleTile';
  multisample: true;
  type: 'multiSampleTile';
};

export type CardListingOptions = CardListing | MultiSampleTile | Ad | Sample | Digital | QRModule;

export type Motif = {
  display_order: number;
  key: string;
  name: string;
  uuid: string;
};

export type Shape = {
  key: string;
  value: string;
  label: string;
  icon: string;
};

export type Collaborator = {
  key: string;
  slug: string;
  label: string;
};

export const PARAM_KEY_TO__FILTER: Record<string, string> = {
  has_foil: 'foil',
  has_custom_photo: 'customphoto',
  has_no_custom_photo: 'nocustomphoto',
  has_magnet: 'magnet',
  is_landscape: 'landscape',
  is_portrait: 'portrait',
  is_vow_renewal: 'elopementandvowrenewal',
  featured_artist: 'featuredArtist',
  is_letterpress: 'is_letterpress',
  single_sample_available: 'multisampleenabled',
};

export const MOTIF_NAMES: Record<string, string> = {
  elegant: 'Elegant',
  classic: 'Classic',
  floral: 'Floral',
  greenery: 'Greenery',
  rustic: 'Rustic',
  destination: 'Destination Themed',
  beach: 'Beach Themed',
  bohemian: 'Bohemian',
  simple: 'Simple',
  modern: 'Modern',
  vintage: 'Vintage',
  formal: 'Formal',
  funny: 'Funny',
  watercolor: 'Watercolor',
};

// TODO: Consolidate with src/cards/constants/CardTypes.js
export enum CARD_TYPES {
  invitation = 'INVITATION',
  invitationEnvelope = 'INVITATION_ENVELOPE',
  std = 'SAVE_THE_DATE',
  ctd = 'CHANGE_THE_DATE',
  thankyou = 'THANK_YOU',
  menu = 'MENU',
  program = 'PROGRAM',
  place = 'PLACE',
  tableNumber = 'TABLE_NUMBER',
  holiday = 'HOLIDAY',
  enclosure = 'ENCLOSURE',
  rsvp = 'RSVP',
  rsvpEnvelope = 'RSVP_ENVELOPE',
  dstd = 'DIGITAL_SAVE_THE_DATE',
  weddingShower = 'WEDDING_SHOWER_INVITATION',
  paperAddOns = 'PAPER_ADD_ONS',
  bellyBand = 'BELLY_BAND',
  vellumJacket = 'VELLUM_JACKET',
  waxSeal = 'WAX_SEAL',
}

// TODO: Consolidate with src/cards/constants/CardTypes.js
export enum PLP_MODULE_GROUP_TAGS {
  DETAILS = 'invites-plp-enclosure',
  ENCLOSURE = 'invites-plp-enclosure',
  HOLIDAY = 'invites-plp-holiday',
  INVITATION = 'invites-plp-invitation',
  MENU = 'invites-plp-menu',
  PLACE = 'invites-plp-placecard',
  TABLE_NUMBER = 'invites-plp-table-number',
  PROGRAM = 'invites-plp-program',
  SAVE_THE_DATE = 'invites-plp-savethedate',
  DIGITAL_SAVE_THE_DATE = 'invites-plp-digitalsavethedate',
  CHANGE_THE_DATE = 'invites-plp-changethedate',
  THANK_YOU = 'invites-plp-thankyou',
  WEDDING_SHOWER_INVITATION = 'invites-plp-wedding-shower',
  PAPER_ADD_ONS = 'invites-plp-paperaddons',
}

export const PLP_PROMO_MODULE_GROUP_TAGS_PRE_AUTH = {
  SAVE_THE_DATE: 'paper-plp-promos-savethedate-preauth',
  INVITATION: 'paper-plp-promos-invitation-preauth',
  WEDDING_SHOWER_INVITATION: 'paper-plp-promos-wedding-shower-preauth',
  PLACE_CARDS: 'paper-plp-promos-placecard-preauth',
  MENU: 'paper-plp-promos-menu-preauth',
  PROGRAM: 'paper-plp-promos-program-preauth',
  THANK_YOU: 'paper-plp-promos-thankyou-preauth',
  LANDING_PAGE: 'paper-plp-promos-universal-lp-preauth',
  ENCLOSURE: 'paper-plp-promos-enclosure-preauth',
  DETAILS: 'paper-plp-promos-enclosure-preauth',
  HOLIDAY: 'paper-plp-promos-holiday-preauth',
  TABLE_NUMBER: 'paper-plp-promos-table-number-preauth',
  CHANGE_THE_DATE: 'paper-plp-promos-changethedate-preauth',
  PAPER_ADD_ONS: 'paper-plp-promos-paperaddons-preauth',
};

export const PLP_PROMO_MODULE_GROUP_TAGS_POST_AUTH = {
  SAVE_THE_DATE: 'paper-plp-promos-savethedate-postauth',
  INVITATION: 'paper-plp-promos-invitation-postauth',
  WEDDING_SHOWER_INVITATION: 'paper-plp-promos-wedding-shower-postauth',
  PLACE_CARDS: 'paper-plp-promos-placecard-postauth',
  MENU: 'paper-plp-promos-menu-postauth',
  PROGRAM: 'paper-plp-promos-program-postauth',
  THANK_YOU: 'paper-plp-promos-thankyou-postauth',
  LANDING_PAGE: 'paper-plp-promos-universal-lp-postauth',
  ENCLOSURE: 'paper-plp-promos-enclosure-postauth',
  DETAILS: 'paper-plp-promos-enclosure-postauth',
  HOLIDAY: 'paper-plp-promos-holiday-postauth',
  TABLE_NUMBER: 'paper-plp-promos-table-number-postauth',
  CHANGE_THE_DATE: 'paper-plp-promos-changethedate-postauth',
  PAPER_ADD_ONS: 'paper-plp-promos-paperaddons-postauth',
};

export enum ACTIVE_CARD_EVENT_DETAIL {
  details = 'DETAILS',
}

export type ACTIVE_CARD_EVENT_TYPE = CARD_TYPES | ACTIVE_CARD_EVENT_DETAIL | 'MATCHING_SUITES';
