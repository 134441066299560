import _filter from 'lodash/filter';
import _isObject from 'lodash/isObject';
import _find from 'lodash/find';

import { getRecipientAddress } from '../../../components/common/form/RecipientAddressing';

export const isOnlyFoil = (customFoiledTextElementList, currentelement) => {
  if (customFoiledTextElementList.length > 1 || currentelement.printing_technique === 'DIGITAL') {
    return false;
  }
  if (customFoiledTextElementList.length === 1) {
    return currentelement.uuid === customFoiledTextElementList[0].uuid;
  }
  return true;
};

export const FOIL_KEY = {
  gold: {
    color: 'd0b554',
    backgroundImage:
      'linear-gradient(to right, #d0b554 4%, #b6951a 15%, #e4bb26 31%, #cea40d 77%, #ffd427)',
  },
  silver: {
    color: 'c0bebe',
    backgroundImage: 'linear-gradient(to right, #c4c4c4 4%, #8e8e8e 37%, #eeeeee 74%, #cacaca 99%)',
  },
  copper: {
    color: 'dda99a',
    backgroundImage:
      'linear-gradient(to right, #dda194 4%, #f1d6c4 37%, #efcfbe 70%, #dda99a 100%)',
  },
};

export const getTextColor = (color, printingTechnique, foilColor) => {
  if (printingTechnique === 'CUSTOM_FOIL' && foilColor && foilColor !== 'none') {
    // update with proper foil color
    return FOIL_KEY[foilColor].color;
  }
  return color;
};

export const getCustomFoiledTextElementList = elements => {
  return (
    _filter(elements, {
      content_type: 'TEXT',
      customizable: true,
      printing_technique: 'CUSTOM_FOIL',
    }) || []
  );
};

function isIeEleven() {
  const ua = window.navigator.userAgent;
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) === 11;
  }
  return false;
}

export const getFoilCssProperties = foilColor => {
  if (isIeEleven()) {
    return {};
  }

  return {
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    backgroundImage: FOIL_KEY[foilColor].backgroundImage,
  };
};

/*
Default data used for the recipient address preview in card customization flow
*/
export const defaultRecipientAddressData = {
  RECIPIENT_ADDRESS_LINE_1: {
    body: "Your Guest's Name",
    image_id: null,
    key: 'mr-david-gonzalez-and-guest',
  },
  RECIPIENT_ADDRESS_LINE_2: {
    body: '1234 Lovestruck Avenue',
    image_id: null,
    key: '231-west-48th-street-6a',
  },
  RECIPIENT_ADDRESS_LINE_3: {
    body: 'City, State, 12345',
    image_id: null,
    key: 'new-york-new-york',
  },
};

export const getRecipientAddressData = (
  customizationValues,
  selectedGuestId,
  guestGroups,
  isRecipientAddressingEnabled,
  fontSizeOverrides
) => {
  // Default placeholder content
  let customizationValuesWithRecipientAddressing = {
    ...customizationValues,
    ...fontSizeOverrides,
    ...defaultRecipientAddressData,
  };

  // Replace with first guest once addresses are added/selected
  if (guestGroups[selectedGuestId] && isRecipientAddressingEnabled) {
    const guestToDisplay = guestGroups[selectedGuestId];

    const { id } = guestToDisplay;
    const adjustedFontSizes = (fontSizeOverrides && fontSizeOverrides[id]) || null;

    // Get the first selected guest, no matter what order the selectedGuestIds values are in
    const recipientAddress = getRecipientAddress(guestToDisplay);
    customizationValuesWithRecipientAddressing = {
      ...customizationValues,
      fontSizeOverrides: adjustedFontSizes,
      RECIPIENT_ADDRESS_LINE_1: recipientAddress.line1,
      RECIPIENT_ADDRESS_LINE_2: recipientAddress.line2,
      RECIPIENT_ADDRESS_LINE_3: recipientAddress.line3,
      RECIPIENT_ADDRESS_LINE_4: recipientAddress.line4,
    };
  }

  return customizationValuesWithRecipientAddressing;
};

export const preventDefaultEvent = event => {
  if (_isObject(event) && event.preventDefault) {
    event.preventDefault();
  }
};

export const changeFontSize = (event, value, element, handleChange) => {
  preventDefaultEvent(event);
  const delta = Math.max(0, element.line_height - element.font_size);

  // increase/decrease line_height along with changes to font_size
  handleChange([
    { elementUUID: element.uuid, fieldName: 'font_size', value },
    {
      elementUUID: element.uuid,
      fieldName: 'line_height',
      value: value + delta,
    },
  ]);
};

export const changeFontColor = (event, value, element, handleChange) => {
  preventDefaultEvent(event);
  handleChange({
    elementUUID: element.uuid,
    fieldName: 'font_color',
    value,
  });
};

export const changeAlignment = (event, value, element, handleChange) => {
  preventDefaultEvent(event);
  handleChange({
    elementUUID: element.uuid,
    fieldName: 'horizontal_alignment',
    value,
  });
};

export const changeFontStyle = (event, value, fonts, handleChange, element) => {
  const { raw: rawFonts } = fonts;
  preventDefaultEvent(event);
  const selectedFont = _find(rawFonts, { id: value.id });
  if (selectedFont) {
    handleChange([
      { elementUUID: element.uuid, fieldName: 'font', value: selectedFont },
      { elementUUID: element.uuid, fieldName: 'font_id', value: selectedFont.id },
    ]);
  }
};

export const changeFontFamily = (
  event,
  value,
  fonts,
  handleChange,
  element,
  resetCharacterSpacing = false
) => {
  const { raw: rawFonts } = fonts;
  let selectedFontId;
  preventDefaultEvent(event);

  if (!value) return;

  const hasVariations = value.variations && value.variations.length > 1;
  if (hasVariations) {
    const fontVariation = _find(value.variations, { displayName: 'Regular' });
    selectedFontId = fontVariation ? fontVariation.id : value.id;
  } else {
    selectedFontId = value.id;
  }
  const selectedFont = _find(rawFonts, { id: selectedFontId });
  if (selectedFont) {
    if (resetCharacterSpacing) {
      handleChange({ elementUUID: element.uuid, fieldName: 'character_spacing', value: 0 });
    }

    // Both requests are needed here, one for local change via form (font)
    // and one for the api call (font_id)
    handleChange([
      { elementUUID: element.uuid, fieldName: 'font', value: selectedFont },
      { elementUUID: element.uuid, fieldName: 'font_id', value: selectedFont.id },
    ]);
  }
};

export const changeLineHeight = (event, value, element, handleChange) => {
  preventDefaultEvent(event);
  if (value >= element.font_size) {
    handleChange({
      elementUUID: element.uuid,
      fieldName: 'line_height',
      value,
    });
  }
};

export const changeCharacterSpacing = (event, value, element, handleChange) => {
  preventDefaultEvent(event);
  handleChange({
    elementUUID: element.uuid,
    fieldName: 'character_spacing',
    value,
  });
};

export const changeTextCasing = (event, value, element, fieldName, handleChange) => {
  preventDefaultEvent(event);
  handleChange({
    elementUUID: element.uuid,
    fieldName,
    value,
  });
};

export const changeFoil = (
  event,
  value,
  element,
  handleChange,
  addToFoilHistory,
  foiledElements,
  handleUpdateFoil,
  customizationCardType
) => {
  preventDefaultEvent(event);
  if (value === 'none') {
    const isLastFoil = isOnlyFoil(foiledElements, element);
    handleChange([
      { elementUUID: element.uuid, fieldName: 'printing_technique', value: 'DIGITAL' },
    ]);
    // if last foil need to change the variation and change the foil history
    if (isLastFoil) {
      handleUpdateFoil('none');
    }
  } else if (value && value !== 'none') {
    handleChange([
      { elementUUID: element.uuid, fieldName: 'printing_technique', value: 'CUSTOM_FOIL' },
    ]);
    handleUpdateFoil(value);
    addToFoilHistory(value, customizationCardType);
  }
};

export const closeOutside = (
  e,
  editWrapper,
  previewWrapper,
  cardItem,
  editItem,
  setActiveField
) => {
  if (editWrapper.current && previewWrapper.current) {
    if (!editWrapper.current.contains(e.target) && !previewWrapper.current.contains(e.target)) {
      // if target is not a child of outermost div, the event originated
      // outside of Preview, set activeField to null
      setActiveField(null);
    } else if (
      cardItem &&
      !e.target.isSameNode(cardItem.current) &&
      editItem &&
      e.target.isSameNode(editItem.current)
    ) {
      // if the event originated inside outermost div but not from on of the
      // text element components (meaning it came from this.cardRef) then set activeField
      // to null
      setActiveField(null);
    }
  }
};
