import _flatten from 'lodash/flatten';
import _keys from 'lodash/keys';
import _reduce from 'lodash/reduce';
import _values from 'lodash/values';
import moment from 'moment';
import * as ActionTypes from '../../actions/types/EventActionTypes';
import * as PlacesActionTypes from '../../actions/types/PlacesActionTypes';

const initialState = {
  busy: false,
  byId: {},
  orderedIds: [],
  byDate: {},
  orderedDates: [],
  uuidToId: {},
  selectedId: null,
  address: {},
  rsvpPageHidden: false,
  rsvpPageId: null,
  currentZoomEvent: {},
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_EVENTS: {
      return { ...state, busy: true };
    }
    case ActionTypes.REQUEST_EVENTS_RSVP_PAGE: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_EVENTS: {
      const { events } = action.payload;
      const { orderedIds, uuidToId, ...byId } = events.reduce(
        (result, event) => {
          result[event.id] = event; // eslint-disable-line no-param-reassign
          result.uuidToId[event.uuid] = event.id; // eslint-disable-line no-param-reassign
          result.orderedIds.push(event.id);
          return result;
        },
        { orderedIds: [], uuidToId: {} }
      );

      return { ...state, busy: false, byId, orderedIds, uuidToId };
    }
    case ActionTypes.RECEIVE_EVENTS_BY_DATE: {
      const { groupedEventsByDate } = action.payload;

      const { orderedDates, ...byDate } = _keys(groupedEventsByDate)
        .sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
        .reduce(
          (result, date) => {
            result.orderedDates.push(date);
            result[date] = groupedEventsByDate[date]; // eslint-disable-line no-param-reassign
            return result;
          },
          { orderedDates: [] }
        );

      const allEvents = _flatten(_values(groupedEventsByDate));
      const { orderedIds, uuidToId, ...byId } = _reduce(
        allEvents,
        (result, event) => {
          result.orderedIds.push(event.id);
          result.uuidToId[event.uuid] = event.id; // eslint-disable-line no-param-reassign
          result[event.id] = event; // eslint-disable-line no-param-reassign
          return result;
        },
        { orderedIds: [], uuidToId: {} }
      );

      return { ...state, busy: false, byDate, byId, orderedIds, orderedDates, uuidToId };
    }
    case ActionTypes.RECEIVE_EVENTS_RSVP_PAGE: {
      const { events = [], hidden, id } = action.payload;

      const { orderedIds, uuidToId, ...byId } = events
        .sort((a, b) => moment(a.start_at).valueOf() - moment(b.start_at).valueOf())
        .reduce(
          (result, event) => {
            result[event.id] = event; // eslint-disable-line no-param-reassign
            result.uuidToId[event.uuid] = event.id; // eslint-disable-line no-param-reassign
            result.orderedIds.push(event.id);
            return result;
          },
          { orderedIds: [], uuidToId: {} }
        );

      return {
        ...state,
        busy: false,
        byId,
        orderedIds,
        uuidToId,
        rsvpPageHidden: hidden,
        rsvpPageId: id,
      };
    }
    case ActionTypes.SELECT_EVENT: {
      const { selectedId } = action.payload;
      return { ...state, selectedId };
    }
    case ActionTypes.INITIALIZE_SELECTED_EVENT: {
      const { orderedIds } = state;
      const selectedId = orderedIds.length > 0 ? orderedIds[0] : null;
      return { ...state, selectedId };
    }
    case PlacesActionTypes.REQUEST_ADDRESS: {
      const address = { busy: true };
      return { ...state, address };
    }
    case PlacesActionTypes.RECEIVE_ADDRESS: {
      const result = action.payload;
      const address = { ...result };
      if (action.overrideName) {
        address[action.overrideName] = result.name;
        delete address.name;
      }
      if (!address.url) {
        address.url = address.google_url;
      }
      address.google_map_url = address.google_url;
      address.busy = false;
      address.reference_vendor_uuid = null;
      return Object.assign({}, state, { address });
    }
    case PlacesActionTypes.RECEIVE_VENDOR_ADDRESS: {
      const { address, name, uuid } = action.payload;
      const mappedAddress = {
        address_1: address.address1,
        address_2: address.address2,
        city: address.city,
        country_code: address.countryCode || 'US', // currently all marketplace vendors are in the US
        google_map_url: address.googleMapsUrl,
        google_place_id: address.googleMapsPlaceId,
        latitude: address.latitude,
        longitude: address.longitude,
        postal_code: address.postalCode,
        reference_vendor_uuid: uuid,
        state_province: address.stateProvince,
        venue_name: name,
      };
      return Object.assign({}, state, { address: mappedAddress });
    }
    case PlacesActionTypes.RESET_ADDRESS: {
      return { ...state, address: {} };
    }
    case ActionTypes.SUGGESTED_ATTIRE_OPTIONS_REQUESTED: {
      return { ...state };
    }
    case ActionTypes.SUGGESTED_ATTIRE_OPTIONS_RECEIVED: {
      const suggestedAttireOptions = action.payload;
      return { ...state, suggestedAttireOptions };
    }
    case ActionTypes.RECEIVE_ZOOM_EVENT:
      return { ...state, currentZoomEvent: action.payload };
    case ActionTypes.RESET_ZOOM_EVENT:
      return { ...state, currentZoomEvent: {} };
    default:
      return state;
  }
};

export default eventsReducer;
