// TODO: remove 'lodash/get'
import _get from 'lodash/get';
import type { WCmsPageView } from '@zola/svc-web-api-ts-client';
import type { RootState } from 'reducers/index';
import type { PagesReducerState } from 'reducers/website/pages';

export const getPages = (state: RootState): PagesReducerState => state.website.pages;

export const getPagesById = (state: RootState): PagesReducerState['byId'] =>
  _get(state, 'website.pages.byId', {});

export const getPagesTypeToId = (state: RootState): PagesReducerState['typeToId'] =>
  _get(state, 'website.pages.typeToId', {});

export const getPagesOrderedIds = (state: RootState): PagesReducerState['orderedIds'] =>
  _get(state, 'website.pages.orderedIds', []);

export const getPagesByOrderedId = (state: RootState): WCmsPageView[] =>
  getPagesOrderedIds(state).map((id: number) => getPagesById(state)[id]);

export const getPageVisibility = (state: RootState, id: number): boolean =>
  _get(state, `website.pages.byId[${id}].hidden`);

export const getPageHome = (state: RootState): WCmsPageView =>
  getPagesById(state)[getPagesTypeToId(state).HOME] || {};

export const getPagePhoto = (state: RootState): WCmsPageView =>
  getPagesById(state)[getPagesTypeToId(state).PHOTO] || {};

export const getPageRegistry = (state: RootState): WCmsPageView =>
  getPagesById(state)[getPagesTypeToId(state).REGISTRY] || {};

export const getPageEvent = (state: RootState): WCmsPageView =>
  getPagesById(state)[getPagesTypeToId(state).EVENT] || {};

export const getPageRSVP = (state: RootState): WCmsPageView =>
  getPagesById(state)[getPagesTypeToId(state).RSVP] || {};

const getActivePageId = (state: RootState): number => _get(state, 'website.pages.activePage.id');

export const getActivePage = (state: RootState): WCmsPageView =>
  getPagesById(state)[getActivePageId(state)];

// export const getProfileImg = (state: RootState): WCmsPageView => _get(state, 'website.pages.profileImage');

export default {
  getPages,
  getPagesById,
  getPagesByOrderedId,
  getPagesTypeToId,
  getPageVisibility,
  getActivePage,
  // getProfileImg,
};
