import GuestAddressingIcon from 'assets/images/invitations/guest-addressing.svg';
import {
  ALL_PAPER_SHAPES,
  BASE_PAPER_SHAPES,
  ALL_PAPER_TYPES,
  BASE_PAPER_TYPES,
  ALL_FOIL_COLORS,
  ALL_STAMPED_FOIL_COLORS,
} from 'cards/constants/CardLevelOptions';
import { TertiaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import { getImageUrl } from 'paper/utils/helpers';
import FeatureFlags from 'util/featureFlags';
import { BASE_URL } from './Cards';

const CURRENT_YEAR = new Date().getFullYear();

export const CARD_TYPES = {
  invitation: 'INVITATION',
  invitationEnvelope: 'INVITATION_ENVELOPE',
  std: 'SAVE_THE_DATE',
  dstd: 'DIGITAL_SAVE_THE_DATE',
  ctd: 'CHANGE_THE_DATE',
  thankyou: 'THANK_YOU',
  menu: 'MENU',
  program: 'PROGRAM',
  place: 'PLACE',
  holiday: 'HOLIDAY',
  details: 'DETAILS',
  enclosure: 'ENCLOSURE',
  rsvp: 'RSVP',
  rsvpEnvelope: 'RSVP_ENVELOPE',
  envelope: 'ENVELOPE',
  matchingSuites: 'MATCHING_SUITES',
  weddingShower: 'WEDDING_SHOWER_INVITATION',
  tableNumber: 'TABLE_NUMBER',
  paperAddOns: 'PAPER_ADD_ONS',
  bellyBand: 'BELLY_BAND',
  vellumJacket: 'VELLUM_JACKET',
  waxSeal: 'WAX_SEAL',
};

// Paper add ons lead card type array used for api requests
export const PAPER_ADD_ONS_LEAD_CARD_TYPES = [
  CARD_TYPES.bellyBand,
  CARD_TYPES.vellumJacket,
  CARD_TYPES.waxSeal,
];

// Paper add ons lead card types plus PAPER_ADD_ONS (FE exculsive type) used for FE purposes only
export const ALL_PAPER_ADD_ONS_CARD_TYPES = [
  ...PAPER_ADD_ONS_LEAD_CARD_TYPES,
  CARD_TYPES.paperAddOns,
];

// Card types whose PDP and confirmation pages should include the paper add-ons upsell carousel
export const CARD_TYPES_WITH_ADD_ONS = [
  ...PAPER_ADD_ONS_LEAD_CARD_TYPES,
  CARD_TYPES.std,
  CARD_TYPES.weddingShower,
  CARD_TYPES.invitation,
];

export const ADD_ONS_COMPATIBILITY_CAPTION = {
  [CARD_TYPES.bellyBand]: 'For Classic & Petite sizes',
  [CARD_TYPES.vellumJacket]: 'For Classic size',
};

export const ADD_ONS_COMPATIBILITY_CAPTION_MWEB = {
  [CARD_TYPES.bellyBand]: 'For Classic & Petite',
  [CARD_TYPES.vellumJacket]: 'For Classic',
};

export const CARD_TYPES_WITH_ADDITIONAL_SHAPES = [
  CARD_TYPES.invitation,
  CARD_TYPES.std,
  CARD_TYPES.menu,
  CARD_TYPES.program,
  CARD_TYPES.holiday,
  CARD_TYPES.weddingShower,
  CARD_TYPES.tableNumber,
];
export const TYPES_WITHOUT_SILHOUETTE_OPTIONS = [
  CARD_TYPES.ctd,
  CARD_TYPES.place,
  CARD_TYPES.paperAddOns,
];

export const CARD_PLP_LINKS = {
  [CARD_TYPES.std]: {
    text: 'Save The Dates',
    path: `${BASE_URL}/save-the-date/shop`,
  },
  [CARD_TYPES.ctd]: {
    text: 'Change The Dates',
    path: `${BASE_URL}/change-the-date/shop`,
  },
  [CARD_TYPES.invitation]: {
    text: 'Invitations',
    path: `${BASE_URL}/invitations/shop`,
  },
  [CARD_TYPES.thankyou]: {
    text: 'Thank You Cards',
    path: `${BASE_URL}/thank-you-cards/shop`,
  },
  [CARD_TYPES.menu]: {
    text: 'Menus',
    path: `${BASE_URL}/menus/shop`,
  },
  [CARD_TYPES.program]: {
    text: 'Programs',
    path: `${BASE_URL}/programs/shop`,
  },
  [CARD_TYPES.place]: {
    text: 'Place Cards',
    path: `${BASE_URL}/place-cards/shop`,
  },
  DETAILS: {
    text: 'Enclosures Cards',
    path: `${BASE_URL}/enclosures/shop`,
  },
  [CARD_TYPES.holiday]: {
    text: 'Holiday Cards',
    path: `${BASE_URL}/holiday-cards/shop`,
  },
  [CARD_TYPES.weddingShower]: {
    text: 'Wedding Shower Invitation Cards',
    path: `${BASE_URL}/bridal-shower/shop`,
  },
  [CARD_TYPES.tableNumber]: {
    text: 'Wedding Table Number Cards',
    path: `${BASE_URL}/table-numbers/shop`,
  },
  // Note: the PAPER_ADD_ONS card type exists exclusively on the FE. Since the paper add-ons PLP hosts 3 separate card types,
  // this card type was created to act as a parent or umbrella card type in order to facilitate navigation
  [CARD_TYPES.paperAddOns]: {
    text: 'Paper Add-ons',
    path: `${BASE_URL}/paper-add-ons/shop`,
  },
};

export const CARD_TYPES_WITH_SAMPLES = [CARD_TYPES.std, CARD_TYPES.invitation];
export const CARD_TYPES_WITH_FREE_ENVELOPES = [
  CARD_TYPES.invitation,
  CARD_TYPES.std,
  CARD_TYPES.ctd,
  CARD_TYPES.thankyou,
  CARD_TYPES.holiday,
  CARD_TYPES.rsvp,
  CARD_TYPES.weddingShower,
];

// QR CODES
export const CARD_TYPES_WITH_QR_CODE_ON_BACK = [
  CARD_TYPES.std,
  CARD_TYPES.invitation,
  CARD_TYPES.enclosure,
  CARD_TYPES.details,
  CARD_TYPES.weddingShower,
];
export const CARD_TYPES_WITH_QR_CODE_ON_FRONT = [
  CARD_TYPES.rsvp,
  CARD_TYPES.enclosure,
  CARD_TYPES.details,
];
export const CARD_TYPES_WITH_QR_CODES = [
  ...CARD_TYPES_WITH_QR_CODE_ON_BACK,
  ...CARD_TYPES_WITH_QR_CODE_ON_FRONT,
];

export const CARD_TYPES_WITH_PHOTO_BADGE = [
  CARD_TYPES.std,
  CARD_TYPES.invitation,
  CARD_TYPES.weddingShower,
];

export const AVAILABLE_PAPER_OPTIONS_BY_CARD_TYPE = {
  ADMIN: {
    THANK_YOU: {
      size: ['folded-petite', 'petite', 'postcard'],
      'foil-color': ALL_FOIL_COLORS,
      'paper-type': ALL_PAPER_TYPES,
    },
    HOLIDAY: {
      size: ['folded-petite', '5x7', 'petite', 'postcard'],
      'foil-color': ALL_FOIL_COLORS,
      'paper-type': ALL_PAPER_TYPES,
      silhouette: BASE_PAPER_SHAPES,
    },
    MENU: {
      size: ['menu-4x8', 'menu-5x7'],
      'foil-color': ALL_FOIL_COLORS,
      'paper-type': ALL_PAPER_TYPES,
      silhouette: BASE_PAPER_SHAPES,
    },
    INVITATION: {
      size: ['petite', '5x7'],
      'foil-color': ALL_FOIL_COLORS,
      'paper-type': ALL_PAPER_TYPES,
      silhouette: ALL_PAPER_SHAPES,
    },
  },
  INVITATION: {
    size: ['petite', '5x7'],
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  SAVE_THE_DATE: {
    size: ['petite', '5x7', 'postcard'],
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  CHANGE_THE_DATE: {
    size: ['petite', 'postcard'],
    'foil-color': null,
    'paper-type': BASE_PAPER_TYPES,
    silhouette: null,
  },
  THANK_YOU: {
    size: ['folded-petite', 'petite', 'postcard'],
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
  },
  MENU: {
    size: ['menu-4x8', 'menu-5x7'],
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  PROGRAM: {
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  PLACE: {
    'paper-type': ['smooth', 'eggshell', 'pearlized', 'linen', 'felt'],
  },
  HOLIDAY: {
    size: ['folded-petite', '5x7', 'petite', 'postcard'],
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  ENCLOSURE: {
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: BASE_PAPER_SHAPES,
  },
  [CARD_TYPES.weddingShower]: {
    size: [],
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  [CARD_TYPES.tableNumber]: {
    size: [],
    'foil-color': ALL_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  [CARD_TYPES.vellumJacket]: {
    size: ['5x7'],
  },
};

export const AVAILABLE_PAPER_OPTIONS_BY_STAMPED_CARD_TYPE = {
  ADMIN: {
    THANK_YOU: {
      size: ['folded-petite', 'petite', 'postcard'],
      'foil-color': ALL_STAMPED_FOIL_COLORS,
      'paper-type': ALL_PAPER_TYPES,
    },
    HOLIDAY: {
      size: ['folded-petite', '5x7', 'petite', 'postcard'],
      'foil-color': ALL_STAMPED_FOIL_COLORS,
      'paper-type': ALL_PAPER_TYPES,
      silhouette: BASE_PAPER_SHAPES,
    },
    MENU: {
      size: ['menu-4x8', 'menu-5x7'],
      'foil-color': ALL_STAMPED_FOIL_COLORS,
      'paper-type': ALL_PAPER_TYPES,
      silhouette: BASE_PAPER_SHAPES,
    },
  },
  INVITATION: {
    'foil-color': ALL_STAMPED_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  SAVE_THE_DATE: {
    size: ['petite', '5x7', 'postcard'],
    'foil-color': ALL_STAMPED_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: ALL_PAPER_SHAPES,
  },
  CHANGE_THE_DATE: {
    size: ['petite', 'postcard'],
    'foil-color': null,
    'paper-type': BASE_PAPER_TYPES,
    silhouette: null,
  },
  THANK_YOU: {
    size: ['folded-petite', 'petite', 'postcard'],
    'foil-color': ALL_STAMPED_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
  },
  MENU: {
    size: ['menu-4x8', 'menu-5x7'],
    'foil-color': ALL_STAMPED_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: BASE_PAPER_SHAPES,
  },
  PROGRAM: {
    'foil-color': ALL_STAMPED_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: BASE_PAPER_SHAPES,
  },
  PLACE: {
    'paper-type': ['smooth', 'eggshell', 'pearlized', 'linen', 'felt'],
  },
  HOLIDAY: {
    size: ['folded-petite', '5x7', 'petite', 'postcard'],
    'foil-color': ALL_STAMPED_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: BASE_PAPER_SHAPES,
  },
  ENCLOSURE: {
    'foil-color': ALL_STAMPED_FOIL_COLORS,
    'paper-type': ALL_PAPER_TYPES,
    silhouette: BASE_PAPER_SHAPES,
  },
};

export const AVAILABLE_MAGNET_OPTIONS_BY_CARD_TYPE = {
  SAVE_THE_DATE: {
    size: ['magnet', '5x7', 'postcard'],
    finish: ['glossy', 'matte'],
    silhouette: ['square', 'rounded'],
  },
  HOLIDAY: {
    size: ['magnet', '5x7', 'postcard'],
    finish: ['glossy', 'matte'],
    silhouette: ['square', 'rounded'],
  },
};

// ! Deprecated ?
export const AVAILABLE_TYPE_OPTIONS_BY_CARD_TYPE = {
  INVITATION: ['paper'],
  SAVE_THE_DATE: ['paper', 'magnet'],
  CHANGE_THE_DATE: ['paper'],
  THANK_YOU: ['paper'],
  MENU: ['paper'],
  PROGRAM: ['paper'],
  HOLIDAY: ['paper', 'magnet'],
  ENCLOSURE: ['paper'],
};

// for Customization:
export const ORDERED_CUSTOMIZATION_TYPES = [
  'INVITATION',
  [CARD_TYPES.weddingShower],
  'RSVP',
  'SAVE_THE_DATE',
  'CHANGE_THE_DATE',
  'DETAILS',
  'ENCLOSURE',
  'MENU',
  'PROGRAM',
  'PLACE',
  [CARD_TYPES.tableNumber],
  'THANK_YOU',
  'ENVELOPE',
  'INVITATION_ENVELOPE',
  'RSVP_ENVELOPE',
  'HOLIDAY',
];

export const PRIMARY_CUSTOMIZATION_TYPES = [
  'INVITATION',
  'SAVE_THE_DATE',
  'DIGITAL_SAVE_THE_DATE',
  'CHANGE_THE_DATE',
  'ENCLOSURE',
  'MENU',
  'PROGRAM',
  'PLACE',
  'THANK_YOU',
  'HOLIDAY',
  [CARD_TYPES.weddingShower],
  [CARD_TYPES.tableNumber],
];

// ! Deprecated?
export const ENABLED_PROOF_ORDER_CARD_TYPES = [
  CARD_TYPES.invitation,
  CARD_TYPES.std,
  CARD_TYPES.ctd,
  CARD_TYPES.menu,
  CARD_TYPES.program,
  CARD_TYPES.enclosure,
  CARD_TYPES.thankyou,
  CARD_TYPES.holiday,
  CARD_TYPES.place,
];

export const PROJECT_COPY_MAP = {
  [CARD_TYPES.invitation]: {
    title: 'Wedding Invitation',
  },
  [CARD_TYPES.std]: {
    title: 'Save The Date',
  },
  [CARD_TYPES.ctd]: {
    title: 'Change The Date',
  },
  [CARD_TYPES.thankyou]: {
    title: 'Thank You Card',
  },
  [CARD_TYPES.menu]: {
    title: 'Menu',
  },
  [CARD_TYPES.enclosure]: {
    title: 'Enclosure Card',
  },
  [CARD_TYPES.program]: {
    title: 'Program Card',
  },
  [CARD_TYPES.place]: {
    title: 'Place Cards',
  },
  [CARD_TYPES.holiday]: {
    title: 'Holiday Card',
  },
  [CARD_TYPES.weddingShower]: {
    title: 'Wedding Shower Invitation',
  },
  [CARD_TYPES.tableNumber]: {
    title: 'Table Numbers',
  },
};

export const PLP_MODULE_GROUP_TAGS = {
  DETAILS: 'invites-plp-enclosure',
  ENCLOSURE: 'invites-plp-enclosure',
  HOLIDAY: 'invites-plp-holiday',
  INVITATION: 'invites-plp-invitation',
  MENU: 'invites-plp-menu',
  PLACE: 'invites-plp-placecard',
  PROGRAM: 'invites-plp-program',
  SAVE_THE_DATE: 'invites-plp-savethedate',
  DIGITAL_SAVE_THE_DATE: 'invites-plp-digitalsavethedate',
  CHANGE_THE_DATE: 'invites-plp-changethedate',
  THANK_YOU: 'invites-plp-thankyou',
  MATCHING_SUITES: 'invites-plp-matching-suites',
  [CARD_TYPES.weddingShower]: 'invites-plp-wedding-shower',
  [CARD_TYPES.tableNumber]: 'invites-plp-table-number',
  [CARD_TYPES.paperAddOns]: 'invites-plp-paperaddons',
};

export const ROUTES_PLP_LEAD_CARD = {
  invitations: {
    leadCardType: 'INVITATION',
    mobileTertiaryNavId: TertiaryNavIdentifier.INVITATIONS,
    tertiaryNavId: TertiaryNavIdentifier.INVITATIONS,
  },
  'save-the-date': {
    leadCardType: 'SAVE_THE_DATE',
    mobileTertiaryNavId: TertiaryNavIdentifier.SAVE_THE_DATE,
    tertiaryNavId: TertiaryNavIdentifier.SAVE_THE_DATE,
  },
  // TODO DSTD
  // 'digital-save-the-date': {
  //   leadCardType: 'DIGITAL_SAVE_THE_DATE',
  //   mobileTertiaryNavId: TertiaryNavIdentifier.DIGITAL_SAVE_THE_DATE,
  //   tertiaryNavId: TertiaryNavIdentifier.DIGITAL_SAVE_THE_DATE,
  // },
  'change-the-date': {
    leadCardType: 'CHANGE_THE_DATE',
    mobileTertiaryNavId: TertiaryNavIdentifier.CHANGE_THE_DATE,
    tertiaryNavId: TertiaryNavIdentifier.SAVE_THE_DATE,
  },
  'thank-you-cards': {
    leadCardType: 'THANK_YOU',
    mobileTertiaryNavId: TertiaryNavIdentifier.THANK_YOUS,
    tertiaryNavId: TertiaryNavIdentifier.THANK_YOUS,
  },
  menus: {
    leadCardType: 'MENU',
    mobileTertiaryNavId: TertiaryNavIdentifier.MENU,
    tertiaryNavId: TertiaryNavIdentifier.DAY_OF,
  },
  enclosures: {
    leadCardType: 'DETAILS',
    mobileTertiaryNavId: TertiaryNavIdentifier.ENCLOSURE,
    tertiaryNavId: TertiaryNavIdentifier.INVITATIONS,
  },
  programs: {
    leadCardType: 'PROGRAM',
    mobileTertiaryNavId: TertiaryNavIdentifier.PROGRAM,
    tertiaryNavId: TertiaryNavIdentifier.DAY_OF,
  },
  'place-cards': {
    leadCardType: 'PLACE',
    mobileTertiaryNavId: TertiaryNavIdentifier.PLACE,
    tertiaryNavId: TertiaryNavIdentifier.DAY_OF,
  },
  'table-numbers': {
    leadCardType: CARD_TYPES.tableNumber,
    mobileTertiaryNavId: TertiaryNavIdentifier.DAY_OF,
    tertiaryNavId: TertiaryNavIdentifier.DAY_OF,
  },
  'holiday-cards': {
    leadCardType: 'HOLIDAY',
    mobileTertiaryNavId: TertiaryNavIdentifier.HOLIDAY,
    tertiaryNavId: TertiaryNavIdentifier.HOLIDAY,
  },
  'bridal-shower': {
    leadCardType: CARD_TYPES.weddingShower,
    mobileTertiaryNavId: TertiaryNavIdentifier.WEDDING_SHOWER_INVITATION,
    tertiaryNavId: TertiaryNavIdentifier.INVITATIONS,
  },
  'paper-add-ons': {
    leadCardType: CARD_TYPES.paperAddOns,
    mobileTertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
    tertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
  },
  'belly-bands': {
    leadCardType: CARD_TYPES.bellyBand,
    mobileTertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
    tertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
  },
  'vellum-jackets': {
    leadCardType: CARD_TYPES.vellumJacket,
    mobileTertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
    tertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
  },
  'wax-seals': {
    leadCardType: CARD_TYPES.waxSeal,
    mobileTertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
    tertiaryNavId: TertiaryNavIdentifier.PAPER_ADD_ONS,
  },
};

export const PLP_EVENTS_AND_TITLES = {
  invitations: {
    activeCardEvent: 'INVITATION',
    pageTitle: `${CURRENT_YEAR} Wedding Invitations | Customizable Templates & Designs - Zola`,
    metaDescription:
      'Discover affordable wedding invitations that will leave your guests breathless. Explore beautiful designs & choose an invite that sets the tone for your big day.',
    metaKeywords:
      'Wedding invites,Wedding invitations,wedding invitation templates,Wedding invitation cards,Sample wedding invitations,Wedding invitation design,Wedding envelopes',
    canonical: `https://www.zola.com/wedding-planning/invitations/shop`,
  },
  'thank-you-cards': {
    activeCardEvent: 'THANK_YOU',
    pageTitle: 'Wedding Thank You Cards - Free Addressing - Zola',
    metaDescription:
      'Find wedding thank you cards that will charm & delight your guests. Choose from dozens of beautiful designs to match your wedding theme and order in minutes.',
    metaKeywords: 'Wedding thank you cards,Custom thank you cards,Wedding thank you',
    canonical: `https://www.zola.com/wedding-planning/thank-you-cards/shop`,
  },
  menus: {
    activeCardEvent: 'MENU',
    pageTitle: 'Wedding Menu Cards - Hundreds of Templates - Zola',
    metaDescription:
      'Create a beautiful dining experience with the perfect wedding menu. Choose from dozens of elegant and charming designs and styles to match any wedding theme.',
    metaKeywords: 'Wedding menu,wedding menu cards,menu cards,wedding reception food menu',
    canonical: `https://www.zola.com/wedding-planning/menus/shop`,
  },
  programs: {
    activeCardEvent: 'PROGRAM',
    pageTitle: 'Wedding Programs - Hundreds of Templates - Zola',
    metaDescription:
      'Get the perfect program for your wedding day. Choose from dozens of charming designs to match your wedding theme and style & order with a few clicks.',
    metaKeywords:
      'Wedding programs,Wedding programs template,Ceremony program,Ceremony program template',
    canonical: `https://www.zola.com/wedding-planning/programs/shop`,
  },
  enclosures: {
    activeCardEvent: 'DETAILS',
    pageTitle: 'Affordable Enclosure Cards | Hundreds of Templates & Samples',
    metaDescription:
      'Get beautiful & elegant enclosure cards for your wedding. Choose from dozens of alluring designs that match your wedding theme & order with a few clicks.',
    canonical: `https://www.zola.com/wedding-planning/enclosures/shop`,
  },
  'place-cards': {
    activeCardEvent: 'PLACE',
    pageTitle: 'Wedding Place Cards - Hundreds of Templates - Zola',
  },
  'save-the-date': {
    activeCardEvent: 'SAVE_THE_DATE',
    pageTitle: `${CURRENT_YEAR} Paper & Digital Save The Dates for Weddings - Zola`,
    metaDescription:
      'Announce your wedding day with Save the Date cards. Choose from hundreds of customizable designs crafted by artists. Premium quality, perfectly priced.',
    metaKeywords:
      'save the date,Save the date cards,save the date magnets,Save the date wedding,Save the date invitations',
    canonical: `https://www.zola.com/wedding-planning/save-the-date/shop`,
  },
  'digital-save-the-date': {
    activeCardEvent: 'SAVE_THE_DATE',
    pageTitle: 'Free Digital Save the Dates for Your Wedding - Zola',
    metaDescription:
      "Spread the word about your wedding with digital save the date cards. Customize a beautiful design in minutes. The best part? They're free.",
    metaKeywords:
      'save the date,Save the date cards,save the date magnets,Save the date wedding,Save the date invitations',
    canonical: `https://www.zola.com/wedding-planning/digital/save-the-date/shop`,
  },
  'change-the-date': {
    activeCardEvent: 'CHANGE_THE_DATE',
    pageTitle: 'Change The Date Cards - Zola',
    metaDescription:
      'Need to postpone or reschedule your big day? Update guests with a change the date wedding card. Get free guest addressing, envelopes, and more.',
    canonical: `https://www.zola.com/wedding-planning/change-the-date/shop`,
  },
  'holiday-cards': {
    activeCardEvent: 'HOLIDAY',
    pageTitle: 'Holiday Cards - Free Addressing! - Zola',
    canonical: `https://www.zola.com/wedding-planning/holiday-cards/shop`,
  },
  'bridal-shower': {
    activeCardEvent: CARD_TYPES.weddingShower,
    pageTitle: 'Bridal & Wedding Shower Invitations | Free Addressing! - Zola',
    metaDescription:
      'Find bridal and wedding shower invitations for your wedding in minutes. Choose from beautiful designs and imagery that set the tone for your special day.',
    canonical: `https://www.zola.com/wedding-planning/bridal-shower/shop`,
  },
  'table-numbers': {
    activeCardEvent: CARD_TYPES.tableNumber,
    pageTitle: 'Table Number Cards - Zola',
    metaDescription:
      'Discover elegant and personalized wedding table numbers for your special day. Find the perfect design to complement your wedding theme and elevate your table decor.',
    canonical: `https://www.zola.com/wedding-planning/table-numbers/shop`,
  },
  'paper-add-ons': {
    activeCardEvent: CARD_TYPES.paperAddOns,
    pageTitle: 'Paper Add-ons - Zola',
    metaDescription:
      'These are the finishing touches that will really impress your guests. Discover our wax seals, vellum jackets, and belly bands.',
    canonical: `https://www.zola.com/wedding-planning/paper-add-ons/shop`,
  },
};

export const SETTINGS_BY_CARD_TYPE = {
  MATCHING_SUITES: {
    type: 'MATCHING_SUITES',
    urlSlug: 'suites',
    label: 'suites',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: false,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.invitation]: {
    type: CARD_TYPES.invitation,
    urlSlug: 'invitations',
    label: 'Invitations',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: true,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: true,
    isLead: true,
    showPdpFreeAddressingModule: false,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: true,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: true,
  },
  [CARD_TYPES.std]: {
    type: CARD_TYPES.std,
    urlSlug: 'save-the-date',
    label: 'Save the dates',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: true,
    hasMagnet: true,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: true,
    isLead: true,
    showPdpFreeAddressingModule: false,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: true,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: true,
  },
  // TODO DSTD
  [CARD_TYPES.dstd]: {
    type: CARD_TYPES.dstd,
    urlSlug: 'digital-save-the-date',
    label: 'Digital save the dates',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: true,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: true,
    isLead: true,
    showPdpFreeAddressingModule: false,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: true,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: true,
  },
  [CARD_TYPES.ctd]: {
    type: CARD_TYPES.ctd,
    urlSlug: 'change-the-date',
    label: 'Change The Dates',
    hasFoil: false,
    hasLetterpress: true,
    hasMotifs: false,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: false,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: true,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.thankyou]: {
    type: CARD_TYPES.thankyou,
    urlSlug: 'thank-you-cards',
    label: 'Thank you cards',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: true,
    freeAddressingText: 'Enjoy FREE Guest Addressing & Envelopes',
    freeAddressingImage: GuestAddressingIcon,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.menu]: {
    type: CARD_TYPES.menu,
    urlSlug: 'menus',
    label: 'Menus',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: true,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.program]: {
    type: CARD_TYPES.program,
    urlSlug: 'programs',
    label: 'Programs',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: true,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.place]: {
    type: CARD_TYPES.place,
    urlSlug: 'place-cards',
    label: 'Place cards',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: true,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: false,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: true,
    freeAddressingText: 'Enjoy FREE printing on Place Cards!',
    freeAddressingImage: GuestAddressingIcon,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  DETAILS: {
    type: 'DETAILS',
    urlSlug: 'enclosures',
    label: 'Enclosures',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.enclosure]: {
    type: CARD_TYPES.enclosure,
    urlSlug: 'enclosures',
    label: 'Enclosures',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: true,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.rsvp]: {
    type: CARD_TYPES.rsvp,
    urlSlug: null, // secondary card type, no slug.
    label: 'RSVP',
    hasFoil: true,
    hasLetterpress: true,
    hasMotifs: false,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: false,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: null,
    showPdpCovidBanner: null,
    showReviewStepCtdBanner: null,
  },
  ENVELOPE: {
    type: 'ENVELOPE',
    urlSlug: null, // secondary card type, no slug.
    label: 'Envelope',
    hasFoil: false, // Not used for now
    hasLetterpress: false,
    hasMotifs: false,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: false,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: false,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: null,
    showPdpCovidBanner: null,
    showReviewStepCtdBanner: null,
    hiddenPlpFilters: null, // secondary card type no PLP
  },
  [CARD_TYPES.holiday]: {
    type: CARD_TYPES.holiday,
    urlSlug: 'holiday-cards',
    label: 'Holiday cards',
    hasFoil: true,
    hasLetterpress: false,
    hasMotifs: true,
    hasShapes: true,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: true,
    hasCustomPhoto: true,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: true,
    freeAddressingText: 'Enjoy FREE Guest Addressing & Envelopes',
    freeAddressingImage: GuestAddressingIcon,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.weddingShower]: {
    type: CARD_TYPES.weddingShower,
    urlSlug: 'bridal-shower',
    label: 'Wedding shower invitations',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: true,
    hasShapes: true,
    hasMagnet: false,
    hasMultipleOrientations: true,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: false,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: true,
    freeAddressingText: 'Enjoy FREE Guest Addressing & Envelopes',
    freeAddressingImage: GuestAddressingIcon,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.tableNumber]: {
    type: CARD_TYPES.tableNumber,
    urlSlug: 'table-numbers',
    label: 'Table numbers',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: true,
    hasShapes: true,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: false,
    hasNoCustomPhoto: false,
    isVowRenewal: false,
    isLead: true,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.paperAddOns]: {
    type: CARD_TYPES.paperAddOns,
    urlSlug: 'paper-add-ons',
    label: 'Paper add-ons',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: false,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: false,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: false,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.bellyBand]: {
    type: CARD_TYPES.bellyBand,
    urlSlug: 'paper-add-ons',
    label: 'Paper add-ons',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: false,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: false,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: false,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.vellumJacket]: {
    type: CARD_TYPES.vellumJacket,
    urlSlug: 'paper-add-ons',
    label: 'Paper add-ons',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: false,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: false,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: false,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
  [CARD_TYPES.waxSeal]: {
    type: CARD_TYPES.waxSeal,
    urlSlug: 'paper-add-ons',
    label: 'Paper add-ons',
    hasFoil: false,
    hasLetterpress: false,
    hasMotifs: false,
    hasShapes: false,
    hasMagnet: false,
    hasMultipleOrientations: false,
    hasThirdPartyDesigns: false,
    hasCustomPhoto: false,
    hasNoCustomPhoto: true,
    isVowRenewal: false,
    isLead: false,
    showPdpFreeAddressingModule: null,
    freeAddressingText: null,
    freeAddressingImage: null,
    showPdpCtdBanner: false,
    showPdpCovidBanner: false,
    showReviewStepCtdBanner: false,
  },
};

export const CARD_TYPES_BEFORE_THE_DAY = [CARD_TYPES.std, CARD_TYPES.invitation];

export const CARD_TYPES_ON_THE_DAY = FeatureFlags.get('paperTableNumber')
  ? [CARD_TYPES.place, CARD_TYPES.tableNumber, CARD_TYPES.menu, CARD_TYPES.program]
  : [CARD_TYPES.place, CARD_TYPES.menu, CARD_TYPES.program];

export const CARD_TYPES_AFTER_THE_DAY = [CARD_TYPES.thankyou];

export const CARD_TYPES_JOURNEY_DATA = {
  [CARD_TYPES.std]: {
    title: 'Save the Dates',
    text: 'Order 6 months out',
    momentSubtractParameters: [6, 'M'],
    plpUrl: 'save-the-date',
    defaultImage: `${getImageUrl('861cc15a-5bca-4c77-85b5-88f3febf67b7')}?w=140`,
  },
  [CARD_TYPES.invitation]: {
    title: 'Invitations',
    text: 'Order 3–6 months out',
    plpUrl: 'invitations',
    momentSubtractParameters: [3, 'M'],
    defaultImage: `${getImageUrl('05e6700c-8b37-44cd-9b15-27f263874f16')}?w=140`,
  },
  [CARD_TYPES.place]: {
    title: 'Place Cards',
    text: 'Order 3–4 weeks out',
    momentSubtractParameters: [4, 'w'],
    plpUrl: 'place-cards',
    defaultImage: `${getImageUrl('decac6f8-f681-4be0-8cd6-51aa5da28de5')}?w=140`,
  },
  [CARD_TYPES.tableNumber]: {
    title: 'Table Numbers',
    text: 'Order 3–4 weeks out',
    momentSubtractParameters: [4, 'w'],
    plpUrl: 'table-numbers',
    defaultImage: `${getImageUrl('56b5a238-33b1-42bd-806c-dbb3d6c9564f')}?w=140`,
  },
  [CARD_TYPES.menu]: {
    title: 'Menus',
    text: 'Order 3–4 weeks out',
    momentSubtractParameters: [4, 'w'],
    plpUrl: 'menus',
    defaultImage: `${getImageUrl('3b2c44b7-3dd9-42a4-8323-a9663abcbe15')}?w=140`,
  },
  [CARD_TYPES.program]: {
    title: 'Programs',
    text: 'Order 3–4 weeks out',
    momentSubtractParameters: [4, 'w'],
    plpUrl: 'programs',
    defaultImage: `${getImageUrl('73b6375f-ee5e-4e32-a5a4-23b4f71d66dc')}?w=140`,
  },
  [CARD_TYPES.thankyou]: {
    title: 'Thank You Cards',
    text: 'Send within 3–4 months',
    momentSubtractParameters: [3, 'M'],
    plpUrl: 'thank-you-cards',
    defaultImage: `${getImageUrl('ed88993a-8f0e-4e33-b1a6-5db65f989ea2')}?w=140`,
  },
};

export const CUSTOMIZATION_TYPES_MAP = new Map([
  ['DETAILS', 'Enclosure card'],
  ['ENCLOSURE', 'Enclosure card'],
  ['ENVELOPE', 'Envelope'],
  ['HOLIDAY', 'Holiday'],
  ['INVITATION', 'Invitation'],
  ['MENU', 'Menu'],
  ['PLACE', 'Place card'],
  ['PROGRAM', 'Program'],
  ['SAVE_THE_DATE', 'Save the date'],
  ['CHANGE_THE_DATE', 'Change the date'],
  ['THANK_YOU', 'Thank you'],
  ['RSVP', 'RSVP'],
  [CARD_TYPES.weddingShower, 'Wedding shower invitation'],
  [CARD_TYPES.tableNumber, 'Table numbers'],
  [CARD_TYPES.bellyBand, 'Belly band'],
  [CARD_TYPES.vellumJacket, 'Vellum jacket'],
  [CARD_TYPES.waxSeal, 'Wax seal'],
]);

export const CARD_TYPE_TO_URL_MAP = new Map([
  [CARD_TYPES.bellyBand, 'paper-add-ons'],
  [CARD_TYPES.vellumJacket, 'paper-add-ons'],
  [CARD_TYPES.waxSeal, 'paper-add-ons'],
]);
