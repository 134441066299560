import Cookies from 'universal-cookie';
import { browserHistory } from 'react-router';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import _find from 'lodash/find';
import { AppDispatch, RootState } from 'reducers/index';
import type {
  WCmsEventView,
  WPublicWeddingView,
  WRsvpRequest,
  WThemeGroupView,
  RsvpConfirmationRequest,
  WGuestGroupSearchResult,
  WRsvpData,
  WCmsRsvpPageView,
} from '@zola/svc-web-api-ts-client';
import type { Action } from 'redux';
import * as ActionType from './types/PublicWebsiteActionTypes';
import { createGuest } from './GuestActions';
import { hideModal } from './ModalActions';

export const entityNames: Record<string, string> = {
  wedding_party: 'wedding_party_members',
  home: 'homepage_sections',
  event: 'events',
  travel: 'travels',
  photos: 'photos',
  registry: 'wedding_registries',
  faq: 'faqs',
  things_to_do: 'pois',
  rsvp: 'rsvp_questions',
  videos: 'videos',
  video: 'video', // homepage uses a singular video
};

type ThemeOverrideValues = {
  font_name?: string;
  map_pin?: string;
  map_style?: string;
  key?: string;
  background_color?: string;
};

type PasscodeRequest = {
  slug?: string; // FIXME this should not be optional
  passcode: string;
};

function requestWedding() {
  return {
    type: ActionType.REQUEST_WEDDING,
  };
}

function requestPage(pageSlug: string) {
  return {
    type: ActionType.REQUEST_PAGE,
    pageSlug,
  };
}

// FIXME dont use any
function receivePage(pageSlug: string, data: any) {
  const entityName = entityNames[pageSlug.toLowerCase()];
  const baseEntities = data[entityName];
  let videoEntities = [];
  if (entityName === entityNames.home && data.video) videoEntities = [data.video];
  if (entityName === entityNames.photos && data.videos) videoEntities = data.videos;
  const entities = [...baseEntities, ...videoEntities];

  return {
    type: ActionType.RECEIVE_PAGE,
    entityName,
    payload: data,
    entities,
  };
}

// FIXME this should not be allowed to be undefined
export function receiveWedding(publicWeddingView?: WPublicWeddingView) {
  return {
    type: ActionType.RECEIVE_WEDDING,
    payload: publicWeddingView,
  };
}

export function weddingNotFound(err: Error) {
  return {
    type: ActionType.NOT_FOUND,
    error: err,
  };
}

function updateGuestSearchCookies() {
  const cookies = new Cookies();
  const date = new Date();
  const cookieMinutesToExpire = 30;
  const searchesNotActedOnLimit = 5;
  const guestSearchesNotActedOn = parseInt(cookies.get('guestSearchesNotActedOn'), 10) || 0;
  // disable search lockout for development - removing staging for react-16 testing
  if (window.zola.env === 'development') {
    return;
  }
  date.setTime(date.getTime() + 1000 * 60 * cookieMinutesToExpire);
  cookies.set('guestSearchesNotActedOn', guestSearchesNotActedOn + 1, { expires: date });

  if (guestSearchesNotActedOn + 1 >= searchesNotActedOnLimit) {
    cookies.set('rsvpSearchDisabled', date.getTime(), { expires: date });
  }
}

export function receiveGuestSearch(guestGroupSearchResults: Array<WGuestGroupSearchResult>) {
  updateGuestSearchCookies();
  return {
    type: ActionType.RECEIVE_GUEST_SEARCH,
    payload: guestGroupSearchResults,
  };
}

export function revealAccessForm() {
  return {
    type: ActionType.REVEAL_ACCESS_FORM,
    payload: true,
  };
}

export function hideAccessForm() {
  return {
    type: ActionType.HIDE_ACCESS_FORM,
    payload: false,
  };
}

export function resetGuestSearch() {
  return {
    type: ActionType.RESET_GUEST_SEARCH,
  };
}

export function requestGuestRsvp() {
  return {
    type: ActionType.REQUEST_GUEST_RSVP,
  };
}

export function receiveGuestRsvp(rsvpData: WRsvpData) {
  return {
    type: ActionType.RECEIVE_GUEST_RSVP,
    payload: rsvpData,
  };
}

export function resetGuestRsvp() {
  return {
    type: ActionType.RESET_GUEST_RSVP,
  };
}

export function receiveGuestRsvpSuccess(response: { success: boolean }) {
  return {
    type: ActionType.RECEIVE_GUEST_RSVP_SUCCESS,
    payload: response,
  };
}

export function resetGuestRsvpSuccess() {
  return {
    type: ActionType.RECEIVE_GUEST_RSVP_SUCCESS,
    payload: {
      success: false,
    },
  };
}
export function storeMobileToken(token: string) {
  return {
    type: ActionType.STORE_MOBILE_TOKEN,
    payload: {
      token,
    },
  };
}

export function receiveVirtualEventPage(eventData: WCmsEventView) {
  return {
    type: ActionType.RECEIVE_VIRTUAL_EVENT_PAGE,
    payload: eventData,
  };
}

function receiveWebsiteVisibility(visible: boolean) {
  return {
    type: ActionType.RECEIVE_WEBSITE_VISIBILITY,
    payload: visible,
  };
}

// FIXME why can this be undefined?
export function getWeddingBySlug(slug?: string) {
  return (dispatch: AppDispatch) => {
    if (slug) {
      dispatch(requestWedding());
      return ApiService.get<WPublicWeddingView>(
        `/web-api/v1/publicwedding/slug/${slug}`
      ).then(publicWeddingView => dispatch(receiveWedding(publicWeddingView)));
    }
    return Promise.resolve(null);
  };
}

export function getWeddingPasscodeViewBySlug(slug: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestWedding());
    return ApiService.get<WPublicWeddingView>(
      `/web-api/v1/publicwedding/slug/${slug}/passcode`
    ).then(json => dispatch(receiveWedding(json)));
  };
}

export function submitPasscode(request: PasscodeRequest) {
  return () => ApiService.post<WPublicWeddingView>('/web-api/v1/publicwedding/passcode', request);
}

// Can't figure out why the react-router-redux types are not working for locationBeforeTransitions. Not worth fixing right now so just using any
export function getWeddingPageByTypeV2(pageSlug: string) {
  return (dispatch: AppDispatch, getState: any) => {
    dispatch(requestPage(pageSlug));
    const state = getState();
    const weddingAccountId = state.publicWebsite.wedding.wedding_account_id;
    const viewHiddenPage = state.routing.locationBeforeTransitions.query.view_hidden_page
      ? state.routing.locationBeforeTransitions.query.view_hidden_page
      : false;
    const { slug } = state.publicWebsite.wedding;
    const token = state.publicWebsite.token.token ? state.publicWebsite.token.token : false;
    return ApiService.get(
      `/web-api/v1/publicwedding/page/slug/${pageSlug}/wedding_account/id/${weddingAccountId}?view_hidden_page=${viewHiddenPage}&token=${token}`
    )
      .then(pageView => dispatch(receivePage(pageSlug, pageView)))
      .catch((error: Error) => {
        dispatch(weddingNotFound(error));
        browserHistory.push(`/wedding/${slug}`);
      });
  };
}

// Can't figure out why the react-router-redux types are not working for locationBeforeTransitions. Not worth fixing right now so just using any
export function getPublicRsvpPageV2() {
  return (dispatch: AppDispatch, getState: any) => {
    dispatch(requestPage('rsvp'));
    const state = getState();
    const weddingAccountId = state.publicWebsite.wedding.wedding_account_id;
    const viewHiddenPage = state.routing.locationBeforeTransitions.query.view_hidden_page;
    const { slug } = state.publicWebsite.wedding;
    const { token } = state.publicWebsite.token;
    return ApiService.get<WCmsRsvpPageView>(
      `/web-api/v2/publicwedding/page/slug/rsvp/wedding_account/id/${weddingAccountId}?view_hidden_page=${viewHiddenPage}&token=${token}`
    )
      .then(json => dispatch(receivePage('rsvp', json)))
      .catch(error => {
        dispatch(weddingNotFound(error));
        browserHistory.push(`/wedding/${slug}`);
      });
  };
}

export function guestSearch(guestName: string) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: 'GUEST_SEARCH' });
    const state = getState();
    const uuid = state.publicWebsite.wedding.wedding_account_uuid;
    return ApiService.post<Array<WGuestGroupSearchResult>>(
      `/web-api/v1/publicwedding/rsvp/guest/wedding-account/uuid/${uuid}/search-groups`,
      { guest_name: guestName }
    ).then(guestGroupSearchResults => {
      dispatch(receiveGuestSearch(guestGroupSearchResults));
      return guestGroupSearchResults;
    });
  };
}

export function getGuestGroupRsvpByUuid(guestGroupUuid: string) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(requestGuestRsvp());
    const state = getState();
    const weddingAccountUuid = state.publicWebsite.wedding.wedding_account_uuid;
    return ApiService.get<WRsvpData>(
      `/web-api/v1/publicwedding/rsvp/guest-group/uuid/${guestGroupUuid}/wedding-account/uuid/${weddingAccountUuid}`
    ).then(rsvpData => dispatch(receiveGuestRsvp(rsvpData)));
  };
}

function normalizeRsvpInputV2(guest: any) {
  if (guest.name_unknown) {
    return Object.assign({}, guest, {
      prefix: undefined,
      first_name: undefined,
      family_name: undefined,
      suffix: undefined,
    });
  }
  const { firstName, familyName } = guest.name; // assign name: { firstName, lastName } to first_name and last_name
  return Object.assign({}, guest, {
    first_name: firstName,
    family_name: familyName,
  });
}

export function updateWebsiteVisibility(enable: boolean) {
  return (dispatch: AppDispatch) => {
    return ApiService.post('/web-api/v1/weddingAccount/publish', { enable }).then(() =>
      dispatch(receiveWebsiteVisibility(!enable))
    );
  };
}

export function getRsvpByGuestGroupUuid(guestGroupUuid: string) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(requestGuestRsvp());
    const state = getState();
    const weddingAccountUuid = state.publicWebsite.wedding.wedding_account_uuid;
    return ApiService.get<WRsvpData>(
      `/web-api/v2/publicwedding/rsvp/guest-group/uuid/${guestGroupUuid}/wedding-account/uuid/${weddingAccountUuid}`
    ).then(rsvpData => dispatch(receiveGuestRsvp(rsvpData)));
  };
}

// FIXME dont use any
export function updateGuestRsvpV2(guestRsvpRequestFormData: any) {
  const guests = guestRsvpRequestFormData.guests.map((guest: any) => normalizeRsvpInputV2(guest));
  const answers = guestRsvpRequestFormData.rsvp_answers.allIds.map((id: number) => {
    const rsvpAnswer = guestRsvpRequestFormData.rsvp_answers[id];
    const { answer } = rsvpAnswer;
    if (answer === '') {
      return { ...rsvpAnswer, answer: null };
    }
    return rsvpAnswer;
  });
  const formattedRsvpRequest = Object.assign({}, guestRsvpRequestFormData, {
    guests,
    rsvp_answers: answers,
  });

  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { wedding_account_uuid } = state.publicWebsite.wedding;
    return ApiService.post<{ success: boolean }>(
      `/web-api/v2/publicwedding/rsvp/wedding-account/uuid/${wedding_account_uuid}`,
      formattedRsvpRequest
    ).then(response => {
      return dispatch(receiveGuestRsvpSuccess(response));
    });
  };
}

export function rsvpConfirmation(rsvpConfirmationRequest: RsvpConfirmationRequest) {
  return () => {
    return ApiService.post('/web-api/v2/publicwedding/rsvp/confirmation', rsvpConfirmationRequest);
  };
}

// manage the guest request response and any necessary rerouting
export function manageGuestRequests(notificationObject: Array<WRsvpRequest>) {
  if (notificationObject.length === 0) {
    browserHistory.push('/wedding/manage/guests/all');
    return {
      type: ActionType.GET_GUEST_REQUESTS,
      payload: notificationObject,
    };
  }
  return {
    type: ActionType.GET_GUEST_REQUESTS,
    payload: notificationObject,
  };
}

export function manageGuestRequestCount(count: number) {
  return {
    type: ActionType.RECEIVE_GUEST_COUNT,
    payload: count,
  };
}

// get all guest requests
export function getGuestRequests() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const weddingAccountId = state.wedding.account.id;
    return ApiService.get<Array<WRsvpRequest>>(
      `/web-api/v2/publicwedding/rsvp/request/wedding_account/id/${weddingAccountId}/list`
    ).then(rsvpRequests => dispatch(manageGuestRequests(rsvpRequests)));
  };
}

export function getGuestRequestCount() {
  return (dispatch: AppDispatch) => {
    return ApiService.get<number>(
      '/website-nav/web-api/v1/nav/wedding_account/id/count'
    ).then(count => dispatch(manageGuestRequestCount(count)));
  };
}

// triggers off success state for submitted rsvp request
function handleRequestSubmit() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: ActionType.RSVP_REQUEST_SUBMITTED,
      payload: true,
    });
  };
}

// receive guest request by uuid
export function receiveGuestRsvpRequestByUuid(rsvpRequest: WRsvpRequest) {
  return {
    type: ActionType.RECEIVE_GUEST_REQUEST_BY_UUID,
    payload: rsvpRequest,
  };
}

// get guest request by uuid
export function grabGuestByUuid(guestUuid: string) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const weddingAccountId = state.wedding.account.id;
    const requestBody = { uuid: guestUuid, wedding_account_id: weddingAccountId };
    return ApiService.post<WRsvpRequest>('/web-api/v2/publicwedding/rsvp/request/uuid', requestBody)
      .catch((err: Error) => {
        if (err.name === 'forbidden') {
          // FIXME what is the value to check here and how to redirect
          browserHistory.push('/wedding/manage/guests/all');
        }
        throw err;
      })
      .then(rsvpRequest => dispatch(receiveGuestRsvpRequestByUuid(rsvpRequest)));
  };
}

/**
 * Create request for rsvp by guest not in the guest list, with no recaptcha
 * FIXME dont use any
 *
 * @param request
 * @deprecated Use {@link createRsvpRequestV2} instead
 */
export function createRsvpRequest(request: any) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { wedding_account_id, wedding_account_uuid } = state.publicWebsite.wedding;
    return ApiService.post<WRsvpRequest>('/web-api/v2/publicwedding/rsvp/request', {
      wedding_account_id,
      wedding_account_uuid,
      ...request,
    }).then(() => dispatch(handleRequestSubmit()));
  };
}

/**
 * Create request for rsvp by guest not in the guest list, WITH recaptcha
 * FIXME dont use any
 *
 * @param request
 */
export function createRsvpRequestV2(request: any) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { wedding_account_id, wedding_account_uuid } = state.publicWebsite.wedding;
    return ApiService.post<WRsvpRequest>('/web-api/v3/publicwedding/rsvp/request', {
      wedding_account_id,
      wedding_account_uuid,
      ...request,
    }).then(() => dispatch(handleRequestSubmit()));
  };
}

export function guestSubmitted() {
  return {
    type: ActionType.GUEST_SUCCESSFULLY_SUBMITTED,
  };
}

// decline guest request to be added
// FIXME dont use any
export function processDecline(declineRequest: any) {
  const headline = `${declineRequest.first_name} ${declineRequest.family_name}'s RSVP request has been declined.`;

  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const weddingAccountId = state.wedding.account.id;
    const requestBody = { uuid: declineRequest.uuid, wedding_account_id: weddingAccountId };
    return ApiService.put<WRsvpRequest>(
      '/web-api/v2/publicwedding/rsvp/request/decline',
      requestBody
    )
      .then(() => dispatch(hideModal()))
      .then(() => dispatch(guestSubmitted()))
      .then(() => dispatch(getGuestRequestCount()))
      .then(rsvpRequest => {
        dispatch(toastsActions.positive({ headline }));
        return rsvpRequest;
      })
      .then(() => browserHistory.push('/wedding/manage/guests/rsvp-notifications'));
  };
}

// FIXME no any
export function submitGuestGroupRequestFromNotification(
  createGuestGroupRequest: any,
  guestUuid: string
) {
  const firstName = createGuestGroupRequest.guests[0].first_name;
  const familyName = createGuestGroupRequest.guests[0].family_name;
  const headline = `${firstName} ${familyName} RSVP request has been accepted.`;
  return (dispatch: AppDispatch) => {
    return ApiService.put<WRsvpRequest>(
      `/web-api/v2/publicwedding/rsvp/request/uuid/${guestUuid}/accept`,
      createGuestGroupRequest
    )
      .then(() => dispatch(guestSubmitted()))
      .then(() => dispatch(getGuestRequestCount()))
      .then(() => dispatch(getGuestRequests()))
      .then(json => {
        dispatch(toastsActions.positive({ headline }));
        return json;
      })
      .then(() => browserHistory.push('/wedding/manage/guests/rsvp-notifications'));
  };
}

// FIXME no any
function normalizeGuestInputs(guest: any) {
  const mealOptionId = guest.meal_option_id ? parseInt(guest.meal_option_id, 10) : undefined;
  const prefix = guest.prefix || undefined;
  const suffix = guest.suffix || undefined;

  if (guest.name_unknown) {
    return Object.assign({}, guest, {
      prefix: undefined,
      first_name: undefined,
      family_name: undefined,
      suffix: undefined,
      meal_option_id: mealOptionId,
    });
  }
  return Object.assign({}, guest, { prefix, suffix, meal_option_id: mealOptionId });
}

// FIXME This is crazy, this needs to be simplified
// FIXME no any types
export function formatGuestGroupRequest(guestGroupRequest: any) {
  const formattedRequest = Object.assign({}, guestGroupRequest, {
    invited: guestGroupRequest.invited === 'true' || guestGroupRequest.invited === true,
    save_the_date_sent:
      guestGroupRequest.save_the_date_sent === 'true' ||
      guestGroupRequest.save_the_date_sent === true,
    invitation_sent:
      guestGroupRequest.invitation_sent === 'true' || guestGroupRequest.invitation_sent === true,
  });

  // Make sure relationship_type is set and in an acceptable configuration and add common fields to primary only
  const guests = guestGroupRequest.guests.map((guest: any, index: any) => {
    const inviteObject = formattedRequest.event_invitations;
    const filteredInvites: any[] = [];
    inviteObject.forEach((invite: any) => {
      if (invite.checked === true) {
        filteredInvites.push(invite);
      }
    });

    switch (index) {
      case 0:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'PRIMARY',
          event_invitations: filteredInvites,
        });
      case 1: {
        const relationshipType =
          guest.relationship_type === 'PRIMARY' ? 'PARTNER' : guest.relationship_type;
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: relationshipType,
          event_invitations: filteredInvites,
        });
      }
      default:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'CHILD',
          event_invitations: filteredInvites,
        });
    }
  });

  // Return the final request object
  return Object.assign({}, formattedRequest, { guests });
}

// add guest from request notification
// FIXME no any type
export function addGuestFromRequestNotification(createGuestGroupRequest: any) {
  // handle notification messaging
  const guestGroupRequest = formatGuestGroupRequest(createGuestGroupRequest);
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const guestUuid = state.publicWebsite.guestRequestByUuid.uuid;
    dispatch(createGuest());
    return dispatch(submitGuestGroupRequestFromNotification(guestGroupRequest, guestUuid));
  };
}

export function showWebsiteRsvpSummary(value: boolean) {
  return {
    type: ActionType.SHOW_RSVP_SUMMARY,
    payload: value,
  };
}

export function handleModalAction(action: Action) {
  return {
    type: ActionType.HANDLE_RSVP_MODAL_ACTION,
    payload: action,
  };
}

export const getEventEncodedPage = (hash: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const weddingAccountId = state.publicWebsite.wedding.wedding_account_id;
    return ApiService.get<WCmsEventView>(
      `/web-api/v1/publicwedding/page/event/encoded/${hash}/wedding_account/id/${weddingAccountId}`
    ).then(cmsEventView => {
      dispatch(receiveVirtualEventPage(cmsEventView));
      return cmsEventView;
    });
  };
};

export const getPassThroughZoomMeetingData = (hash: string) => {
  return (dispatch: AppDispatch) => {
    return ApiService.get<WCmsEventView>(
      `/web-api/v1/publicwedding/page/event/encoded/${hash}/read-through`
    ).then(cmsEventView => {
      dispatch(receiveVirtualEventPage(cmsEventView));
      return cmsEventView;
    });
  };
};

export function hideTopNavigation() {
  return {
    type: ActionType.HIDE_TOP_NAVIGATION,
  };
}

function receiveWeddingTheme(data: ThemeOverrideValues) {
  return {
    type: ActionType.RECEIVE_WEDDING_THEME,
    payload: data,
  };
}

export function fetchThemeOverride(themeKey: string) {
  return (dispatch: AppDispatch) => {
    return ApiService.get<WThemeGroupView>(`/web-api/v2/theme/${themeKey}`).then(
      themeGroupDetailView => {
        const { themes } = themeGroupDetailView;
        if (themes) {
          const match = _find(themes, o => o.key === themeKey);
          if (match) {
            const pickedValues: ThemeOverrideValues = {
              font_name: match.font_name,
              map_pin: match.map_pin,
              map_style: match.map_style,
              key: match.key,
              background_color: match.background_color,
            };
            dispatch(receiveWeddingTheme(pickedValues));
          }
        }
      }
    );
  };
}

export function togglePoiMaps() {
  return {
    type: ActionType.TOGGLE_POI_MAPS,
  };
}
