import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

// actions
import {
  fetchDefaultTemplates,
  fetchTemplate,
  setStepKey,
  updateDesignCustomizations,
} from 'cards/reducers/samplesFlow/actions';

// selectors
import {
  getCardSuiteDetailsSet,
  getDesignCustomizationsSet,
  getTemplatesSet,
  getWeddingDetails,
} from 'cards/selectors/samplesFlow';

// components
import ProductColorPicker from 'cards/components/common/ProductColorPicker/ProductColorPicker';
import { Tabs } from '@zola/zola-ui/src/components/Tabs';

// utils
import { useIsMobile } from 'paper/hooks/isMobile';
import { lookupSelectedVariation } from 'cards/components/common/Samples/util/helpers';
import { DesignCustomizations } from 'cards/reducers/samplesFlow/types';
import Preview from './Preview';
import Uploadcare from './Uploadcare';
import Form from '../../Form';
import { STEPS } from '../../constants';
import { extractCoupleNames } from '../../utils';

import styles from './styles.module.less';

interface PhotoStepProps {
  cardSuiteUUIDList: string[];
  isMagnet: boolean;
}

const PhotoStep = ({ cardSuiteUUIDList, isMagnet }: PhotoStepProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const cardSuiteDetailsSet = useSelector(getCardSuiteDetailsSet);
  const designCustomizationsSet = useSelector(getDesignCustomizationsSet);
  const templatesSet = useSelector(getTemplatesSet);
  const weddingDetails = useSelector(getWeddingDetails);

  const [activePreviewUUID, setActivePreviewUUID] = useState<string>(cardSuiteUUIDList[0]);

  const coupleNames = extractCoupleNames(weddingDetails);
  const date = weddingDetails.date !== null ? moment(weddingDetails.date) : null;
  let substitutions = {};
  if (coupleNames !== null) {
    substitutions = {
      PrimaryFirstName: coupleNames.owner_first_name,
      PrimaryFirstNameInitial: coupleNames.owner_first_name[0],
      PrimaryLastName: coupleNames.owner_last_name,
      PrimaryLastNameInitial: coupleNames.owner_last_name[0],
      PartnerFirstName: coupleNames.partner_first_name,
      PartnerFirstNameInitial: coupleNames.partner_first_name[0],
      PartnerLastName: coupleNames.partner_last_name,
      PartnerLastNameInitial: coupleNames.partner_last_name[0],
      Month: date?.format('MMMM'),
      MonthNumber: date?.format('M'),
      MonthNumber2Digits: date?.format('MM'),
      DayNumber: date?.format('D'),
      DayNumber2Digits: date?.format('DD'),
      DayOfWeek: date?.format('dddd'),
      Year: date?.format('GGGG'),
      Year2Digits: date?.format('GG'),
    };
  }

  // TODO: either add dependencies or set a "templatesFetched" state variable
  useEffectOnce(() => {
    dispatch(
      fetchDefaultTemplates(
        cardSuiteUUIDList,
        cardSuiteDetailsSet,
        substitutions,
        designCustomizationsSet
      )
    );
  });

  if (Object.keys(designCustomizationsSet).length === 0 || Object.keys(templatesSet).length === 0)
    return null;

  const activePreviewCustomizations: DesignCustomizations =
    designCustomizationsSet[activePreviewUUID];

  const setColor = (color: any) => {
    if (color.value === activePreviewCustomizations.color) return;

    const { variation } = activePreviewCustomizations;
    const cardSuiteDetails = cardSuiteDetailsSet[activePreviewUUID];

    const selectedOptions = { ...variation.option_values, color: color.value };
    const newVariation = lookupSelectedVariation(selectedOptions, cardSuiteDetails, isMagnet);

    dispatch(fetchTemplate(activePreviewUUID, cardSuiteDetails, color.value, substitutions));
    dispatch(
      updateDesignCustomizations(activePreviewUUID, { color: color.value, variation: newVariation })
    );
  };

  const tabItems = cardSuiteUUIDList.map(cardSuiteUUID => {
    const customizations = designCustomizationsSet[cardSuiteUUID];
    const details = cardSuiteDetailsSet[cardSuiteUUID];
    const isLPOrFoil = details.letterpress || details.foil;
    const availableColors = details.mediums.paper.available_options.color.options;
    const hasCustomPhoto = details.has_custom_photo;
    const page = templatesSet[cardSuiteUUID].pages[0];

    return {
      id: cardSuiteUUID,
      text: (
        <div className={styles.tabCardPreview}>
          <Preview customizations={customizations} page={page} width={53} />
        </div>
      ),
      panelContent: (
        <div className={styles.content}>
          <div className={styles.previewWrapper}>
            <Preview customizations={customizations} page={page} width={isMobile ? 270 : 330} />
          </div>
          <div className={styles.details}>
            {hasCustomPhoto && !isLPOrFoil ? (
              <Uploadcare
                cardSuiteUUID={cardSuiteUUID}
                cardSuiteUUIDList={cardSuiteUUIDList}
                previousUploadcareFile={customizations.uploadcareFile}
              />
            ) : (
              <div className={styles.text}>
                {details.foil
                  ? 'This photo cannot be customized for your sample because it is a Foil design.'
                  : 'This card design does not have a photo.'}
              </div>
            )}
          </div>
          <div className={styles.colorPicker}>
            <ProductColorPicker
              colors={availableColors}
              activeColor={customizations.color}
              maxColorsToShow={isLPOrFoil ? 1 : 50}
              onSelect={setColor}
              swatchWidth={24}
            />
          </div>
        </div>
      ),
      onClick: () => setActivePreviewUUID(cardSuiteUUID),
    };
  });

  const nextBtnText = 'Next: Customize Paper';
  const nextStep = () => dispatch(setStepKey(STEPS.CUSTOMIZE));
  const prevStep = () => dispatch(setStepKey(STEPS.NAMES));

  return (
    <>
      <Form.Header
        currentStep={2}
        description="If you haven't found the perfect photo yet, don't stress! This step is optional."
        steps={4}
        title="Customize Your Design"
      />
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} />
      </div>
      <Form.ButtonWrapper>
        <Form.BackButton handleClick={prevStep} />
        <Form.Button btnText={nextBtnText} handleClick={nextStep} />
      </Form.ButtonWrapper>
    </>
  );
};

export default PhotoStep;
