import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DetailBox from '../DetailBox/DetailBox';
import ToggleList from '../ToggleList/ToggleList';
import { CARD_TYPES } from '../../../constants/CardTypes';
import styles from './styles.module.less';

const OptionList = ({
  options,
  currentSelection,
  onToggle,
  optionType,
  isMagnet,
  isLinkHidden,
  styleOverrides,
  titleLink,
  titleLinkAction,
  cardType,
  hoverDisabled,
  tooltipMessage,
  variation,
}) => {
  const [currentHover, setCurrentHover] = useState(null);
  const isMenuCard = cardType === CARD_TYPES.menu;

  const updateCurrentHover = useCallback(
    newVal => {
      if (!hoverDisabled) {
        setCurrentHover(newVal);
      }
    },
    [hoverDisabled]
  );

  function detailSelector(newVal) {
    return options.find(option => {
      return option.value === newVal;
    });
  }

  return (
    <React.Fragment>
      <div className={styles.optionListWrapper}>
        {isMenuCard && optionType === 'size' ? null : (
          <div className={styles.optionList}>
            <DetailBox
              show={!!currentHover}
              details={detailSelector(currentHover)}
              isMagnet={isMagnet}
              cardType={cardType}
            />
          </div>
        )}
      </div>
      <ToggleList
        currentSelection={currentSelection}
        options={options}
        onHover={updateCurrentHover}
        onToggle={onToggle}
        isMagnet={isMagnet}
        styleOverrides={styleOverrides}
        tooltipMessage={tooltipMessage}
        variation={variation}
      />
      {optionType === 'paper-type' && !hoverDisabled ? (
        <div className={styles.optionListMobileDetails}>
          <DetailBox
            details={detailSelector(currentSelection.value)}
            isLinkHidden={isLinkHidden}
            titleLink={titleLink}
            titleLinkAction={titleLinkAction}
            show
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

OptionList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      hide: PropTypes.bool,
      subTitle: PropTypes.string,
      iconEnabled: PropTypes.node,
      iconDisabled: PropTypes.node,
    })
  ),
  currentSelection: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onToggle: PropTypes.func,
  optionType: PropTypes.string,
  isMagnet: PropTypes.bool,
  titleLink: PropTypes.string,
  titleLinkAction: PropTypes.func,
  cardType: PropTypes.string,
  tooltipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  variation: PropTypes.string,
};

OptionList.defaultProps = {
  onToggle: () => {},
};

export default OptionList;
