import onboardTrackingConstants from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';

const { BUSINESS_UNIT, COMPONENT } = onboardTrackingConstants;

export function mapComponentToBusinessUnit(businessUnitComponent: string): string {
  switch (businessUnitComponent) {
    case COMPONENT.REGISTRY:
      return BUSINESS_UNIT.WEDDING_REGISTRY;
    case COMPONENT.WEDDING_WEBSITE:
    case COMPONENT.WEDDING_CHECKLIST:
    case COMPONENT.WEDDING_PLANNING:
    case COMPONENT.WEDDING_GUEST_LIST:
    case COMPONENT.WEDDINGS:
      return BUSINESS_UNIT.WEDDINGS;
    case COMPONENT.INVITATIONS:
    case COMPONENT.SAVE_THE_DATE:
    case COMPONENT.CHANGE_THE_DATE:
    case COMPONENT.THANK_YOU_CARDS:
    case COMPONENT.DAY_OF_PAPER:
    case COMPONENT.HOLIDAY:
    case COMPONENT.WEDDING_SHOWER_INVITATION:
      return BUSINESS_UNIT.INVITATIONS;
    case COMPONENT.DIGITAL_SAVE_THE_DATE:
      return BUSINESS_UNIT.DIGITAL_SAVE_THE_DATE;
    default:
      return BUSINESS_UNIT.WEDDINGS;
  }
}

export function mapCardTypeToBusinessUnitComponent(cardType: string): string {
  switch (cardType) {
    case 'DIGITAL SAVE_THE_DATE':
      return COMPONENT.DIGITAL_SAVE_THE_DATE;
    case 'SAVE_THE_DATE':
      return COMPONENT.SAVE_THE_DATE;
    case 'CHANGE_THE_DATE':
      return COMPONENT.CHANGE_THE_DATE;
    case 'THANK_YOU':
      return COMPONENT.THANK_YOU_CARDS;
    case 'MENU':
    case 'PROGRAM':
    case 'TABLE_NUMBER':
    case 'PLACE':
      return COMPONENT.DAY_OF_PAPER;
    case 'HOLIDAY':
      return COMPONENT.HOLIDAY;
    case 'WEDDING_SHOWER_INVITATION':
      return COMPONENT.WEDDING_SHOWER_INVITATION;
    default:
      return COMPONENT.INVITATIONS;
  }
}

export function preventAndPersist(event: React.SyntheticEvent): void {
  event.preventDefault();
  event.persist();
}

export function isShortWindow(): boolean {
  if (typeof window !== 'undefined') return window.matchMedia(`(max-height: 610px)`).matches;
  return false;
}

// Lifted from web-registry onboarding, modified for web/wedding onboarding
export function getBackBtnRoute(): string {
  let route = '/'; // default to homepage
  if (localStorage.getItem('destinationUrl')) {
    // destinationUrl is set (like in web-regsitry), use that if avaliable
    const routeFromStorage = localStorage.getItem('destinationUrl');
    if (typeof routeFromStorage === 'string') {
      route = routeFromStorage;
      localStorage.removeItem('destinationUrl');
    }
  } else if (document.referrer) {
    // see if we got to onboarding via clicking a link, if yes go back to there
    route = document.referrer;
  }
  return route;
}

export const handleOwnerNameRedirect = (): void => {
  window.location.href = getBackBtnRoute();
};

export function checkOwnerNameOnboard(): boolean {
  return /\?question=NAME/.test(window.location.search);
}

export default {
  checkOwnerNameOnboard,
  getBackBtnRoute,
  handleOwnerNameRedirect,
  mapCardTypeToBusinessUnitComponent,
  mapComponentToBusinessUnit,
  preventAndPersist,
};
