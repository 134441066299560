import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { mapCardTypeToBusinessUnitComponent } from 'components/onboard/helpers';
import { RootState } from 'reducers/index';
import { PdpData } from './reducer';

const getPdpState = (state: RootState) => state.cards.pdp;

// Card suite. Will only change when suite is fetched.
export const getActiveUUID = createSelector(getPdpState, pdpState => pdpState.activeUUID);
export const getInitialSuiteUUID = createSelector(
  getPdpState,
  pdpState => pdpState.initialActiveUUID
);
export const getPageData = createSelector(
  getPdpState,
  pdpState => (pdpState.data || {}) as Partial<PdpData>
);
export const getCardType = createSelector(getPageData, pageData => pageData.cardType);
export const getProjectUUID = createSelector(getPdpState, pdpState => pdpState.projectUUID);
export const getCardFamily = createSelector(getPageData, pageData => pageData.family);
export const getCardCollaborator = createSelector(getPageData, pageData => pageData.collaborator);
export const getCardOrientation = createSelector(getPageData, pageData => pageData.orientation);
export const getIsStampFoilCard = createSelector(getPageData, pageData => pageData.isStampFoilCard);
export const getImages = createSelector(getPdpState, pdpState => pdpState.images);
export const getIsBusy = createSelector(getPdpState, pdpState => pdpState.busy);
export const getActiveVariation = createSelector(getPdpState, pdpState => pdpState.activeVariation);
export const getIsCustomFoilAvailable = createSelector(
  getPdpState,
  pdpState => pdpState.data && pdpState.data.isCustomFoilAvailable
);
export const getBusinessUnitComponent = createSelector(getCardType, cardType =>
  mapCardTypeToBusinessUnitComponent(cardType || '')
);

// Current selection. Will change whenever selected options change.
export const getActiveMediumKey = createSelector(getPdpState, pdpState => pdpState.activeMediumKey);
export const getActiveMediumData = createSelector(
  getPdpState,
  pdpState => pdpState.activeMediumData
);
export const getActiveSelection = createSelector(
  getPdpState,
  pdpState => pdpState.activeMediumSelection
);
export const getActiveSelectionColor = createSelector(
  getActiveSelection,
  activeSelection => activeSelection?.color || ''
);
export const getActiveSelectionSize = createSelector(
  getActiveSelection,
  activeSelection => activeSelection?.size || null
);
export const getActiveVariationOptionValues = createSelector(
  getActiveVariation,
  activeVariation => activeVariation.option_values
);
export const getActiveEarliestArrivalDate = createSelector(
  getActiveVariation,
  activeVariation => activeVariation.earliest_arrival_date
);
export const getActiveVariationUUID = createSelector(
  getActiveVariation,
  activeVariation => activeVariation.uuid
);
export const getAvailableOptions = createSelector(
  getActiveMediumData,
  activeMediumData => activeMediumData.available_options
);
export const getIsMagnet = createSelector(
  getActiveMediumKey,
  activeMediumKey => activeMediumKey === 'magnet'
);
export const getQuantity = createSelector(getPdpState, pdpState => pdpState.quantity);
export const getCostData = createSelector(getPdpState, pdpState => pdpState.cost);

export const getVariationTemplates = createSelector(
  getPdpState,
  pdpState => pdpState.variationTemplates
);

export const getDefaultOptionValues = createSelector(getPdpState, pdpState => {
  const default_option_values = _get(
    pdpState,
    ['mediums', 'paper', 'default_variation_options'],
    {}
  );
  return default_option_values || {};
});

export const getShowOnboardModal = createSelector(
  getPdpState,
  pdpState => pdpState.showOnboardModal
);
