import ApiService from '../../util/api';

// Action types
export const ActionTypes = {
  REQUEST_GUESTS_WITH_CUSTOM_NOTE: 'zola/cards/project/REQUEST_GUESTS_WITH_CUSTOM_NOTE',
  RECEIVE_GUESTS_WITH_CUSTOM_NOTE: 'zola/cards/project/RECEIVE_GUESTS_WITH_CUSTOM_NOTE',
  RECEIVE_GUEST_NOTE: 'zola/cards/project/RECEIVE_GUEST_NOTE',
  UPDATE_GUEST_NOTE: 'zola/cards/project/UPDATE_GUEST_NOTE',
  DELETE_GUEST_NOTE: 'zola/cards/project/DELETE_GUEST_NOTE',
  REQUEST_GUEST_DEFAULT_NOTE: 'zola/cards/project/REQUEST_GUEST_DEFAULT_NOTE',
  // RECEIVE_GUEST_DEFAULT_NOTE: 'zola/cards/project/RECEIVE_GUEST_DEFAULT_NOTE',
  SET_ACTIVE_GUEST_GROUP_UUID: 'zola/cards/project/SET_ACTIVE_GUEST_GROUP_UUID',
  RESET_GUESTS_NOTES: 'zola/cards/project/RESET_GUESTS_NOTES',
};

// Actions
function requestGuestsWithCustomNote() {
  return {
    type: ActionTypes.REQUEST_GUESTS_WITH_CUSTOM_NOTE,
  };
}

function updateGuestContent() {
  return {
    type: ActionTypes.UPDATE_GUEST_NOTE,
  };
}

function deleteGuestContent() {
  return {
    type: ActionTypes.DELETE_GUEST_NOTE,
  };
}

function requestGuestDefaultNote() {
  return {
    type: ActionTypes.REQUEST_GUEST_DEFAULT_NOTE,
  };
}

function receiveGuestsWithCustomNote(json) {
  return {
    type: ActionTypes.RECEIVE_GUESTS_WITH_CUSTOM_NOTE,
    payload: json,
  };
}

function receiveGuestContent(json, type) {
  return {
    type: ActionTypes.RECEIVE_GUEST_NOTE,
    payload: {
      ...json,
      type,
    },
  };
}

export function resetGuestsNotes() {
  return {
    type: ActionTypes.RESET_GUESTS_NOTES,
  };
}

export function fetchGuestsWithCustomNote(projectUUID) {
  return dispatch => {
    dispatch(requestGuestsWithCustomNote());

    return fetch(`/web-api/v2/card-project/guestdata/project/${projectUUID}`, {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuestsWithCustomNote(json)))
      .catch(() => undefined);
  };
}

export function fetchGuestDefaultNote(guestUUID) {
  return dispatch => {
    dispatch(requestGuestDefaultNote());

    return fetch(`/web-api/v2/card-project/guestdata/default/${guestUUID}`, {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuestContent(json, 'defaultNote')));
  };
}

export function createGuestCustomNote(guestUUID, content, projectId) {
  return dispatch => {
    const requestBody = {
      data: content,
      project_uuid: projectId,
    };
    return ApiService.post(`/web-api/v2/card-project/guestdata/${guestUUID}`, requestBody).then(
      json => {
        dispatch(receiveGuestContent(json, 'customNote'));
        return json;
      }
    );
  };
}

export function updateGuestCustomNote(guestCustomizationUUID, content, projectId) {
  return dispatch => {
    dispatch(updateGuestContent());

    const requestBody = {
      data: content,
      project_uuid: projectId,
    };
    return ApiService.put(
      `/web-api/v2/card-project/guestdata/${guestCustomizationUUID}`,
      requestBody
    ).then(json => {
      dispatch(receiveGuestContent(json, 'customNote'));
      return json;
    });
  };
}

export function deleteGuestCustomNote(guestCustomizationUUID, guestGroupUUID) {
  return dispatch => {
    dispatch(deleteGuestContent());

    return ApiService.delete(
      `/web-api/v2/card-project/guestdata/${guestCustomizationUUID}`,
      {},
      {},
      {},
      'text'
    ).then(() =>
      dispatch(
        receiveGuestContent(
          {
            guest_group_uuid: guestGroupUUID,
            data: null,
          },
          'customNote'
        )
      )
    );
  };
}

export function setActiveGuestGroupUUID(guestGroupUUID) {
  return {
    type: ActionTypes.SET_ACTIVE_GUEST_GROUP_UUID,
    payload: {
      guestGroupUUID,
    },
  };
}
