import { CARD_TYPES } from 'cards/components/CardListing/types';
import type { FilterCardType } from 'favorites/types';
import FeatureFlags from 'util/featureFlags';

export const DEFAULT_PARTNER = {
  firstName: 'Someone',
  lastName: 'Special',
};

export const ACCOUNT_ERROR_MESSAGES = {
  invalidEmail: 'Please enter a correct email address',
  inUseEmail: 'The provided email is in use.',
  invalidPassword: 'Your password must be at least 8 characters long',
  emptyWeddingDetails: 'Please enter your first and last name',
  emptyPartnerWeddingDetails: "Please enter your partner's first and last name",
  invalidWeddingDate: 'Please make sure your wedding date is valid',
  invalidWeddingDetails: 'Please make sure your wedding details are valid.',
};

type Filter = {
  type: FilterCardType;
  label: string;
};

export const FILTER_TYPE_ALL = 'all';

export const CARD_TYPE_FILTERS: Array<Filter> = FeatureFlags.get('paperAddOns')
  ? [{ type: FILTER_TYPE_ALL, label: 'All' }]
  : [
      { type: FILTER_TYPE_ALL, label: 'All' },
      { type: CARD_TYPES.std, label: 'Save the dates' },
      { type: CARD_TYPES.dstd, label: 'Digital save the dates' },
      { type: CARD_TYPES.invitation, label: 'Invitations' },
      { type: CARD_TYPES.weddingShower, label: 'Shower invitations' },
      { type: CARD_TYPES.enclosure, label: 'Enclosure cards' },
      { type: CARD_TYPES.place, label: 'Place cards' },
      { type: CARD_TYPES.tableNumber, label: 'Table numbers' },
      { type: CARD_TYPES.menu, label: 'Menus' },
      { type: CARD_TYPES.program, label: 'Programs' },
      { type: CARD_TYPES.thankyou, label: 'Thank you cards' },
      { type: CARD_TYPES.holiday, label: 'Holiday cards' },
    ];

export interface Data {
  name: string;
  url: string;
}

export const CARD_TYPE_DATA: { [key: string]: Data } = {
  [FILTER_TYPE_ALL]: { name: 'Invites and paper', url: '/wedding-planning/save-the-date/shop' },
  [CARD_TYPES.std]: { name: 'Save the Dates', url: '/wedding-planning/save-the-date/shop' },
  [CARD_TYPES.dstd]: {
    name: 'Digital Save the Dates',
    url: '/wedding-planning/digital/save-the-date/shop',
  },
  [CARD_TYPES.invitation]: { name: 'Invitations', url: '/wedding-planning/invitations/shop' },
  [CARD_TYPES.ctd]: { name: 'Change the dates', url: '/wedding-planning/change-the-date/shop' },
  [CARD_TYPES.enclosure]: { name: 'Enclosure cards', url: '/wedding-planning/enclosures/shop' },
  [CARD_TYPES.menu]: { name: 'Menus', url: '/wedding-planning/menus/shop' },
  [CARD_TYPES.program]: { name: 'Programs', url: '/wedding-planning/programs/shop' },
  [CARD_TYPES.holiday]: { name: 'Holiday cards', url: '/wedding-planning/holiday-cards/shop' },
  [CARD_TYPES.thankyou]: {
    name: 'Thanks you cards',
    url: '/wedding-planning/thank-you-cards/shop',
  },
  [CARD_TYPES.place]: { name: 'Place cards', url: '/wedding-planning/place-cards/shop' },
  [CARD_TYPES.tableNumber]: { name: 'Table numbers', url: '/wedding-planning/table-numbers/shop' },
  [CARD_TYPES.weddingShower]: {
    name: 'Shower invitations',
    url: '/wedding-planning/bridal-shower/shop',
  },
  [CARD_TYPES.paperAddOns]: {
    name: 'Paper add-ons',
    url: '/wedding-planning/paper-add-ons/shop',
  },
};
