import { useState, useEffect } from 'react';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';

export const isPostAuthNav3Desktop = (userContext?: UserContext | null) => {
  if (!userContext || typeof window === 'undefined') {
    return false;
  }
  return !userContext.is_guest && isDesktop();
};

export const useIsPostAuthNav3Desktop = (userContext?: UserContext | null) => {
  const [postAuthNav3DesktopEnabled, setPostAuthNav3DesktopEnabled] = useState(false);
  useEffect(() => {
    if (userContext) {
      setPostAuthNav3DesktopEnabled(isPostAuthNav3Desktop(userContext));
    }
  }, [userContext]);
  return postAuthNav3DesktopEnabled;
};

export const isPostAuthNav3Mobile = (userContext?: UserContext | null): boolean => {
  if (!userContext || typeof window === 'undefined') {
    return false;
  }
  return !userContext.is_guest && !isDesktop();
};

export const useIsPostAuthNav3Mobile = (userContext?: UserContext | null) => {
  const [postAuthNav3MobileEnabled, setPostAuthNav3MobileEnabled] = useState(false);
  useEffect(() => {
    if (userContext) {
      setPostAuthNav3MobileEnabled(isPostAuthNav3Mobile(userContext));
    }
  }, [userContext]);
  return postAuthNav3MobileEnabled;
};

// Things with this check we'll mostly want to apply for good with experiment cleanup
export const isPostAuthNav3MobileOrDesktop = (userContext?: UserContext | null): boolean => {
  return isPostAuthNav3Mobile(userContext) || isPostAuthNav3Desktop(userContext);
};

export const useIsPostAuthNav3MobileOrDesktop = (userContext?: UserContext | null): boolean => {
  const [postAuthNav3MobileOrDesktopEnabled, setPostAuthNav3MobileOrDesktopEnabled] = useState(
    false
  );
  useEffect(() => {
    if (userContext) {
      setPostAuthNav3MobileOrDesktopEnabled(isPostAuthNav3MobileOrDesktop(userContext));
    }
  }, [userContext]);
  return postAuthNav3MobileOrDesktopEnabled;
};
