import moment from 'moment-timezone';
import { ORIENTATION } from 'cards/types';
import { DEFAULT_PARTNER } from 'paper/utils/constants';

export const extractWeddingNames = weddingDetails => {
  // * Validation
  let names = { errors: [] };

  if (!(weddingDetails.fullName && weddingDetails.fullName.trim().split(' ').length > 1)) {
    names = Object.assign(names, { errors: [...names.errors, 'userDetails'] });
  }

  if (
    !weddingDetails.hasNoPartner &&
    !(weddingDetails.partnerFullName && weddingDetails.partnerFullName.trim().split(' ').length > 1)
  ) {
    names = Object.assign(names, { errors: [...names.errors, 'partnerDetails'] });
  }

  if (names.errors.length > 0) {
    return names;
  }

  // * Split and default partner values
  const [ownerUserFirstName, ...ownerUserLastNames] = weddingDetails.fullName.split(' ');
  const [partnerUserFirstName, ...partnerUserLastNames] = weddingDetails.partnerFullName
    ? weddingDetails.partnerFullName.split(' ')
    : [DEFAULT_PARTNER.firstName, DEFAULT_PARTNER.lastName];

  return {
    owner_first_name: ownerUserFirstName,
    owner_last_name: ownerUserLastNames.join(' '),
    partner_first_name: partnerUserFirstName,
    partner_last_name: partnerUserLastNames.join(' '),
  };
};

export const parseWeddingDate = date => {
  if (!date) {
    return null;
  }

  if (date.length !== 10) {
    return null;
  }

  const parsedDate = moment(
    `${date.substring(0, 4)}-${date.substring(5, 7)}-${date.substring(8, 10)}`
  );

  if (!parsedDate.isValid()) {
    return null;
  }

  return parsedDate;
};

export const getUserTimeZone = () => moment.tz.guess();

export const getAccountCreationTimestamp = () => moment.utc().format();

const PX_TO_PT_RATIO = 16 / 12;

export const ptToPx = pt => pt * PX_TO_PT_RATIO;

export const pxToPt = px => px / PX_TO_PT_RATIO;

// * character_spacing value is inDesign Tracking value where 1 unit is 1/1000th of an em space
export const getLetterSpacingInPt = (fontSize, characterSpacing) =>
  fontSize * 0.001 * characterSpacing;

export const isIeEleven = () => {
  const ua = window.navigator.userAgent;
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) === 11;
  }
  return false;
};

export const srcsetHelper = (imageUrl, width1x) =>
  `${imageUrl}?w=${width1x} 1x, ${imageUrl}?w=${width1x * 2} 2x, ${imageUrl}?w=${width1x * 3} 3x`;

export const getImageUrl = imageId => {
  // resolve image host based on environment
  const env = (typeof window !== 'undefined' && window.zola && window.zola.env) || 'production';
  let hostname = '//images.zola.com';
  if (env === 'development') {
    // hostname = 'http://127.0.0.1:9100/v2/image'; // point directly at svc-image in development if pointing at development services
    hostname = '//stage-images.zola.com'; // use me if pointing services at stage (npm run dev-staging)
  } else if (env === 'staging') {
    hostname = '//stage-images.zola.com';
  }
  return `${hostname}/${imageId}`;
};

export const getTextWidthInPt = (text = '', { ...fontStyles }) => {
  const {
    refFontStyle = 'normal',
    refFontWeight = 'regular',
    refFontSize = 12, // pt
    refLineHeight = 12, // pt
    refFontFamily = 'Arial',
  } = fontStyles;

  // * Create Canvas and get its context
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // * Assign the proper font formatting
  context.font = `${refFontStyle} ${refFontWeight} ${ptToPx(refFontSize)}px/${ptToPx(
    refLineHeight
  )}px ${refFontFamily}`;

  // * Get the metrics of the specified text (in px)
  const metrics = context.measureText(text);

  return Math.ceil(pxToPt(metrics.width));
};

export const getCapitalized = (word = '') =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export const getSizesPerOrientation = (width, height, isFolded, orientation) => {
  let sizes = [];

  switch (orientation) {
    case ORIENTATION.portrait:
      sizes = [isFolded ? width / 2 : width, height];
      break;

    case ORIENTATION.landscape:
      sizes = [width, isFolded ? height / 2 : height];
      break;

    default:
      sizes = [width, height];
      break;
  }

  return sizes;
};

export const getElementOffsetPerOrientation = (
  offsetY,
  offsetX,
  pageHeight,
  pageWidth,
  isFoldable,
  pageOrientation
) => {
  let offsets = [];

  switch (pageOrientation) {
    case ORIENTATION.portrait:
      offsets = [isFoldable ? offsetX - pageWidth : offsetX, offsetY];
      break;

    case ORIENTATION.landscape:
      offsets = [offsetX, isFoldable ? offsetY - pageHeight : offsetY];
      break;

    default:
      offsets = [offsetX, offsetY];
      break;
  }

  return offsets;
};

export const getDeltasPerOrientation = ({
  elemX,
  elemY,
  pageHeight,
  pageWidth,
  deltaLeft,
  deltaTop,
  isFoldable,
  pageOrientation,
}) => {
  let deltas = []; // [X, Y]
  const defaultDeltaX = elemX + pxToPt(deltaLeft);
  const defaultDeltaY = elemY + pxToPt(deltaTop);
  const getComputedDelta = (elemPos, pageDim, deltaPos) => elemPos - pageDim + pxToPt(deltaPos);

  switch (pageOrientation) {
    case ORIENTATION.portrait:
      deltas = [
        isFoldable ? getComputedDelta(elemX, pageWidth, deltaLeft) : defaultDeltaX,
        defaultDeltaY,
      ];
      break;

    case ORIENTATION.landscape:
      deltas = [
        defaultDeltaX,
        isFoldable ? getComputedDelta(elemY, pageHeight, deltaTop) : defaultDeltaY,
      ];
      break;

    default:
      deltas = [defaultDeltaX, defaultDeltaY];
      break;
  }

  return deltas;
};

export const getTxtCoordinatesPerOrientation = (
  width,
  height,
  txtWidth,
  txtHeight,
  orientation,
  isFolded
) => {
  let coordinates = [];
  const defaultX = Math.floor((width - txtWidth) / 2);
  const defaultY = Math.floor((height - txtHeight) / 2);

  switch (orientation) {
    case ORIENTATION.portrait:
      coordinates = [isFolded ? Math.floor(width - width / 4 - txtWidth / 2) : defaultX, defaultY];
      break;

    case ORIENTATION.landscape:
      coordinates = [
        defaultX,
        isFolded ? Math.floor(height - height / 4 - txtHeight / 2) : defaultY,
      ];
      break;

    default:
      coordinates = [defaultX, defaultY];
      break;
  }

  return coordinates;
};

export const getOffsetGuideProps = ({
  elemX,
  elemY,
  elemWidth,
  elemHeight,
  pageHeight,
  pageWidth,
  deltaLeft,
  deltaTop,
  deltaRight,
  deltaBottom,
  isFoldable,
  pageOrientation,
}) => {
  let props = {};
  const measureTopBase = elemY + pxToPt(deltaTop);
  const measureRightBase = pageWidth - (elemX + elemWidth) + pxToPt(deltaRight);
  const measureBottomBase = pageHeight - (elemY + elemHeight);
  const measureLeftBase = elemX + pxToPt(deltaLeft);

  switch (pageOrientation) {
    case ORIENTATION.portrait:
      props = {
        style: {
          topOffset: {
            top: `-${elemY}pt`,
            left: `${(ptToPx(elemWidth) + (deltaLeft - deltaRight)) / 2}px`,
            height: `${elemY + pxToPt(deltaTop)}pt`,
          },
          bottomOffset: {
            bottom: `-${pageHeight - (elemY + elemHeight)}pt`,
            left: `${(ptToPx(elemWidth) + (deltaLeft - deltaRight)) / 2}px`,
            height: `${pageHeight - (elemY + elemHeight) + pxToPt(deltaBottom)}pt`,
          },
          leftOffset: {
            top: `${(ptToPx(elemHeight) + (deltaTop - deltaBottom)) / 2}px`,
            left: isFoldable ? `-${elemX - pageWidth}pt` : `-${elemX}pt`,
            width: isFoldable
              ? `${elemX + pxToPt(deltaLeft) - pageWidth}pt`
              : `${elemX + pxToPt(deltaLeft)}pt`,
          },
          rightOffset: {
            top: `${(ptToPx(elemHeight) + (deltaTop - deltaBottom)) / 2}px`,
            right: isFoldable
              ? `-${pageWidth - (elemX + elemWidth) + pageWidth}pt`
              : `-${pageWidth - (elemX + elemWidth)}pt`,
            width: isFoldable
              ? `${pageWidth - (elemX + elemWidth) + pxToPt(deltaRight) + pageWidth}pt`
              : `${pageWidth - (elemX + elemWidth) + pxToPt(deltaRight)}pt`,
          },
        },
        measures: {
          top: Math.round(measureTopBase),
          bottom: Math.round(measureBottomBase + pxToPt(deltaBottom)),
          left: isFoldable ? Math.round(measureLeftBase - pageWidth) : Math.round(measureLeftBase),
          right: isFoldable
            ? Math.round(measureRightBase + pageWidth)
            : Math.round(measureRightBase),
        },
      };
      break;

    case ORIENTATION.landscape:
      props = {
        style: {
          topOffset: {
            top: isFoldable ? `-${elemY - pageHeight}pt` : `-${elemY}pt`,
            left: `${(ptToPx(elemWidth) + (deltaLeft - deltaRight)) / 2}px`,
            height: isFoldable
              ? `${elemY + pxToPt(deltaTop) - pageHeight}pt`
              : `${elemY + pxToPt(deltaTop)}pt`,
          },
          bottomOffset: {
            bottom: isFoldable
              ? `-${pageHeight - (elemY + elemHeight) + pageHeight}pt`
              : `-${pageHeight - (elemY + elemHeight)}pt`,
            left: `${(ptToPx(elemWidth) + (deltaLeft - deltaRight)) / 2}px`,
            height: isFoldable
              ? `${pageHeight - (elemY + elemHeight) + pxToPt(deltaBottom) + pageHeight}pt`
              : `${pageHeight - (elemY + elemHeight) + pxToPt(deltaBottom)}pt`,
          },
          leftOffset: {
            top: `${(ptToPx(elemHeight) + (deltaTop - deltaBottom)) / 2}px`,
            left: `-${elemX}pt`,
            width: `${elemX + pxToPt(deltaLeft)}pt`,
          },
          rightOffset: {
            top: `${(ptToPx(elemHeight) + (deltaTop - deltaBottom)) / 2}px`,
            right: `-${pageWidth - (elemX + elemWidth)}pt`,
            width: `${pageWidth - (elemX + elemWidth) + pxToPt(deltaRight)}pt`,
          },
        },
        measures: {
          top: isFoldable ? Math.round(measureTopBase - pageHeight) : Math.round(measureTopBase),
          bottom: isFoldable
            ? Math.round(measureBottomBase + pxToPt(deltaBottom) + pageHeight)
            : Math.round(measureBottomBase + pxToPt(deltaBottom)),
          left: Math.round(measureLeftBase),
          right: Math.round(measureRightBase),
        },
      };
      break;

    default:
      props = {
        style: {
          topOffset: {
            top: `-${elemY}pt`,
            left: `${(ptToPx(elemWidth) + (deltaLeft - deltaRight)) / 2}px`,
            height: `${elemY + pxToPt(deltaTop)}pt`,
          },
          bottomOffset: {
            bottom: `-${pageHeight - (elemY + elemHeight)}pt`,
            left: `${(ptToPx(elemWidth) + (deltaLeft - deltaRight)) / 2}px`,
            height: `${pageHeight - (elemY + elemHeight) + pxToPt(deltaBottom)}pt`,
          },
          leftOffset: {
            top: `${(ptToPx(elemHeight) + (deltaTop - deltaBottom)) / 2}px`,
            left: `-${elemX}pt`,
            width: `${elemX + pxToPt(deltaLeft)}pt`,
          },
          rightOffset: {
            top: `${(ptToPx(elemHeight) + (deltaTop - deltaBottom)) / 2}px`,
            right: `-${pageWidth - (elemX + elemWidth)}pt`,
            width: `${pageWidth - (elemX + elemWidth) + pxToPt(deltaRight)}pt`,
          },
        },
        measures: {
          top: Math.round(measureTopBase),
          bottom: Math.round(measureBottomBase + pxToPt(deltaBottom)),
          left: Math.round(measureLeftBase),
          right: Math.round(measureRightBase),
        },
      };
      break;
  }

  return props;
};

export const getFilteredShape = searchFilters => searchFilters?.silhouettes?.[0] || 'square';

export const getActiveThumbnailUrl = (activeColorObject, searchFilters) =>
  Object.keys(activeColorObject?.plp_front_image_uuids).length > 0
    ? getImageUrl(activeColorObject?.plp_front_image_uuids?.[getFilteredShape(searchFilters)])
    : activeColorObject?.plp_front_image_url;
