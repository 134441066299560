import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.less';

const ToggleButton = ({
  active,
  icon,
  iconStyle,
  label,
  subLabel,
  onClick,
  onHover,
  value,
  metadata,
  disabled,
  styleOverrides,
  type,
  tooltipMessage,
  variation,
  narrow, // * TODO: delete after PDP 2.0 is launched
}) => {
  function ToggleClick() {
    return disabled ? null : onClick({ type, label, value, metadata });
  }

  // * When tooltip message is an object, we test the option property value first, then the default
  // * if none exist, we assume tooltip message is passed as a string
  const getTooltipMessage = optionValue =>
    tooltipMessage?.[optionValue] || tooltipMessage?.default || tooltipMessage || null;

  return (
    <div data-tooltip={disabled ? getTooltipMessage(value) : null} className="no-tooltip-on-mobile">
      <button
        type="button"
        aria-pressed={active}
        onClick={ToggleClick}
        onMouseEnter={() => onHover(value)}
        onMouseLeave={() => onHover(null)}
        className={cx(
          styles.pdpToggleButton,
          'no-tooltip-on-mobile',
          {
            [styles.active]: active,
            [styles.disabled]: disabled,
            [styles.narrow]: narrow,
            [styles[variation]]: !!variation,
          },
          styleOverrides && styleOverrides.pdpToggleButton
        )}
        data-testid={label?.toLowerCase()}
      >
        {!!icon && (
          <img
            src={icon}
            alt={label}
            className={cx(styles.pdpToggleButtonIcon, 'zolaicon')}
            style={iconStyle}
          />
        )}
        {!icon && type === 'color' && !metadata?.showRainbowSwatch && (
          <span className={styles.pdpToggleButtonColorIcon} style={{ background: `#${value}` }} />
        )}
        {!icon && type === 'color' && metadata?.showRainbowSwatch && (
          <span
            className={cx(styles.pdpToggleButtonColorIcon, styles.rainbow, 'with-tooltip')}
            data-tooltip="Choose your own colors to get even more custom"
          />
        )}
        <div className={cx(styles.pdpToggleButtonLabel, { [styles.active]: active })}>
          <span>{label}</span>
          {subLabel ? (
            <span
              className={cx(
                styles.pdpToggleButtonSublabel,
                {
                  [styles.active]: active,
                  [styles.disabled]: disabled,
                },
                styleOverrides && styleOverrides.pdpToggleButtonSublabel
              )}
            >{`(${subLabel})`}</span>
          ) : null}
        </div>
      </button>
    </div>
  );
};

ToggleButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconStyle: PropTypes.shape({}),
  label: PropTypes.string,
  metadata: PropTypes.shape({}),
  subLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  tooltipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  narrow: PropTypes.bool,
  styleOverrides: PropTypes.shape({}),
};

ToggleButton.defaultProps = {
  active: false,
  disabled: false,
  onClick: () => {},
  onHover: () => {},
  narrow: false,
};

export default ToggleButton;
