import { CARD_TYPES } from 'cards/components/CardListing/types';

export const getTypeFromBackendType = (backEndType: string): CARD_TYPES => {
  switch (backEndType) {
    case 'HOLIDAY':
      return CARD_TYPES.holiday;
    case 'THANK_YOU':
      return CARD_TYPES.thankyou;
    case 'SAVE_THE_DATE':
      return CARD_TYPES.std;
    case 'CHANGE_THE_DATE':
      return CARD_TYPES.ctd;
    case 'INVITATION':
      return CARD_TYPES.invitation;
    case 'DETAILS':
      return CARD_TYPES.enclosure;
    case 'MENU':
      return CARD_TYPES.menu;
    case 'PROGRAM':
      return CARD_TYPES.program;
    case 'PLACE':
      return CARD_TYPES.place;
    case 'WEDDING_SHOWER_INVITATION':
      return CARD_TYPES.weddingShower;
    default:
      return backEndType as CARD_TYPES;
  }
};

export const getBackendTypeFromType = (cardType: CARD_TYPES): string => {
  return cardType === CARD_TYPES.enclosure ? 'DETAILS' : cardType;
};
