export const getPlaceCardAlignment = (
  verticalAlignmentValue: string,
  horizontalAlignmentValue: string
) => {
  const baseStyle = { display: 'flex' };
  let verticalAlignmentStyle = {};
  let horizontalAlignmentStyle = {};

  switch (verticalAlignmentValue) {
    case 'top':
      verticalAlignmentStyle = { alignItems: 'start' };
      break;

    case 'bottom':
      verticalAlignmentStyle = { alignItems: 'end' };
      break;

    case 'middle':
      verticalAlignmentStyle = { alignItems: 'center' };
      break;

    default:
      break;
  }

  switch (horizontalAlignmentValue) {
    case 'left':
      horizontalAlignmentStyle = { justifyContent: 'start' };
      break;

    case 'right':
      horizontalAlignmentStyle = { justifyContent: 'end' };
      break;

    case 'center':
      horizontalAlignmentStyle = { justifyContent: 'center' };
      break;

    default:
      break;
  }

  return { ...baseStyle, ...verticalAlignmentStyle, ...horizontalAlignmentStyle };
};

interface Alignment {
  vertical_alignment?: string;
  horizontal_alignment?: string;
}

interface ElementAlignment extends Alignment  {
  font?: Alignment;
}

export const getElementAlignment = <T extends ElementAlignment = Alignment>(element: T): [verticalAlignment: string, horizontalAlignment: string] => {
  const verticalAlignment = (
    element?.font?.vertical_alignment ||
    element?.vertical_alignment ||
    'TOP'
  ).toLowerCase();

  const horizontalAlignment = (
    element?.font?.horizontal_alignment ||
    element?.horizontal_alignment ||
    'LEFT'
  ).toLowerCase();

  return [verticalAlignment, horizontalAlignment];
};
