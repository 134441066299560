import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

// components
import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';

// actions
import { setStepKey } from 'cards/reducers/samplesFlow/actions';

// selectors
import { getOrderNumber } from 'cards/selectors/samplesFlow';

// helpers
import { useIsMobile } from 'paper/hooks/isMobile';

// icons
import Interstitial1Icon from 'assets/images/invitations/single-sample-modal/interstitial-1.svg';
import Interstitial2Icon from 'assets/images/invitations/single-sample-modal/interstitial-2.svg';
import Interstitial3Icon from 'assets/images/invitations/single-sample-modal/interstitial-3.svg';
import { STEPS } from '../../constants';

import styles from './styles.module.less';

const delay = (duration: number) => new Promise(resolve => setTimeout(resolve, duration));

const INTERSTITIAL_DELAY = 2000;

interface InterstitialProps {
  icon: string;
  shouldDisplay: boolean;
  text: string;
}

const Interstitial = ({ icon, shouldDisplay, text }: InterstitialProps): JSX.Element => (
  <div className={cx(styles.interstitial, { [styles.hidden]: !shouldDisplay })}>
    <div className={styles.interstitialIcon}>
      <img src={icon} alt={text} />
    </div>
    {text}
  </div>
);

const ProcessingOrder = (): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const orderNumber = useSelector(getOrderNumber);

  const [interstitialStep, setInterstitialStep] = useState(0);

  useEffect(() => {
    delay(INTERSTITIAL_DELAY)
      .then(() => setInterstitialStep(interstitialStep + 1))
      .then(() => delay(INTERSTITIAL_DELAY))
      .catch(() => undefined);
  }, [interstitialStep]);

  useEffect(() => {
    if (orderNumber && interstitialStep > 3) {
      dispatch(setStepKey(STEPS.CONFIRMATION));
    }
  }, [orderNumber, interstitialStep, setStepKey]);

  return (
    <>
      <div className={styles.processingWrapper}>
        <ZolaLogo className={styles.processingIcon} type="heart" />
        Processing your order...
      </div>
      {!isMobile && (
        <div className={styles.interstitialWrapper}>
          <Interstitial
            icon={Interstitial1Icon}
            shouldDisplay={interstitialStep >= 1}
            text="30% off when you place a full order"
          />
          <Interstitial
            icon={Interstitial2Icon}
            shouldDisplay={interstitialStep >= 2}
            text="Free guest addressing and envelopes"
          />
          <Interstitial
            icon={Interstitial3Icon}
            shouldDisplay={interstitialStep >= 3}
            text="Matching invitation suite for all designs"
          />
        </div>
      )}
    </>
  );
};

export default ProcessingOrder;
