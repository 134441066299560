import { BASE_QUANTITY_RANGE, ADDITIONAL_QUANTITIES } from 'cards/constants/Cards';
import { CARD_TYPES } from 'cards/constants/CardTypes';

type QuantityRangeWithProof = {
  label: string;
  value: number;
};

export const getQuantityRange = (cardType: string): Array<number> => {
  if (cardType === CARD_TYPES.place) {
    return [...ADDITIONAL_QUANTITIES, ...BASE_QUANTITY_RANGE].sort((a, b) => a - b);
  }

  /**
   * Table number cards have a different quantity range
   * Min Qty supported: 10 minimum, increments of 1 after that
   * Max Qty supported: 90
   */
  if (cardType === CARD_TYPES.tableNumber) {
    const [MinQuantity, MaxQuantity] = [10, 90];
    return Array.from(new Array(MaxQuantity - MinQuantity + 1)).map((_, i) => i + MinQuantity);
  }

  return BASE_QUANTITY_RANGE;
};

export const getQuantityRangeWithProof = (cardType: string): Array<QuantityRangeWithProof> => {
  const options = getQuantityRange(cardType).map(quantity => ({
    label: quantity.toString(),
    value: +quantity,
  }));

  // Table number doesn't have a proof option
  if (cardType === CARD_TYPES.tableNumber) {
    return options;
  }

  options.unshift({ label: '1 (Proof)', value: 1 });

  return options;
};
