import UploadClient from '@uploadcare/upload-client';
import _get from 'lodash/get';
import type { WUploadedImageView } from '@zola/svc-web-api-ts-client';
import { FileInfo } from '@uploadcare/react-widget';
import ApiService from '../../../../util/api';
import { uploadcareKey } from '../constants';

const uploadcareClient = new UploadClient();

const FORMAT_TO_MIME_TYPE = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  SVG: 'image/svg+xml',
  TIFF: 'image/tiff',
  WEBP: 'image/webp',
  BMP: 'image/bmp',
};
const getMimeTypeFromFormat = (uploadcareFile: FileInfo) =>
  FORMAT_TO_MIME_TYPE[_get(uploadcareFile, 'imageInfo.format') as keyof typeof FORMAT_TO_MIME_TYPE];

const MAX_COPY_ATTEMPTS = 2;

// TODO: The retry here is a temporary fix until svc-image implements it. Remove it here after.
export const copyUploadcareFileToZola = (
  uploadcareFile: FileInfo,
  preserveInUC: boolean,
  attemptNb = 0,
  daysToKeepInUploadCare?: number
): Promise<WUploadedImageView> => {
  return ApiService.post('/web-api/v1/image/uploadcare-save', {
    url: uploadcareFile.cdnUrl,
    contentType: uploadcareFile.mimeType || getMimeTypeFromFormat(uploadcareFile),
    preserveInUC,
    daysToKeepInUploadCare,
  }).then(json => {
    const data = json && json.data;
    if (!data && attemptNb < MAX_COPY_ATTEMPTS) {
      return copyUploadcareFileToZola(
        uploadcareFile,
        preserveInUC,
        attemptNb + 1,
        daysToKeepInUploadCare
      );
    }

    return data;
  });
};

export const copyUploadcareCdnUrlToZola = (
  cdnUrl: string,
  preserveInUC: boolean,
  daysToKeepInUploadCare: number
): Promise<WUploadedImageView> => {
  return ApiService.post('/web-api/v1/image/uploadcare-save', {
    url: cdnUrl,
    preserveInUC,
    daysToKeepInUploadCare,
  }).then(json => json.data);
};

export const copyUploadcareFileToZolaLite = (uploadcareUuids: string[], preserveInUC = true) => {
  return ApiService.post('/web-api/v1/image/uploadcare-save-lite', {
    uploadcareUuids,
    preserveInUC,
  })
    .then(json => {
      const data = json && json.data;
      return data;
    })
    .catch(() => undefined);
};

export const fetchJobForCopyUploadcareFileToZolaLite = ({ jobUuid }: { jobUuid: number }) => {
  return ApiService.get(`/web-api/v1/image/uploadcare-save-lite/job/${jobUuid}`)
    .then(json => {
      const data = json && json.data;
      return data;
    })
    .catch(() => undefined);
};

export const copyUploadcareFileGroupToZola = (fileGroupId: number, preserveInUC = true) => {
  return ApiService.post('/web-api/v1/image/uploadcare-group-save', {
    groupId: fileGroupId,
    preserveInUC,
  }).then(json => json.data);
};

export const fetchImageMetadata = (zolaImageId: string) => {
  return ApiService.get(`/web-api/v1/image/metadata/${zolaImageId}`).then(json => json.data);
};

export const fetchBatchImageMetadata = (imageUUIDs: number[]) => {
  return ApiService.post(`/web-api/v1/image/metadata/list`, { imageUUIDs }).then(json => json.data);
};

export const fetchOriginalMetadata = (uploadcareImageId: string | null) => {
  return ApiService.get(`/web-api/v1/image/uploadcare-original/${uploadcareImageId}`)
    .then(json => json.data)
    .catch(() => ({})); // Return empty data if there is an error fetching the original
};

// Uploadcare allows us to fetch the original file data, but doesn't return modifier info,
// We must therefore manually add modiifiers to the returned uploadcare file.
export const fetchUploadcareFile = (cdnUrl: string) => {
  const urlMatches = cdnUrl.match(/ucarecdn\.com\/([^/]+)\/(-.+)?/);
  const cropMatches = cdnUrl.match(/-\/crop\/(\d+)x(\d+)\/(\d+),(\d+)/);

  const id = urlMatches?.[1] ? urlMatches[1] : '';
  const cdnUrlModifiers = urlMatches?.[2] ? urlMatches[2] : '';
  const crop = cropMatches
    ? {
        width: cropMatches[1],
        height: cropMatches[2],
        x: cropMatches[3],
        y: cropMatches[4],
      }
    : null;

  // Doesn't actually reupload: Simply returns existing upload's data.

  return uploadcareClient
    .uploadFile(id, {
      publicKey: uploadcareKey,
    })
    .then(uploadcareFile => {
      return {
        ...uploadcareFile,
        cdnUrl,
        cdnUrlModifiers,
        crop,
        originalImageInfo: uploadcareFile.imageInfo,
      };
    });
};
