import type { CardTemplateBackgroundView } from '@zola/svc-web-api-ts-client';
import qs from 'query-string';
import { AppThunk } from 'reducers/index';
import { CARD_TYPES } from 'cards/components/CardListing/types';
import { CARD_SIZES } from 'cards/constants/Cards';
import { ORIENTATION } from 'cards/types';
import { getBackendTypeFromType } from 'cards/util/cardTypes';
import ApiService from '../../util/api';

export const RECEIVE_BACKGROUND_ASSETS = 'RECEIVE_BACKGROUND_ASSETS';

type ReceiveBackgroundAssetsAction = {
  type: typeof RECEIVE_BACKGROUND_ASSETS;
  payload: CardTemplateBackgroundView[];
};

const receiveBackgroundAssets = (
  assets: CardTemplateBackgroundView[]
): ReceiveBackgroundAssetsAction => ({
  type: RECEIVE_BACKGROUND_ASSETS,
  payload: assets,
});

export type ExtraCustomizationActions = ReceiveBackgroundAssetsAction;

export function fetchBackgroundAssets(
  cardType: CARD_TYPES,
  size: keyof typeof CARD_SIZES,
  orientation: ORIENTATION,
  variationUuid?: string
): AppThunk<Promise<void>> {
  return dispatch => {
    const backendCardType = getBackendTypeFromType(cardType);
    const queryString = {
      cardType: backendCardType,
      size,
      orientation,
      variationUuid,
    };
    return ApiService.get(
      `/web-api/v2/card-template/background-assets?${qs.stringify(queryString)}`
    ).then(data => {
      dispatch(receiveBackgroundAssets(data || []));
    });
  };
}
