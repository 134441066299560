import moment, { Moment } from 'moment';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { setGTMLoggedIn } from '@zola-helpers/client/dist/es/tracking/googleTagManager';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import { trackUserCreated } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { trackUserCreated as trackUserCreatedTrackingContracts } from '@zola/tracking-contracts/src/tracking';

import { getCookie, setAllStorage } from 'util/storage';
import { BusinessUnitComponent } from 'components/onboard/types';
import { AppDispatch, RootState } from 'reducers/index';
import type { WOnboardingResult, WQuestionnaireView } from '@zola/svc-web-api-ts-client';
import * as ActionType from './types/OnboardActionTypes';

import { identifyUserSailthru, getUserContext } from './UserActions';

export function checkingSlug() {
  return {
    type: ActionType.CHECK_SLUG_AVAILABLE,
  };
}

export function requestUpdateSlug() {
  return {
    type: ActionType.REQUEST_UPDATE_SLUG,
  };
}

export function receiveSlugAvailability(response: boolean) {
  return {
    type: ActionType.SLUG_AVAILABILITY_RECEIVED,
    payload: response,
  };
}

export function receiveSlugSuggestions(response: Array<string>) {
  return {
    type: ActionType.SLUG_SUGGESTIONS_RECEIVED,
    payload: response,
  };
}

export function receiveUpdatedSlug() {
  return {
    type: ActionType.RECEIVE_UPDATED_SLUG,
  };
}

export function setSubmitDelayActive(bool: boolean) {
  return {
    type: ActionType.UPDATE_SUBMIT_DELAY_ACTIVE,
    payload: bool,
  };
}

export function checkSlugAvailability(slug: string) {
  return (dispatch: AppDispatch) => {
    dispatch(checkingSlug());
    return ApiService.get<boolean>(`/web-api/v1/wedding/slug/${slug}/available`)
      .then(response => dispatch(receiveSlugAvailability(response)))
      .then(action => action);
  };
}

// FIXME dont use any
export function getSlugSuggestions(suggestionData: any) {
  const {
    primaryFirstName,
    partnerFirstName,
    primaryLastName,
    partnerLastName,
    eventDate,
  } = suggestionData;
  return (dispatch: AppDispatch) => {
    dispatch(checkingSlug());
    const epoch = moment(eventDate).valueOf() || '';
    const slugData = {
      owner_first_name: primaryFirstName,
      owner_last_name: primaryLastName,
      partner_first_name: partnerFirstName,
      partner_last_name: partnerLastName,
      event_date: epoch,
    };
    return ApiService.post<Array<string>>(
      '/web-api/v1/wedding/slug/suggestions',
      slugData
    ).then(slugSuggestions => dispatch(receiveSlugSuggestions(slugSuggestions)));
  };
}

// TODO: maybe move slug actions into another file becuase not really related to onboarding anymore;
// FIXME dont use any
export function updateSlug(request: any) {
  return (dispatch: AppDispatch) => {
    dispatch(requestUpdateSlug());
    return ApiService.put<void>('/web-api/v1/wedding/slug', request).then(() => {
      dispatch(receiveUpdatedSlug());
    });
  };
}

// Onboard Actions related to new (Perfect) Onboard flow.

function requestSignup() {
  return {
    type: ActionType.REQUEST_SIGNUP,
  };
}

function onboardingCompleted(json: WOnboardingResult) {
  return {
    type: ActionType.ONBOARDING_COMPLETED,
    payload: json,
  };
}

export function updateWeddingDate(date: Moment) {
  // FIXME this right?
  return {
    type: ActionType.UPDATE_WEDDING_DATE,
    payload: {
      date,
    },
  };
}

export function setBusinessUnitComponent(component: BusinessUnitComponent) {
  return {
    type: ActionType.SET_BUSINESS_UNIT_COMPONENT,
    payload: component,
  };
}

// FIXME dont use any
export function submitOnboarding(data: any) {
  return (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const { userContext } = getState().user; // Do this up here so that you do not have to modify userContext after dispatching any account creation events.
    const isGuest = userContext && userContext.is_guest;
    dispatch(requestSignup());

    const onboardEndpoint = isGuest
      ? '/web-api/v1/onboard/submit'
      : '/web-api/v1/onboard/submit/existing';

    return ApiService.post<WOnboardingResult>(onboardEndpoint, data).then(json => {
      setAllStorage('zolaUserCreatedAt', new Date().toISOString());
      setGTMLoggedIn();
      dispatch(onboardingCompleted(json));
      return dispatch(getUserContext()).then(() => {
        const { user } = getState();
        const latestUserContext = user.userContext || {};

        if (isGuest) {
          // track user created
          let cookie: string | null = getCookie('pkey');
          if (cookie === '') {
            cookie = null;
          }
          trackUserCreated(latestUserContext.email, cookie);

          // Tracking Contracts
          if (data.flow) {
            const { business_unit, business_category, flow_run_id } = data.flow;
            const normalizedPkey = cookie || undefined;
            trackUserCreatedTrackingContracts({
              business_unit,
              business_category,
              promotion_key: normalizedPkey,
              flow_run_id,
            });
          }
        }
        return identifyUserSailthru(latestUserContext.email, isGuest ? data.user : undefined);
      });
    });
  };
}

export function setOnboardCityState(cityStateString: string | null) {
  return {
    type: ActionType.SET_CITY_STATE,
    payload: cityStateString,
  };
}

export function setOnboardWeddingYear(year?: number | null) {
  // fixme this right?
  return {
    type: ActionType.SET_WEDDING_YEAR,
    payload: year,
  };
}

export function setOnboardWeddingMonth(month: number | null) {
  return {
    type: ActionType.SET_WEDDING_MONTH,
    payload: month,
  };
}

function requestQuestions() {
  return {
    type: ActionType.REQUEST_QUESTIONS,
  };
}

function receiveQuestions(json: WQuestionnaireView) {
  return {
    type: ActionType.RECEIVE_QUESTIONS,
    payload: json,
  };
}

export function fetchQuestions(flowName: string) {
  return (dispatch: AppDispatch): Promise<WQuestionnaireView | void> => {
    dispatch(requestQuestions());
    return ApiService.get<WQuestionnaireView>(`/web-api/v1/onboard/get?flow=${flowName}`)
      .then(json => {
        dispatch(receiveQuestions(json));
        return json;
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({ headline: 'Error in getting questions for onboarding.' })
        );
      });
  };
}

export function fetchQuestionnaire(version: number, businessUnit: string) {
  return (dispatch: AppDispatch) => {
    dispatch(requestQuestions());
    return ApiService.get<WQuestionnaireView>(
      `/web-api/v1/onboard/get?version=${version}&businessUnit=${businessUnit}`
    )
      .then(json => {
        dispatch(receiveQuestions(json));
        return json;
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({ headline: 'Error in getting questions for onboarding.' })
        );
      });
  };
}

// FIXME What type is answers?
export function submitQuestionnaireAnswers(questionKey: string, answers: any) {
  return {
    type: ActionType.SUBMIT_QUESTIONNAIRE_ANSWERS,
    payload: { questionKey, values: answers },
  };
}

export function clearQuestionnaireAnswer(questionKey: string) {
  return {
    type: ActionType.CLEAR_QUESTIONNAIRE_ANSWER,
    payload: { questionKey },
  };
}
