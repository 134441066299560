import _isEmpty from 'lodash/isEmpty';
import type {
  UserActionTypes,
  AuthStatusType,
} from '@zola-helpers/client/dist/types/redux/user/userActionTypes';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import {
  requestUser,
  receiveUser,
  updateUserStatus as updateUserStatusApi,
  identifyUserSailthru,
  fetchUserContext,
  identifyGuestUser,
  receiveIdentifyGuestUser,
  identifyUser,
  receiveIdentifyUser,
} from '@zola-helpers/client/dist/es/redux/user/userActions';

import { AppThunk } from 'reducers/index';
import * as userSelectors from '../selectors/user/userSelectors';

import * as ActionType from './types/UserActionTypes';

function requestUserStatus(): UserActionTypes {
  return {
    type: ActionType.REQUEST_USER_STATUS,
  };
}

function receiveUserStatus(response: { status: AuthStatusType }): UserActionTypes {
  return {
    type: ActionType.RECEIVE_USER_STATUS,
    payload: response,
  };
}

export function onIdentifyUser(forceIdentify?: boolean): AppThunk<void> {
  return (dispatch, getState) => {
    const userContext = getState().user.userContext || {};
    const hasIdentified = getState().user.hasIdentified || false;
    if (!userContext.is_guest && (!hasIdentified || forceIdentify)) {
      identifyUser({ userContext, hasIdentified, forceIdentify });
      dispatch(receiveIdentifyUser());
    }
  };
}

export function onIdentifyGuestUser(forceIdentify?: boolean): AppThunk<void> {
  return (dispatch, getState) => {
    const userContext = getState().user.userContext || {};
    const { hasGuestIdentified } = getState().user;
    identifyGuestUser({ userContext, hasGuestIdentified, forceIdentify });
    dispatch(receiveIdentifyGuestUser());
  };
}

function getUserContext(): AppThunk<Promise<UserContext>> {
  return (dispatch): Promise<UserContext> => {
    dispatch(requestUser());
    return fetchUserContext().then((json: UserContext) => {
      dispatch(receiveUser(json));
      dispatch(onIdentifyUser());
      return json;
    });
  };
}

function updateUserStatus(): AppThunk<Promise<AuthStatusType>> {
  return (dispatch): Promise<AuthStatusType> => {
    dispatch(requestUserStatus());
    return updateUserStatusApi().then((json: { status: AuthStatusType }) => {
      dispatch(receiveUserStatus(json));
      return json.status;
    });
  };
}

export const maybeFetchUser = (forceFetch = false): AppThunk<Promise<UserContext>> => (
  dispatch,
  getState
) => {
  const state = getState();
  const userInStore = userSelectors.getUserContext(state);
  const isFetchingUser = userSelectors.getIsFetchingUser(state);

  if (forceFetch || (_isEmpty(userInStore) && !isFetchingUser)) {
    return dispatch(getUserContext());
  }
  return Promise.resolve(userInStore);
};

export type { UserContext };
export {
  requestUser,
  receiveUser,
  requestUserStatus,
  receiveUserStatus,
  identifyUserSailthru,
  getUserContext,
  updateUserStatus,
};
