import { BusinessUnitComponent } from 'components/onboard/types';
import { EventConfig } from '@zola/tracking-contracts/src/configs';

export const BusinessCategoryComponentMap: Record<
  BusinessUnitComponent,
  EventConfig['business_category']
> = {
  CHANGE_THE_DATE: 'CHANGE_THE_DATE',
  DAY_OF_PAPER: 'DAY_OF',
  DIGITAL_SAVE_THE_DATE: 'DIGITAL_SAVE_THE_DATE',
  HOLIDAY: 'HOLIDAY',
  INSPIRATION: 'UNATTRIBUTED',
  INVITATIONS: 'INVITATIONS',
  MARKETPLACE: 'UNATTRIBUTED',
  REGISTRY: 'UNATTRIBUTED',
  SAVE_THE_DATE: 'SAVE_THE_DATE',
  STORE: 'UNATTRIBUTED',
  THANK_YOU_CARDS: 'THANK_YOU',
  WEDDINGS: 'UNATTRIBUTED',
  WEDDING_ALBUM: 'UNATTRIBUTED',
  WEDDING_CHECKLIST: 'UNATTRIBUTED',
  WEDDING_GUEST_LIST: 'UNATTRIBUTED',
  WEDDING_PLANNING: 'UNATTRIBUTED',
  WEDDING_WEBSITE: 'UNATTRIBUTED',
};
