import fetch from 'isomorphic-fetch';
import _reduce from 'lodash/reduce';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import { toastsActions } from '@zola-helpers/client';

import * as ActionType from './types/GuestActionTypes';

export function resetGuests() {
  return {
    type: ActionType.RESET_GUESTS,
  };
}

export function reinitialize() {
  return {
    type: ActionType.REINITIALIZE,
  };
}

function requestGuest() {
  return {
    type: ActionType.REQUEST_GUEST,
  };
}

function receiveGuest(json) {
  return {
    type: ActionType.RECEIVE_GUEST,
    payload: {
      guest: json,
    },
  };
}

export function createGuest() {
  return {
    type: ActionType.CREATE_GUEST,
  };
}

export function onSetAltActive(altActiveGlobal) {
  return {
    type: ActionType.UPDATE_ALT_ACTIVE,
    payload: altActiveGlobal,
  };
}

function updateGuestGroup() {
  return { type: ActionType.UPDATE_GUEST_GROUP };
}

export function selectGuest(guestId) {
  return {
    type: ActionType.SELECT_GUESTS,
    payload: {
      id: guestId,
    },
  };
}

export function unselectGuest() {
  return {
    type: ActionType.UNSELECT_GUESTS,
  };
}

export function changeSeatedGuest(json) {
  return {
    type: ActionType.CHANGE_SEATED_GUESTS,
    payload: {
      seatedGuests: json,
    },
  };
}

export function expandGuestGroup(guestGroupId) {
  return {
    type: ActionType.EXPAND_GUEST_GROUP,
    payload: {
      id: guestGroupId,
    },
  };
}

export function collapseGuestGroup(guestGroupId) {
  return {
    type: ActionType.COLLAPSE_GUEST_GROUP,
    payload: {
      id: guestGroupId,
    },
  };
}

function toggleFacetValueAction(facetKey, valueKey) {
  return {
    type: ActionType.TOGGLE_FACET_VALUE,
    payload: {
      facetKey,
      valueKey,
    },
  };
}

function requestGuests() {
  return {
    type: ActionType.REQUEST_GUESTS,
  };
}

function receiveGuests(json) {
  return {
    type: ActionType.RECEIVE_GUESTS,
    payload: json,
  };
}

export function selectEventTab(id) {
  return {
    type: ActionType.EVENT_TAB_SELECTED,
    payload: {
      id,
    },
  };
}

export function updateSorting(sortByValue, ascending) {
  return {
    type: ActionType.UPDATE_SORTING,
    payload: {
      sortByValue,
      ascending,
    },
  };
}

export function fetchGuestList() {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByNameAsc: getState().guestList.guests.sortByNameAsc,
    };
    return fetch('/web-api/v1/guestgroup/list/all', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)));
  };
}

export function fetchEnvelopeList() {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByNameAsc: getState().guestList.guests.sortByNameAsc,
    };
    return fetch('/web-api/v1/guestgroup/list/envelopes', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)));
  };
}

export function fetchEnvelopeListV2() {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByValue: getState().guestList.guests.sortByValue,
      ascending: getState().guestList.guests.ascending,
    };
    return fetch('/web-api/v2/guestgroup/list/envelopes', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)))
      .catch(() => undefined);
  };
}

export function fetchRsvpList() {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByNameAsc: getState().guestList.guests.sortByNameAsc,
    };
    return fetch('/web-api/v1/guestgroup/list/rsvps', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)));
  };
}

export function fetchRsvpOverviewList() {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByNameAsc: getState().guestList.guests.sortByNameAsc,
    };
    const { activeEventTab } = getState().guestList.guests;

    if (activeEventTab) {
      return fetch(`/web-api/v1/guestgroup/list/rsvps/${activeEventTab}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedFacetValues),
      })
        .then(response => response.json())
        .then(json => dispatch(receiveGuests(json)));
    }
    return fetch('/web-api/v1/guestgroup/list/rsvps/overview', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)));
  };
}

export function fetchRsvpEventList(eventId) {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByNameAsc: getState().guestList.guests.sortByNameAsc,
    };
    return fetch(`/web-api/v1/guestgroup/list/rsvps/${eventId}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)));
  };
}

export function fetchBuildList() {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByNameAsc: getState().guestList.guests.sortByNameAsc,
    };
    return fetch('/web-api/v1/guestgroup/list/build', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)));
  };
}

export function getGuestGroup(guestGroupId) {
  return dispatch => {
    dispatch(requestGuest());
    return fetch(`/web-api/v1/guestgroup/id/${guestGroupId}`, {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuest(json)));
  };
}

function normalizeGuestInputs(guest) {
  const mealOptionId = guest.meal_option_id ? parseInt(guest.meal_option_id, 10) : undefined;
  const prefix = guest.prefix || undefined;
  const suffix = guest.suffix || undefined;

  if (guest.name_unknown) {
    return Object.assign({}, guest, {
      prefix: undefined,
      first_name: undefined,
      family_name: undefined,
      suffix: undefined,
      meal_option_id: mealOptionId,
    });
  }
  return Object.assign({}, guest, { prefix, suffix, meal_option_id: mealOptionId });
}

// Maybe this should just be on web-api side...
export function formatGuestGroupRequest(guestGroupRequest) {
  const formattedRequest = Object.assign({}, guestGroupRequest, {
    invited: guestGroupRequest.invited === 'true' || guestGroupRequest.invited === true,
    save_the_date_sent:
      guestGroupRequest.save_the_date_sent === 'true' ||
      guestGroupRequest.save_the_date_sent === true,
    invitation_sent:
      guestGroupRequest.invitation_sent === 'true' || guestGroupRequest.invitation_sent === true,
  });

  // Make sure relationship_type is set and in an acceptable configuration and add common fields to primary only
  const guests = guestGroupRequest.guests.map((guest, index) => {
    switch (index) {
      case 0:
        return Object.assign({}, normalizeGuestInputs(guest), { relationship_type: 'PRIMARY' });
      case 1: {
        const relationshipType =
          guest.relationship_type === 'PRIMARY' ? 'PARTNER' : guest.relationship_type;
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: relationshipType,
        });
      }
      default:
        return Object.assign({}, normalizeGuestInputs(guest), { relationship_type: 'CHILD' });
    }
  });

  // Return the final request object
  return Object.assign({}, formattedRequest, { guests });
}

/* Marked for Deprecation: submitGuestGroupRequest */
function submitGuestGroupRequest(type, guestGroupRequest) {
  return dispatch => {
    if (type === 'ADD') {
      dispatch(createGuest());
    } else if (type === 'UPDATE') {
      dispatch(updateGuestGroup());
    }
    const finalRequest = formatGuestGroupRequest(guestGroupRequest);
    return fetch('/web-api/v1/guestgroup', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(finalRequest),
    }).then(response => response.json());
  };
}
/* Marked for Deprecation: addGuestGroup */
export function addGuestGroup(createGuestGroupRequest) {
  const { length } = createGuestGroupRequest.guests;
  const headline = `${length} Guest${length > 1 ? 's' : ''} Added`;
  return dispatch =>
    dispatch(submitGuestGroupRequest('ADD', createGuestGroupRequest)).then(json => {
      dispatch(toastsActions.positive({ headline }));
      return json;
    });
}
/* Marked for Deprecation: editGuestGroup */
export function editGuestGroup(updateGuestGroupRequest) {
  const { length } = updateGuestGroupRequest.guests;
  const headline = `Guest${length > 1 ? 's' : ''} Updated`;
  return dispatch =>
    dispatch(submitGuestGroupRequest('UPDATE', updateGuestGroupRequest)).then(json => {
      dispatch(toastsActions.positive({ headline }));
      return json;
    });
}

export function toggleInvited(guestGroupIds, isInvited, updateListFn) {
  return dispatch => {
    const body = {
      guest_group_ids: guestGroupIds,
      invited: isInvited,
    };

    return fetch('/web-api/v1/guestgroup/invited', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(() => dispatch(updateListFn()));
  };
}

export function toggleInvitationSent(guestGroupIds, isSent, updateListFn) {
  return dispatch => {
    const body = {
      guest_group_ids: guestGroupIds,
      invitation_sent: isSent,
    };

    return fetch('/web-api/v1/guestgroup/invitation', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(() => dispatch(updateListFn()));
  };
}

export function toggleSaveTheDateSent(guestGroupIds, isSent, updateListFn) {
  return dispatch => {
    const body = {
      guest_group_ids: guestGroupIds,
      save_the_date_sent: isSent,
    };

    return fetch('/web-api/v1/guestgroup/save-the-date', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(() => dispatch(updateListFn()));
  };
}

export function toggleAllGuestsFacetValue(facetKey, valueKey) {
  return dispatch => {
    dispatch(toggleFacetValueAction(facetKey, valueKey));
    dispatch(fetchGuestList());
  };
}

export function toggleEnvelopeFacetValue(facetKey, valueKey) {
  return dispatch => {
    dispatch(toggleFacetValueAction(facetKey, valueKey));
    dispatch(fetchEnvelopeList());
  };
}

export function toggleEnvelopeFacetValueV2(facetKey, valueKey) {
  return dispatch => {
    dispatch(toggleFacetValueAction(facetKey, valueKey));
    dispatch(fetchEnvelopeListV2());
  };
}

export function toggleRsvpFacetValue(facetKey, valueKey) {
  return dispatch => {
    dispatch(toggleFacetValueAction(facetKey, valueKey));
    dispatch(fetchRsvpList());
  };
}

export function toggleRsvpFacetValueV2(facetKey, valueKey) {
  return dispatch => {
    dispatch(toggleFacetValueAction(facetKey, valueKey));
    dispatch(fetchRsvpOverviewList());
  };
}

export function toggleBuildFacetValue(facetKey, valueKey) {
  return dispatch => {
    dispatch(toggleFacetValueAction(facetKey, valueKey));
    dispatch(fetchBuildList());
  };
}

export function resetFacetValues() {
  return {
    type: ActionType.RESET_FACET_VALUES,
  };
}

export function sendMessageCollectAddress(sendCollectAddressMessageRequest) {
  return dispatch =>
    fetch('/web-api/v1/guestgroup/message/collect_address', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendCollectAddressMessageRequest),
    })
      .then(() => dispatch(fetchGuestList()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Request Sent' })));
}

export function sendMessage(sendMessageRequest) {
  return dispatch =>
    fetch('/web-api/v1/guestgroup/message/custom', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendMessageRequest),
    })
      .then(() => dispatch(fetchGuestList()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Message Sent' })));
}

export function copyLink(guestGroupUuid) {
  return dispatch => {
    return ApiService.put('/web-api/v2/guestgroup/copy-link', { guestGroupUuid })
      .then(json => dispatch(receiveGuest(json)))
      .then(() => dispatch(toastsActions.positive({ headline: 'Copied Link' })))
      .catch(() => {
        dispatch(toastsActions.negative({ headline: 'Error copying link' }));
      });
  };
}

export function deleteGuestGroups(guestGroupIds) {
  return (dispatch, getState) => {
    const { byId } = getState().guestList.guests;
    const deletedGuests = _reduce(guestGroupIds, (acc, id) => acc + byId[id].guests.length, 0);
    const headline = `${deletedGuests} Guest${deletedGuests > 1 ? 's' : ''} Deleted`;
    return fetch('/web-api/v1/guestgroup/delete', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ guest_group_ids: guestGroupIds }),
    }).then(() => dispatch(toastsActions.positive({ headline })));
  };
}

export function toggleSortByNameAsc() {
  return { type: ActionType.TOGGLE_SORT_BY_NAME_ASC };
}

function formatAddGuestGroupRequest(guestGroupRequest) {
  const formattedRequest = Object.assign({}, guestGroupRequest, {
    invited: guestGroupRequest.invited === 'true' || guestGroupRequest.invited === true,
    save_the_date_sent:
      guestGroupRequest.save_the_date_sent === 'true' ||
      guestGroupRequest.save_the_date_sent === true,
    invitation_sent:
      guestGroupRequest.invitation_sent === 'true' || guestGroupRequest.invitation_sent === true,
  });

  // Make sure relationship_type is set and in an acceptable configuration and add common fields to primary only
  const guests = guestGroupRequest.guests.map((guest, index) => {
    const eventInvitations = guestGroupRequest.event_invitations.reduce((result, invite) => {
      if (invite.checked === true) {
        result.push({
          id: invite.id,
          event_id: invite.event_id,
          rsvp_type: invite.rsvp_type,
          meal_option_id: invite.meal_option_id,
          rsvp_at: invite.rsvp_at,
        });
      }
      return result;
    }, []);

    switch (index) {
      case 0:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'PRIMARY',
          event_invitations: eventInvitations,
        });
      case 1: {
        const relationshipType =
          guest.relationship_type === 'PRIMARY' ? 'PARTNER' : guest.relationship_type;
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: relationshipType,
          event_invitations: eventInvitations,
        });
      }
      default:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'CHILD',
          event_invitations: eventInvitations,
        });
    }
  });

  return Object.assign({}, formattedRequest, { guests });
}

function formatUpdateGuestGroupRequest(guestGroupRequest) {
  let formattedRsvpQuestionAnswers;
  if (guestGroupRequest.rsvp_question_answers?.orderedIds) {
    formattedRsvpQuestionAnswers = guestGroupRequest.rsvp_question_answers.orderedIds.map(id => {
      const rsvpObject = guestGroupRequest.rsvp_question_answers[id];
      const { answer } = rsvpObject;
      if (answer === '') {
        return { ...rsvpObject, answer: null };
      }
      return rsvpObject;
    });
  }

  const formattedRequest = Object.assign({}, guestGroupRequest, {
    invited: guestGroupRequest.invited === 'true' || guestGroupRequest.invited === true,
    save_the_date_sent:
      guestGroupRequest.save_the_date_sent === 'true' ||
      guestGroupRequest.save_the_date_sent === true,
    invitation_sent:
      guestGroupRequest.invitation_sent === 'true' || guestGroupRequest.invitation_sent === true,
    rsvp_question_answers: formattedRsvpQuestionAnswers,
    state_province:
      guestGroupRequest.state_province === '' ? null : guestGroupRequest.state_province,
  });

  // Make sure relationship_type is set and in an acceptable configuration and add common fields to primary only
  const guests = guestGroupRequest.guests.map((guest, index) => {
    let eventInvitations;
    if (guest.event_invitations?.orderedIds) {
      eventInvitations = guest.event_invitations.orderedIds
        .map(eventId => guest.event_invitations[eventId])
        .filter(eventInvitation => eventInvitation.checked === true);
    }

    switch (index) {
      case 0:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'PRIMARY',
          event_invitations: eventInvitations,
        });
      case 1: {
        const relationshipType =
          guest.relationship_type === 'PRIMARY' ? 'PARTNER' : guest.relationship_type;
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: relationshipType,
          event_invitations: eventInvitations,
        });
      }
      default:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'CHILD',
          event_invitations: eventInvitations,
        });
    }
  });

  return Object.assign({}, formattedRequest, { guests });
}

function submitGuestGroupRequestV2(guestGroupRequest) {
  const { template, ...amendedGuestGroupRequest } = guestGroupRequest;
  return () => ApiService.post('/web-api/v1/guestgroup', amendedGuestGroupRequest);
}

export function addGuestGroupV2(createGuestGroupRequest) {
  const { length } = createGuestGroupRequest.guests;
  const headline = `${length} Guest${length > 1 ? 's' : ''} Added`;
  const guestGroupRequest = formatAddGuestGroupRequest(createGuestGroupRequest);
  return dispatch => {
    dispatch(createGuest());
    return dispatch(submitGuestGroupRequestV2(guestGroupRequest)).then(json => {
      dispatch(toastsActions.positive({ headline, className: 'testid-toast-guest-added' }));
      return json;
    });
  };
}

export function editGuestGroupV2(updateGuestGroupRequest, hideToast) {
  const { length } = updateGuestGroupRequest.guests;
  const headline = `Guest${length > 1 ? 's' : ''} Updated`;
  const guestGroupRequest = formatUpdateGuestGroupRequest(updateGuestGroupRequest);
  return dispatch => {
    dispatch(updateGuestGroup());
    return dispatch(submitGuestGroupRequestV2(guestGroupRequest)).then(json => {
      if (!hideToast) {
        dispatch(toastsActions.positive({ headline }));
      }
      return json;
    });
  };
}

export function fetchGuestListV2(type) {
  return (dispatch, getState) => {
    dispatch(requestGuests());
    const selectedFacetValues = {
      selectedFacetValues: getState().guestList.guests.selectedFacetValues,
      sortByNameAsc: getState().guestList.guests.sortByNameAsc,
    };
    return fetch(`/web-api/v1/guestgroup/list/${type}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedFacetValues),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveGuests(json)));
  };
}

export function updateEventInvitation(guestGroupIds, eventId, invited) {
  return dispatch => {
    const body = {
      guest_group_ids: guestGroupIds,
      event_id: eventId,
      invited,
    };

    return fetch('/web-api/v1/guestgroup/event_invite', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(() => dispatch(fetchBuildList()));
  };
}

export function bulkUpdateAllEventInvites(guestGroupIds, invited) {
  return dispatch => {
    const body = {
      guest_group_ids: guestGroupIds,
      invited,
    };

    return fetch('/web-api/v1/guestgroup/event_invite/all', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(() => dispatch(fetchBuildList()));
  };
}

export function updateSeatedStatus(guestId, tableId) {
  return dispatch => {
    const body = {
      guest_ids: guestId,
      table_id: tableId,
    };
    return fetch('', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(json => dispatch(changeSeatedGuest(json)));
  };
}
