import { FONTS_VERTICAL_MULTILINE_SPACING_VALUES } from 'paper/utils/fontCalibration/fontOverrides';

/*
 * Handle fonts vertical alignment
 * in Cards previews on a per font basis
 */
export const textLayerTopSpacing = ({
  lineHeight,
  fontSize,
  body,
  fontFamily,
}: {
  lineHeight: number;
  fontSize: number;
  body: string;
  fontFamily: string;
}) => {
  const currentFontTopSpacingValues =
    FONTS_VERTICAL_MULTILINE_SPACING_VALUES[
      fontFamily as keyof typeof FONTS_VERTICAL_MULTILINE_SPACING_VALUES
    ];
  const isSingleLine = body.indexOf('\n') === -1;

  /*
   * - Default / Single line -
   * The default rule when the difference is not null
   * correspond to 25% in top space of that difference.
   */
  const defaultTopSpacingCalculation = currentFontTopSpacingValues
    ? (lineHeight - fontSize) / 4
    : 0;

  /*
   * - Default can vary from a font to another
   */
  let paddingTop =
    !!currentFontTopSpacingValues && currentFontTopSpacingValues.hasMultiSingleParity
      ? currentFontTopSpacingValues.default
      : defaultTopSpacingCalculation;

  /*
   * Are Line Height and Font Size the same
   */
  const hasLhFsParity = lineHeight === fontSize;

  /*
   * - Multilines -
   * When parity is met, need to apply the
   * default (current) font multiline top spacing
   * When parity is NOT met, need to apply the
   * general default multiline top spacing.
   */
  if (!isSingleLine && !!currentFontTopSpacingValues) {
    paddingTop = hasLhFsParity
      ? currentFontTopSpacingValues.default
      : currentFontTopSpacingValues.NoHeightParity;
  }

  return paddingTop;
};
