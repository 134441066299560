import type { WUploadedImageView } from '@zola/svc-web-api-ts-client';
import _fromPairs from 'lodash/fromPairs';

// actions
import { getUserContext as getUserContextAction } from 'actions/UserActions';
import { UserContext } from 'actions/types/UserActionTypes';

// selectors
import { getFlowData } from 'cards/selectors/samplesFlow';

// utils
import {
  createSubstitutions,
  extractUserWeddingDetails,
  getIsSampleCustomizable,
  matchColorToCardVariation,
} from 'cards/components/common/Samples/util/helpers';
import { createAccount, createInvitationsAccount } from 'paper/utils/account';
import { copyUploadcareFileToZola } from 'components/common/UploadcareWidget/utils/api';
import { extractCoupleNames } from 'cards/components/common/Samples/SamplesFlow/utils';
import { trackGoogleTagManagerEvent } from '@zola-helpers/client';
import { AppDispatch, RootState } from 'reducers/index';
import { STEPS } from 'cards/components/common/Samples/SamplesFlow/constants';
import { trackEvent } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { mapCardTypeToBusinessUnitComponent } from 'components/onboard/helpers';
import { getActiveVariationOptionValues } from '../pdp/selectors';
import { cardSuiteFulfilled } from '../pdp/actions';
import {
  Actions,
  AccountDetails,
  CardSuiteDetails,
  CardSuiteDetailsSet,
  DesignCustomizations,
  DesignCustomizationsSet,
  ShippingDetails,
  Template,
  TemplatesSet,
  UploadcareFile,
  WeddingDetails,
} from './types';
import ApiService from '../../../util/api';

export const actionTypes = {
  DELETE_UPLOADCARE_IMAGE: 'zola/cards/project/samplesFlow/DELETE_UPLOADCARE_IMAGE',
  RESET: 'zola/cards/project/samplesFlow/RESET',
  SET_ACCOUNT_DETAILS: 'zola/cards/project/samplesFlow/SET_ACCOUNT_DETAILS',
  SET_ALL_LP_OR_FOIL: 'zola/cards/project/samplesFlow/SET_ALL_LP_OR_FOIL',
  SET_CARD_SUITE_DETAILS: 'zola/cards/project/samplesFlow/SET_CARD_SUITE_DETAILS',
  SET_DEFAULT_TEMPLATES: 'zola/cards/project/samplesFlow/SET_DEFAULT_TEMPLATES',
  SET_DESIGN_CUSTOMIZATIONS: 'zola/cards/projects/samplesFlow/SET_DESIGN_CUSTOMIZATIONS',
  SET_ERRORS: 'zola/cards/project/samplesFlow/SET_ERRORS',
  SET_HAS_LP_OR_FOIL: 'zola/cards/project/samplesFlow/SET_HAS_LP_OR_FOIL',
  SET_ORDER_NUMBER: 'zola/cards/project/samplesFlow/SET_ORDER_NUMBER',
  SET_PAPER_CUSTOMIZATIONS: 'zola/cards/project/samplesFlow/SET_PAPER_CUSTOMIZATIONS',
  SET_SHIPPING_DETAILS: 'zola/cards/project/samplesFlow/SET_SHIPPING_DETAILS',
  SET_SHIPPING_DETAILS_FETCHED: 'zola/cards/project/samplesFlow/SET_SHIPPING_DETAILS_FETCHED',
  SET_SINGLE_SAMPLE_AVAILABLE: 'zola/cards/project/samplesFlow/SET_SINGLE_SAMPLE_AVAILABLE',
  SET_STEP_KEY: 'zola/cards/project/samplesFlow/SET_STEP_KEY',
  SET_UPLOADCARE_IMAGE: 'zola/cards/project/samplesFlow/SET_UPLOADCARE_IMAGE',
  SET_WEDDING_DETAILS: 'zola/cards/project/samplesFlow/SET_WEDDING_DETAILS',
  SET_PREVIEW_PHOTO: 'zola/cards/project/samplesFlow/SET_PREVIEW_PHOTO',
  UPDATE_DESIGN_CUSTOMIZATIONS: 'zola/cards/projects/samplesFlow/UPDATE_DESIGN_CUSTOMIZATIONS',
  UPDATE_TEMPLATE: 'zola/cards/project/samplesFlow/UPDATE_TEMPLATE',
};

export const deleteUploadcareImage = (cardSuiteUUID: string): Actions.DeleteUploadcareImage => ({
  type: actionTypes.DELETE_UPLOADCARE_IMAGE,
  payload: { cardSuiteUUID },
});

export const resetSamplesFlow = (): Actions.ResetSamplesFlow => ({
  type: actionTypes.RESET,
  payload: {},
});

export const setAccountDetails = (accountDetails: AccountDetails): Actions.SetAccountDetails => ({
  type: actionTypes.SET_ACCOUNT_DETAILS,
  payload: { accountDetails },
});

const setAllLPOrFoil = (): Actions.SetAllLPOrFoil => ({
  type: actionTypes.SET_ALL_LP_OR_FOIL,
  payload: {},
});

const setCardSuiteDetails = (
  suiteDetails: CardSuiteDetails,
  activePDPVariation?: any
): Actions.SetCardSuiteDetails => ({
  type: actionTypes.SET_CARD_SUITE_DETAILS,
  payload: { suiteDetails, activePDPVariation },
});

const setDefaultTemplates = (templates: TemplatesSet): Actions.SetDefaultTemplates => ({
  type: actionTypes.SET_DEFAULT_TEMPLATES,
  payload: { templates },
});

export const setDesignCustomizations = (
  designCustomizationsSet: DesignCustomizationsSet
): Actions.SetDesignCustomizations => ({
  type: actionTypes.SET_DESIGN_CUSTOMIZATIONS,
  payload: { designCustomizationsSet },
});

export const setErrors = (errors: Actions.Errors): Actions.SetErrors => ({
  type: actionTypes.SET_ERRORS,
  payload: { errors },
});

const setHasLPOrFoil = (): Actions.SetHasLPOrFoil => ({
  type: actionTypes.SET_HAS_LP_OR_FOIL,
  payload: {},
});

export const setPaperCustomizations = (newSelections: any): Actions.SetPaperCustomizations => ({
  type: actionTypes.SET_PAPER_CUSTOMIZATIONS,
  payload: { newSelections },
});

export const setWeddingDetails = (weddingDetails: WeddingDetails): Actions.SetWeddingDetails => ({
  type: actionTypes.SET_WEDDING_DETAILS,
  payload: { weddingDetails },
});

const setOrderNumber = (orderNumber: string): Actions.SetOrderNumber => ({
  type: actionTypes.SET_ORDER_NUMBER,
  payload: { orderNumber },
});

export const setShippingDetails = (
  shippingDetails: ShippingDetails
): Actions.SetShippingDetails => ({
  type: actionTypes.SET_SHIPPING_DETAILS,
  payload: { shippingDetails },
});

const setSamplesAvailable = (samplesAvailable: boolean): Actions.SetSamplesAvailable => ({
  type: actionTypes.SET_SINGLE_SAMPLE_AVAILABLE,
  payload: { samplesAvailable },
});

export const setStepKey = (stepKey: string): Actions.SetStepKey => ({
  type: actionTypes.SET_STEP_KEY,
  payload: { stepKey },
});

export const setUploadcareImage = (
  activePreviewUUID: string,
  cardSuiteUUIDList: string[],
  uploadcareFile: UploadcareFile
): Actions.SetUploadcareImage => ({
  type: actionTypes.SET_UPLOADCARE_IMAGE,
  payload: { activePreviewUUID, cardSuiteUUIDList, uploadcareFile },
});

export const setPreviewPhoto = (
  cdnUrl?: string | null,
  mimeType?: string | null
): Actions.SetPreviewPhoto => ({
  type: actionTypes.SET_PREVIEW_PHOTO,
  payload: { cdnUrl, mimeType },
});

export const setShippingDetailsFetched = (): Actions.SetShippingDetailsFetched => ({
  type: actionTypes.SET_SHIPPING_DETAILS_FETCHED,
  payload: {},
});

export const updateDesignCustomizations = (
  cardSuiteUUID: string,
  customizations: DesignCustomizations
): Actions.UpdateDesignCustomizations => ({
  type: actionTypes.UPDATE_DESIGN_CUSTOMIZATIONS,
  payload: { cardSuiteUUID, customizations },
});

const updateTemplate = (cardSuiteUUID: string, template: Template): Actions.UpdateTemplates => ({
  type: actionTypes.UPDATE_TEMPLATE,
  payload: { cardSuiteUUID, template },
});

// API calls
export const fetchAvailableSamplesCount = (cardType: string) => (dispatch: AppDispatch) => {
  if (!cardType) {
    return Promise.resolve(null);
  }

  return ApiService.get(`/web-api/v1/card-single-sample/availability/${cardType}`).then(data => {
    const samplesAvailable = data.order_placed < data.maximum_orders_allowed;

    dispatch(setSamplesAvailable(samplesAvailable));
  });
};

export const fetchCardSuiteDetails = (cardSuiteUUIDList: string[], isAdminView: boolean) => (
  dispatch: AppDispatch,
  getState: () => RootState
): Promise<void> => {
  return ApiService.post('/web-api/v1/card-catalog/suites/details', {
    suite_uuids: cardSuiteUUIDList,
    show_unlisted: !!isAdminView,
  }).then(suiteDetails => {
    const samplesFlowState = getFlowData(getState());
    const activePDPVariation = getActiveVariationOptionValues(getState());

    Object.values(suiteDetails).forEach((suite: any) => {
      if ((suite.letterpress || suite.foil) && !samplesFlowState.hasLPOrFoil) {
        dispatch(setHasLPOrFoil());
      }

      if (!suite.letterpress && !suite.foil && samplesFlowState.allLPOrFoil) {
        dispatch(setAllLPOrFoil());
      }
    });

    dispatch(setCardSuiteDetails(suiteDetails, activePDPVariation));
    dispatch(cardSuiteFulfilled(suiteDetails[0]));
  });
};

export const fetchDefaultTemplates = (
  cardSuiteUUIDList: string[],
  cardSuiteDetailsSet: CardSuiteDetailsSet,
  substitutions: { [key: string]: string } | null,
  designCustomizationsSet: DesignCustomizationsSet
) => (dispatch: AppDispatch): Promise<void> => {
  const variationUUIDs: { [key: string]: string } = {};

  cardSuiteUUIDList.forEach(uuid => {
    const cardSuiteDetails = cardSuiteDetailsSet[uuid];
    // get get currently selected color unless card is letterpress or foil (then use default)
    const selectedColor =
      cardSuiteDetails.letterpress || cardSuiteDetails.foil
        ? cardSuiteDetails.mediums.paper.default_variation_options.color
        : designCustomizationsSet[uuid].color;
    const matched = matchColorToCardVariation(cardSuiteDetails, selectedColor);

    variationUUIDs[matched.uuid] = uuid;
  });

  return ApiService.post('/web-api/v2/card-template/preview/by-variations', {
    variation_uuids: Object.keys(variationUUIDs),
    substitutions: substitutions || {},
  }).then(templates => {
    const templatesBySuiteUUID: { [key: string]: Template } = {};

    Object.entries(templates).forEach(template => {
      templatesBySuiteUUID[variationUUIDs[template[0]]] = template[1];
    });

    dispatch(setDefaultTemplates(templatesBySuiteUUID));
  });
};

export const fetchTemplate = (
  cardSuiteUUID: string,
  cardSuiteDetails: CardSuiteDetails,
  color: string,
  substitutions: { [key: string]: string } | null
) => (dispatch: AppDispatch) => {
  if (!cardSuiteDetails || !color) {
    return Promise.resolve();
  }

  const matched = matchColorToCardVariation(cardSuiteDetails, color);

  return ApiService.post('/web-api/v2/card-template/preview/by-variations', {
    variation_uuids: [matched.uuid],
    substitutions: substitutions || {},
  }).then(template => dispatch(updateTemplate(cardSuiteUUID, template[matched.uuid])));
};

export const fetchUserShippingAddress = (userContext: UserContext) => (dispatch: AppDispatch) => {
  const weddingDetails = extractUserWeddingDetails(userContext);
  const slug = weddingDetails && weddingDetails.slug;

  if (!slug) {
    Promise.resolve().catch(() => undefined);
  }

  return ApiService.get(`/web-api/v1/card-single-sample/prefill-content/${slug}`).then(
    locationData => {
      if (locationData && locationData.shippingAddress) {
        const shippingDetails = locationData.shippingAddress;

        dispatch(
          setShippingDetails({
            address: shippingDetails.street_address_1,
            apt: shippingDetails.street_address_2,
            city: shippingDetails.city,
            state: shippingDetails.state_province_region,
            zip: shippingDetails.postal_code,
          })
        );
      }

      dispatch(setShippingDetailsFetched());
    }
  );
};

export const submitSamplesFlowForm = ({
  cardSuiteUUIDList,
  isGuest,
  userContext,
  trackingParams,
  captchaToken,
}: {
  cardSuiteUUIDList: string[];
  isGuest: boolean;
  userContext: UserContext;
  trackingParams: Record<string, unknown>;
  captchaToken?: string;
}) => (dispatch: AppDispatch, getState: () => RootState): unknown => {
  const samplesFlowState = getFlowData(getState());

  const {
    accountDetails,
    allLPOrFoil,
    cardSuiteDetailsSet,
    cardType,
    designCustomizationsSet,
    errors,
    shippingDetails,
    weddingDetails,
  } = samplesFlowState;
  const {
    has_invitation_account,
    has_completed_onboarding,
    user_role_account_weddings = [],
    object_id,
  } = userContext;

  let accountPromise;

  if (!has_completed_onboarding) {
    const existingAccount = {
      account_id: user_role_account_weddings[0]?.account_id,
      user_id: object_id,
    };
    // Create accounts for both guests and "basic" users
    accountPromise = createAccount({
      accountDetails,
      currentErrors: errors,
      weddingDetails,
      shouldTrackOnboarding: false,
      isGuest,
      captchaToken,
      existingAccount,
      businessUnitComponent: mapCardTypeToBusinessUnitComponent(cardType || ''),
    }).then((result: any): any => {
      if (result.errors) {
        dispatch(setErrors(result.errors));
        dispatch(
          result.errors.weddingDetails ? setStepKey(STEPS.NAMES) : setStepKey(STEPS.SHIPPING)
        );

        throw new Error('Account details invalid');
      }

      dispatch(getUserContextAction()).catch(() => undefined);
    });
  } else if (!has_invitation_account) {
    accountPromise = createInvitationsAccount();
  } else {
    accountPromise = Promise.resolve();
  }

  return accountPromise
    .then(() => {
      const photoApiCalls: Promise<{
        suiteUUID: string;
        photoUUID?: string;
      }>[] = cardSuiteUUIDList.map(uuid => {
        const { uploadcareFile } = designCustomizationsSet[uuid];
        if (uploadcareFile) {
          return copyUploadcareFileToZola(uploadcareFile, true).then(
            (zolaFile: WUploadedImageView) => ({
              suiteUUID: uuid,
              photoUUID: zolaFile.uuid,
            })
          );
        }

        return Promise.resolve({ suiteUUID: uuid });
      });

      return Promise.all(photoApiCalls).then(results => ({
        photoUUIDSet: _fromPairs(results.map(r => [r.suiteUUID, r.photoUUID])),
      }));
    })
    .then((result: any) => {
      const { photoUUIDSet } = result;
      const coupleNames = extractCoupleNames(weddingDetails);

      if (!coupleNames) {
        dispatch(setErrors(result.errors));
        dispatch(setStepKey(STEPS.NAMES));

        throw new Error('Account details invalid');
      }
      const substitutions = allLPOrFoil ? {} : createSubstitutions(weddingDetails);

      const projects = cardSuiteUUIDList.map(uuid => {
        const { variation } = designCustomizationsSet[uuid];
        const activeVariationUUID = variation.uuid;

        return {
          variation_uuid: activeVariationUUID,
          suite_uuid: uuid,
          photo_uuid: photoUUIDSet[uuid],
          sku_object_id: !getIsSampleCustomizable(cardSuiteDetailsSet[uuid])
            ? cardSuiteDetailsSet[uuid].single_sample_sku
            : null,
        };
      });

      const cardInfo = {
        projects,
        wedding_details: allLPOrFoil
          ? {}
          : {
              ...coupleNames,
              event_date: weddingDetails.date,
              ...substitutions,
            },
      };

      const body = {
        ...cardInfo,
        card_type: cardType,
        address_validation: {
          street_line_1: shippingDetails.address,
          street_line_2: shippingDetails.apt,
          city: shippingDetails.city,
          state_province_region: shippingDetails.state,
          postal_code: shippingDetails.zip,
          country_code: 'USA',
        },
      };

      return ApiService.post('/web-api/v1/card-single-sample/order', body).then(data => {
        if (!data.addressValidated) {
          dispatch(
            setErrors({
              shipping: {
                field: {
                  address: 'Please check the address',
                  city: 'Please check the city',
                  state: 'Please check the state',
                  zipCode: 'Please check zip code',
                },
              },
            })
          );
          dispatch(setStepKey(STEPS.SHIPPING));

          throw new Error('Shipping Address invalid');
        }

        if (!data.checkedOut || !data.orderNumber) {
          dispatch(
            setErrors({
              shipping: {
                form: "Something went wrong. Let's try that again.",
              },
            })
          );
          dispatch(setStepKey(STEPS.SHIPPING));

          throw new Error("Something went wrong. Let's try that again.");
        }

        dispatch(setOrderNumber(data.orderNumber));

        return data.orderNumber;
      });
    })
    .then(orderId => {
      trackEvent('Flow Completed', {
        ...trackingParams,
        is_error: false,
      });

      dispatch(getUserContextAction())
        .then(data => {
          trackGoogleTagManagerEvent('Paper Free Sample', {
            order_id: orderId,
            revenue: 0,
            email: data.email,
          });
        })
        .catch(() => undefined);
    })
    .catch((error: string) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

export const saveAsDraft = (cardSuiteUUIDList: string[], userContext: UserContext) => (
  dispatch: AppDispatch,
  getState: () => RootState
): Promise<boolean> => {
  // We can't save a draft for guests
  if (userContext.is_guest) return Promise.resolve(false);

  const { designCustomizationsSet, weddingDetails } = getFlowData(getState());

  // If couple names were entered in the samples flow, use them.
  let coupleNames = extractCoupleNames(weddingDetails);
  // Otherwise, try to extract them from the user context
  if (!coupleNames) {
    const userRoleAccountWeddings =
      userContext.user_role_account_weddings && userContext.user_role_account_weddings[0];
    const { owner_first_name, owner_last_name, partner_first_name, partner_last_name } =
      userRoleAccountWeddings || {};

    if (owner_first_name && owner_last_name && partner_first_name && partner_last_name) {
      coupleNames = { owner_first_name, owner_last_name, partner_first_name, partner_last_name };
    }
  }

  const accountCreationRequest = userContext.has_invitation_account
    ? Promise.resolve({})
    : createInvitationsAccount().then(accountCreationResult => {
        // Can't proceed without an invitations account
        if (accountCreationResult.errors) throw new Error(`Couldn't create invitations account`);

        return dispatch(getUserContextAction());
      });

  return accountCreationRequest
    .then(
      (): Promise<Record<string, string>> => {
        // Can't create a draft without names
        if (!coupleNames) throw new Error(`Couple names weren't found`);

        // Once an invitations account is available, copy any uploaded images from Uploadcare to Zola
        const photoCopyRequests: Promise<{
          suiteUUID: string;
          photoUUID?: string;
        }>[] = cardSuiteUUIDList.map(uuid => {
          const { uploadcareFile } = designCustomizationsSet[uuid];

          if (uploadcareFile) {
            return copyUploadcareFileToZola(uploadcareFile, true).then(
              (zolaFile: WUploadedImageView) => ({
                suiteUUID: uuid,
                photoUUID: zolaFile.uuid,
              })
            );
          }

          return Promise.resolve({ suiteUUID: uuid });
        });

        return Promise.all(photoCopyRequests).then(photoCopyResults => {
          const pairs: (string | undefined)[][] = photoCopyResults.map(r => [
            r.suiteUUID,
            r?.photoUUID,
          ]);
          return _fromPairs(pairs);
        });
      }
    )
    .then((photoUUIDSet: Record<string, string>) => {
      const substitutions = createSubstitutions(weddingDetails);
      const weddingDetailsToSubmit = {
        ...coupleNames,
        event_date: weddingDetails.date,
        ...substitutions,
      };

      // Draft save requests will return false if there are errors, and true otherwise
      const draftRequests = cardSuiteUUIDList.map(uuid => {
        const { variation } = designCustomizationsSet[uuid];
        const activeVariationUUID = variation.uuid;

        const body = {
          variation_uuid: activeVariationUUID,
          suite_uuid: uuid,
          photo_uuid: photoUUIDSet[uuid],
          wedding_details: weddingDetailsToSubmit,
        };

        return ApiService.post('/web-api/v1/card-single-sample/draft', body)
          .then(draftResult => draftResult.status !== 'error' && !draftResult.errors)
          .catch(() => false);
      });

      return Promise.all(draftRequests).then(results => {
        // Consider the "save drafts" operation successful if at least 1 draft was saved
        return results.some(successfullySaved => !!successfullySaved);
      });
    })
    .catch(() => false);
};
