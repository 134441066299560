/**
 * @prettier
 */

import { useCallback, useState, useEffect } from 'react';
import _fromPairs from 'lodash/fromPairs';
import { isSingleSampleLimitExhausted, getCardSuiteDetails } from '../api';

export function useSingleSampleLimitExhausted(cardType) {
  const globalKey = 'ZOLA_INVITATIONS_SINGLE_SAMPLE_MODAL_SAMPLE_LIMIT_EXHAUSTED_PROMISE';

  const [isExhausted, setIsExhausted] = useState(false);

  const updateIsExhausted = useCallback(
    exhausted => isExhausted !== exhausted && setIsExhausted(exhausted),
    [isExhausted, setIsExhausted]
  );

  const fetchIsExhausted = useCallback(() => {
    window[globalKey] = isSingleSampleLimitExhausted(cardType);
    return window[globalKey].then(updateIsExhausted);
  }, [cardType, updateIsExhausted]);

  /**
   * Cache whether the sample limit is exhausted in a global variable
   */
  useEffect(() => {
    if (cardType) {
      if (window[globalKey] && window[globalKey].then) {
        window[globalKey].then(updateIsExhausted);
      } else {
        fetchIsExhausted();
      }
    }
  });

  return [isExhausted, fetchIsExhausted];
}

export const useCardSuiteDetails = (cardSuiteUUID, isAdminView) => {
  const [cardSuiteDetails, setCardSuiteDetails] = useState(null);

  useEffect(() => {
    getCardSuiteDetails(cardSuiteUUID, isAdminView).then(setCardSuiteDetails);
  }, [cardSuiteUUID, isAdminView]);

  return cardSuiteDetails;
};

export function useCardSuiteDetailsSet(cardSuiteUUIDList, isAdminView, isMulti) {
  const [cardSuiteDetailsSet, setCardSuiteDetailsSet] = useState({});

  useEffect(() => {
    if (isMulti && cardSuiteUUIDList) {
      Promise.all(
        cardSuiteUUIDList.map(cardSuiteUUID =>
          getCardSuiteDetails(cardSuiteUUID, isAdminView).then(suiteDetails => [
            cardSuiteUUID,
            suiteDetails,
          ])
        )
      )
        .then(_fromPairs)
        .then(setCardSuiteDetailsSet);
    }
  }, [(cardSuiteUUIDList || []).join('_'), isAdminView, isMulti]);

  return cardSuiteDetailsSet;
}

export function usePhotoObjectURL(photo) {
  const [photoURL, setPhotoURL] = useState();

  useEffect(() => {
    if (photo && photo.file && !photoURL) {
      setPhotoURL(URL.createObjectURL(photo.displayedBlob));
    }

    return () => photoURL && URL.revokeObjectURL(photoURL);
  }, [photo, photoURL]);

  return photoURL;
}

export { default as useMostPopularSamplesFromFamily } from './useMostPopularSamplesFromFamily';
