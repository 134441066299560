import {
  copyUploadcareFileToZola,
  copyUploadcareFileGroupToZola,
  fetchImageMetadata,
  fetchBatchImageMetadata,
  fetchOriginalMetadata,
  fetchUploadcareFile,
} from 'components/common/UploadcareWidget/utils/api';
import { getCachedMetadata } from 'selectors/uploadcareSelectors';
import { FileInfo } from '@uploadcare/react-widget';
import type { WCmsPageImageView, WUploadedImageView } from '@zola/svc-web-api-ts-client';
import { AppDispatch } from 'reducers/index';
import { UploadcareFile } from '@uploadcare/upload-client/lib/tools/UploadcareFile';

export const ActionTypes = {
  UPLOADCARE_TRANSFER_STARTED: 'zola/uploadcare/UPLOADCARE_TRANSFER_STARTED',
  UPLOADCARE_TRANSFER_COMPLETE: 'zola/uploadcare/UPLOADCARE_TRANSFER_COMPLETE',
  ZOLA_METADATA_FETCH_STARTED: 'zola/uploadcare/ZOLA_METADATA_FETCH_STARTED',
  ZOLA_METADATA_FETCH_COMPLETE: 'zola/uploadcare/ZOLA_METADATA_FETCH_COMPLETE',
  ZOLA_METADATA_BATCH_FETCH_STARTED: 'zola/uploadcare/ZOLA_METADATA_BATCH_FETCH_STARTED',
  ZOLA_METADATA_BATCH_FETCH_COMPLETE: 'zola/uploadcare/ZOLA_METADATA_BATCH_FETCH_COMPLETE',
  UPLOADCARE_FILE_FETCH_STARTED: 'zola/uploadcare/UPLOADCARE_FILE_FETCH_STARTED',
  UPLOADCARE_FILE_FETCH_COMPLETE: 'zola/uploadcare/UPLOADCARE_FILE_FETCH_COMPLETE',
  CLEAR_UPLOADCARE_FILES: 'zola/uploadcare/CLEAR_UPLOADCARE_FILES',
  CLEAR_ZOLA_METADATA: 'zola/uploadcare/CLEAR_ZOLA_METADATA',
};

const transferStarted = (uploadcareFile: FileInfo) => ({
  type: ActionTypes.UPLOADCARE_TRANSFER_STARTED,
  payload: { uploadcareFile },
});

const transferComplete = (metadata: WCmsPageImageView) => ({
  type: ActionTypes.UPLOADCARE_TRANSFER_COMPLETE,
  payload: { metadata },
});

const zolaMetadataFetchStarted = (zolaUUID: string, fetchingPromise: Promise<unknown>) => ({
  type: ActionTypes.ZOLA_METADATA_FETCH_STARTED,
  payload: { zolaUUID, fetchingPromise },
});

const zolaMetadataFetchComplete = (metadata: WCmsPageImageView) => ({
  type: ActionTypes.ZOLA_METADATA_FETCH_COMPLETE,
  payload: { metadata },
});

const zolaMetadataBatchFetchStarted = (zolaUUIDs: number[]) => ({
  type: ActionTypes.ZOLA_METADATA_BATCH_FETCH_STARTED,
  payload: { zolaUUIDs },
});

const zolaMetadataBatchFetchComplete = (metadataList: WCmsPageImageView[]) => ({
  type: ActionTypes.ZOLA_METADATA_BATCH_FETCH_COMPLETE,
  payload: { metadataList },
});

export const saveUploadcareImageToZola = ({
  uploadcareFile,
  cache = {},
  preserveInUC = false,
  daysToKeepInUploadCare = 0,
}: {
  uploadcareFile: FileInfo;
  cache: Record<string, WUploadedImageView>;
  preserveInUC: boolean;
  daysToKeepInUploadCare?: number;
}) => (dispatch: AppDispatch) => {
  dispatch(transferStarted(uploadcareFile));
  const { cdnUrl, uuid } = uploadcareFile;
  const cacheValues = Object.values(cache);
  let filePromise;

  const cachedMetadata = cacheValues.find(info => info.uploadcare_url === cdnUrl);
  const hasCachedOriginal = cacheValues.some(info => info.uploadcare_original_url === cdnUrl);
  if (cachedMetadata) {
    // If the image is already available in the cache, directly use its metadata to avoid re-copying.
    filePromise = Promise.resolve(cachedMetadata);
  } else if (hasCachedOriginal) {
    // else if originalUrl && uploadcareFile.cdnUrl changes === originalUrl, fetch and use the original's zola file uuid
    filePromise = fetchOriginalMetadata(uuid);
  } else {
    // Otherwise, copy the new image to zola
    filePromise = copyUploadcareFileToZola(uploadcareFile, preserveInUC, 0, daysToKeepInUploadCare);
  }

  return filePromise.then(metadata => {
    dispatch(transferComplete(metadata));
    return metadata;
  });
};

export const saveUploadcareImagesToZola = ({
  uploadcareFiles,
  cache = {},
  preserveInUC = false,
  daysToKeepInUploadCare = 0,
}: {
  uploadcareFiles: FileInfo[];
  cache: Record<string, WUploadedImageView>;
  preserveInUC: boolean;
  daysToKeepInUploadCare?: number;
}) => (dispatch: AppDispatch) => {
  const filePromises = uploadcareFiles.map((uploadcareFile: FileInfo) =>
    dispatch(
      saveUploadcareImageToZola({ uploadcareFile, cache, preserveInUC, daysToKeepInUploadCare })
    )
  );
  return Promise.all(filePromises);
};

export const saveUploadcareImageGroupToZola = (
  uploadcareFiles: FileInfo[],
  fileGroupId: number
) => (dispatch: AppDispatch) => {
  uploadcareFiles.forEach((uploadcareFile: FileInfo) => dispatch(transferStarted(uploadcareFile)));

  return copyUploadcareFileGroupToZola(fileGroupId).then(zolaFiles => {
    zolaFiles.forEach((zolaFile: WCmsPageImageView) => dispatch(transferComplete(zolaFile)));
    return zolaFiles;
  });
};

export const fetchZolaMetadata = (zolaImageId: string) => (
  dispatch: AppDispatch,
  getState: () => void
) => {
  // Accessing the state inside an action is an antipattern, but we use it here to make sure
  // that this often-used action doesn't reset existing metadata and make unnecessary API calls.
  const cachedMetadata = getCachedMetadata(getState());
  const existingMetadata = cachedMetadata[zolaImageId];
  if (existingMetadata) {
    return existingMetadata.fetchingPromise || Promise.resolve(existingMetadata);
  }

  // fetchingPromise is used to handle concurrent fetch requests.
  // We create the promise on the 1st request, then let other calls wait for it to resolve.
  const fetchingPromise = new Promise((resolve, reject) => {
    fetchImageMetadata(zolaImageId)
      .then(metadata => {
        dispatch(zolaMetadataFetchComplete(metadata));
        resolve(metadata);
      })
      .catch(e => {
        reject(e);
      });
  });

  dispatch(zolaMetadataFetchStarted(zolaImageId, fetchingPromise));

  return fetchingPromise;
};

export const fetchBatchZolaMetadata = (zolaImageIds: number[]) => (
  dispatch: AppDispatch,
  getState: () => void
) => {
  // If no image UUIDs are passed in, immediately return an empty object.
  if (!zolaImageIds || zolaImageIds.length === 0) return {};

  // Accessing the state inside an action is an antipattern, but we use it here to make sure
  // that this often-used action doesn't reset existing metadata and make unnecessary API calls.
  const cachedMetadata = getCachedMetadata(getState());
  const unfetchedImageUUIDs = zolaImageIds.filter((id: string | number) => !cachedMetadata[id]);

  // If we already have all requested metadata, return it directly.
  if (unfetchedImageUUIDs.length === 0) {
    return Promise.resolve(
      zolaImageIds.reduce((acc: { [x: string]: string[] }, id: string | number) => {
        acc[id] = cachedMetadata[id];
        return acc;
      }, {})
    );
  }

  dispatch(zolaMetadataBatchFetchStarted(zolaImageIds));

  return fetchBatchImageMetadata(zolaImageIds).then(newMetadata => {
    dispatch(zolaMetadataBatchFetchComplete(newMetadata));

    // Once all metadata is available, return only the requested images' metadata
    const validNewMetadata = newMetadata || [];
    return zolaImageIds.reduce((acc: { [x: string]: string[] }, id: string | number) => {
      acc[id] =
        validNewMetadata.find((item: { uuid: string }) => item.uuid === id) ||
        cachedMetadata[id] ||
        null;
      return acc;
    }, {});
  });
};

// Existing Uploadcare file data fetching
const uploadcareFileFetchStarted = (uploadcareCdnUrl: string) => ({
  type: ActionTypes.UPLOADCARE_FILE_FETCH_STARTED,
  payload: { uploadcareCdnUrl },
});

const uploadcareFileFetchComplete = (uploadcareCdnUrl: string, uploadcareFile: UploadcareFile) => ({
  type: ActionTypes.UPLOADCARE_FILE_FETCH_COMPLETE,
  payload: { uploadcareCdnUrl, uploadcareFile },
});

export const fetchExistingUploadcareFile = (uploadcareCdnUrl: string) => (
  dispatch: AppDispatch
) => {
  dispatch(uploadcareFileFetchStarted(uploadcareCdnUrl));

  return fetchUploadcareFile(uploadcareCdnUrl).then(uploadcareFile => {
    dispatch(uploadcareFileFetchComplete(uploadcareCdnUrl, uploadcareFile));
    return uploadcareFile;
  });
};

export const clearZolaMetadata = () => ({
  type: ActionTypes.CLEAR_ZOLA_METADATA,
});

export const clearUploadCareFiles = () => ({
  type: ActionTypes.CLEAR_UPLOADCARE_FILES,
});
